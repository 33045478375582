// Logo Mode
.logo-light {
	display: none;
}

@include color-mode(dark) {
	.logo-dark {
		display: none;
	}
	.logo-light {
		display: block;
	}
}
@include color-mode(light) {
	.logo-light {
		display: none;
	}
}


// Logo show hide
.header-transparent {
	.logo-dark {
		display: none;
	}
	[data-bs-theme="light"] & {
		.logo-light {
			display: block;
		}
	}
	&.fixed-header {
		[data-bs-theme="light"] & {
			.logo-light {
				display: none;
			}
			.logo-dark {
				display: block;
			}
		}	
	}
}

.fixed-header {
	.navbar {
		background-color: var(--#{$prefix}bg-mode-re);
		box-shadow: $box-shadow-lg;
	}
}

.header-main {
	transition: ease all 0.35s;
	&:not(.fixed-top) {
		position: relative;
		z-index: 1030;
	}
}


/* Header Fluid
-----------------------------------*/
.navbar-toggler-px-light {
	.navbar-toggler-icon {
		background-image: escape-svg($navbar-light-toggler-icon-bg);

	}
}

.navbar-toggler {
	width: 40px;
    height: 40px;
    border-radius: 0.25rem;
    padding: 0;
    color: var(--#{$prefix}text-mode);
    border: none;
}

.px-navbar-toggler-icon {
	width: 60%;
    height: 2px;
    background: currentColor;
    display: inline-block;
    vertical-align: middle;
    box-shadow: 0 -8px 0 0 currentColor,0 8px 0 0 currentColor;
}

/* Header Fluid
-----------------------------------*/
.header-fluid {
	.container-fluid {
		@include media-breakpoint-up(xxl) {
			padding-left: 35px;
			padding-right: 35px;
		}
	}
}

/* Header Search
-----------------------------------*/
.px-search-full {
	z-index: 9999;
	.search-close {
	    position: fixed;
	    top: 15px;
	    right: 15px;
	    width: 40px;
	    height: 40px;
	    padding: 0;
	    display: inline-flex;
	    align-items: center;
	    justify-content: center;
	    font-size: 25px;
	    color: var(--#{$prefix}white);
	    background: var(--#{$prefix}primary);
	    border: 2px solid var(--#{$prefix}primary);
	    border-radius: 50%;
	    &:hover {
	    	background: var(--#{$prefix}dark);
	    }
	}
}

/* Header Top
-----------------------------------*/
.header-top {
	.fixed-header & {
		visibility: hidden;
		pointer-events: none;
		transition: ease visibility 0.35s;
	}
}

/* Header Nav Color
-----------------------------------*/
.navbar-dark {
	.nav {
		.nav-link {
			color: $navbar-dark-color; 
			&:hover {
				color: var(--#{$prefix}white);
			}
		}
	}
}

.navbar-light {
	.nav {
		.nav-link {
			color: $navbar-light-color; 
			&:hover {
				color: var(--#{$prefix}primary);
			}
		}
	}
}


/* Cart Count
-----------------------------------*/
header {
	@include cart_count();
}


/* menu-label
-----------------------------------*/
.menu-label {
	background: var(--#{$prefix}danger);
	color: var(--#{$prefix}white);
	position: relative;
	padding: 2px 5px;
	font-size: 10px;
	text-transform: uppercase;
	line-height: 1;
	margin-left: 10px;
	&:after {
		content: "";
	    position: absolute;
	    top: 4px;
	    left: -5px;
	    border-top: 5px solid transparent;
	    border-right: 5px solid var(--#{$prefix}danger);
	}
	.dropdown-mega-menu & {
	    margin-left: 4px;
	    display: inline-flex;
	    top: -4px;
	}
}
