/*!
   * Bootstrap  v5.3.3 (https://getbootstrap.com/)
   * Copyright 2011-2024 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
/*----------------------------------------------
  Custom Variables
----------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url(../vendor/bootstrap/icon/bootstrap-icons.css);
@import url(../vendor/magnific/magnific-popup.css);
@import url(../vendor/swiper/swiper-bundle.min.css);
@import url(../vendor/feather/feather.css);
.font-alt {
  font-family: Rubik, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important; }

:root,
[data-bs-theme="light"] {
  --bs-blue: #7678ed;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #ff6f61;
  --bs-orange: #fd7e14;
  --bs-yellow: #ff9736;
  --bs-green: #28bb74;
  --bs-teal: #20c997;
  --bs-cyan: #3b86ff;
  --bs-white: #fff;
  --bs-gray: #858c97;
  --bs-gray-dark: #434a57;
  --bs-gray-25: #FCFCFD;
  --bs-gray-50: #F9FAFB;
  --bs-gray-100: #f6f9fc;
  --bs-gray-200: #edf1f5;
  --bs-gray-300: #e3e9ef;
  --bs-gray-400: #d7dde2;
  --bs-gray-500: #b4bbc3;
  --bs-gray-600: #858c97;
  --bs-gray-700: #576071;
  --bs-gray-800: #434a57;
  --bs-gray-900: #121519;
  --bs-primary: #e63946;
  --bs-secondary: #858c97;
  --bs-success: #28bb74;
  --bs-info: #3b86ff;
  --bs-warning: #ff9736;
  --bs-danger: #ff6f61;
  --bs-light: #f6f9fc;
  --bs-white: #fff;
  --bs-black: #000;
  --bs-dark: #02010a;
  --bs-primary-rgb: 230, 57, 70;
  --bs-secondary-rgb: 133, 140, 151;
  --bs-success-rgb: 40, 187, 116;
  --bs-info-rgb: 59, 134, 255;
  --bs-warning-rgb: 255, 151, 54;
  --bs-danger-rgb: 255, 111, 97;
  --bs-light-rgb: 246, 249, 252;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-dark-rgb: 2, 1, 10;
  --bs-primary-text-emphasis: #5c171c;
  --bs-secondary-text-emphasis: #35383c;
  --bs-success-text-emphasis: #104b2e;
  --bs-info-text-emphasis: #183666;
  --bs-warning-text-emphasis: #663c16;
  --bs-danger-text-emphasis: #662c27;
  --bs-light-text-emphasis: #576071;
  --bs-dark-text-emphasis: #576071;
  --bs-primary-bg-subtle: #fad7da;
  --bs-secondary-bg-subtle: #e7e8ea;
  --bs-success-bg-subtle: #d4f1e3;
  --bs-info-bg-subtle: #d8e7ff;
  --bs-warning-bg-subtle: #ffead7;
  --bs-danger-bg-subtle: #ffe2df;
  --bs-light-bg-subtle: #fbfcfe;
  --bs-dark-bg-subtle: #d7dde2;
  --bs-primary-border-subtle: #f5b0b5;
  --bs-secondary-border-subtle: #ced1d5;
  --bs-success-border-subtle: #a9e4c7;
  --bs-info-border-subtle: #b1cfff;
  --bs-warning-border-subtle: #ffd5af;
  --bs-danger-border-subtle: #ffc5c0;
  --bs-light-border-subtle: #edf1f5;
  --bs-dark-border-subtle: #b4bbc3;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: Rubik, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 0.9rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.7;
  --bs-body-color: #4b566b;
  --bs-body-color-rgb: 75, 86, 107;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(75, 86, 107, 0.75);
  --bs-secondary-color-rgb: 75, 86, 107;
  --bs-secondary-bg: #edf1f5;
  --bs-secondary-bg-rgb: 237, 241, 245;
  --bs-tertiary-color: rgba(75, 86, 107, 0.5);
  --bs-tertiary-color-rgb: 75, 86, 107;
  --bs-tertiary-bg: #f6f9fc;
  --bs-tertiary-bg-rgb: 246, 249, 252;
  --bs-heading-color: #152C5B;
  --bs-link-color: #e63946;
  --bs-link-color-rgb: 230, 57, 70;
  --bs-link-decoration: none;
  --bs-link-hover-color: #b82e38;
  --bs-link-hover-color-rgb: 184, 46, 56;
  --bs-code-color: #d63384;
  --bs-highlight-color: #4b566b;
  --bs-highlight-bg: #ffead7;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: var(--bs-gray-300);
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.75rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.25rem 0.5625rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 1.25rem -0.0625rem rgba(0, 0, 0, 0.05);
  --bs-box-shadow-sm: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06);
  --bs-box-shadow-lg: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(230, 57, 70, 0.25);
  --bs-form-valid-color: #28bb74;
  --bs-form-valid-border-color: #28bb74;
  --bs-form-invalid-color: #ff6f61;
  --bs-form-invalid-border-color: #ff6f61; }

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #999999;
  --bs-body-color-rgb: 153, 153, 153;
  --bs-body-bg: #121519;
  --bs-body-bg-rgb: 18, 21, 25;
  --bs-emphasis-color: #f6f9fc;
  --bs-emphasis-color-rgb: 246, 249, 252;
  --bs-secondary-color: rgba(153, 153, 153, 0.75);
  --bs-secondary-color-rgb: 153, 153, 153;
  --bs-secondary-bg: #434a57;
  --bs-secondary-bg-rgb: 67, 74, 87;
  --bs-tertiary-color: rgba(153, 153, 153, 0.5);
  --bs-tertiary-color-rgb: 153, 153, 153;
  --bs-tertiary-bg: #2b3038;
  --bs-tertiary-bg-rgb: 43, 48, 56;
  --bs-primary-text-emphasis: #f08890;
  --bs-secondary-text-emphasis: #b6bac1;
  --bs-success-text-emphasis: #7ed6ac;
  --bs-info-text-emphasis: #89b6ff;
  --bs-warning-text-emphasis: #ffc186;
  --bs-danger-text-emphasis: #ffa9a0;
  --bs-light-text-emphasis: #f6f9fc;
  --bs-dark-text-emphasis: #e3e9ef;
  --bs-primary-bg-subtle: #2e0b0e;
  --bs-secondary-bg-subtle: #121519;
  --bs-success-bg-subtle: #082517;
  --bs-info-bg-subtle: #0c1b33;
  --bs-warning-bg-subtle: #331e0b;
  --bs-danger-bg-subtle: #331613;
  --bs-light-bg-subtle: #434a57;
  --bs-dark-bg-subtle: #22252c;
  --bs-primary-border-subtle: #8a222a;
  --bs-secondary-border-subtle: #576071;
  --bs-success-border-subtle: #187046;
  --bs-info-border-subtle: #183666;
  --bs-warning-border-subtle: #663c16;
  --bs-danger-border-subtle: #99433a;
  --bs-light-border-subtle: #576071;
  --bs-dark-border-subtle: #434a57;
  --bs-heading-color: #fff;
  --bs-link-color: #f08890;
  --bs-link-hover-color: #f5b0b5;
  --bs-link-color-rgb: 240, 136, 144;
  --bs-link-hover-color-rgb: 245, 176, 181;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #999999;
  --bs-highlight-bg: #663c16;
  --bs-border-color: var(--bs-gray-300);
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #7ed6ac;
  --bs-form-valid-border-color: #7ed6ac;
  --bs-form-invalid-color: #ffa9a0;
  --bs-form-invalid-border-color: #ffa9a0; }

:root,
[data-bs-theme="light"] {
  --bs-avatar-border:              #fff;
  --bs-footer-link-color:          rgba(var(--bs-dark-rgb), 0.75);
  --bs-footer-link-color-hover:    var(--bs-dark);
  --bs-text-mode:                  var(--bs-dark);
  --bs-bg-mode:                  var(--bs-dark);
  --bs-text-mode-re:                  var(--bs-white);
  --bs-bg-mode-re:                  var(--bs-white); }

[data-bs-theme="dark"] {
  --bs-gray-100:   rgba(255, 255, 255, 0.025);
  --bs-gray-200:   rgba(255, 255, 255, 0.05);
  --bs-gray-300:   rgba(255, 255, 255, 0.13);
  --bs-gray-400:   rgba(255, 255, 255, 0.25);
  --bs-gray-500:   rgba(255, 255, 255, 0.4);
  --bs-gray-600:   rgba(255, 255, 255, 0.5);
  --bs-gray-700:   rgba(255, 255, 255, 0.7);
  --bs-gray-800:   rgba(255, 255, 255, 0.9);
  --bs-gray-900:   #fff;
  --bs-avatar-border:              #02010a;
  --bs-footer-link-color:          rgba(var(--bs-white-rgb), 0.75);
  --bs-footer-link-color-hover:    var(--bs-white);
  --bs-text-mode:                  var(--bs-white);
  --bs-bg-mode:                  var(--bs-white);
  --bs-text-mode-re:                  var(--bs-dark);
  --bs-bg-mode-re:                  var(--bs-black);
  --bs-backdrop-opacity:  0.8; }

*,
*::before,
*::after {
  box-sizing: border-box; }

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth; } }

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color); }

h1, .h1 {
  font-size: calc(1.35rem + 1.2vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.25rem; } }

h2, .h2 {
  font-size: calc(1.305rem + 0.66vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 1.8rem; } }

h3, .h3 {
  font-size: calc(1.2825rem + 0.39vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.575rem; } }

h4, .h4 {
  font-size: calc(1.26rem + 0.12vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.35rem; } }

h5, .h5 {
  font-size: 1.125rem; }

h6, .h6 {
  font-size: 0.9rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

mark, .mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg); }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none; }
  a:hover {
    --bs-link-color-rgb: var(--bs-link-hover-color-rgb); }

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color); }
  kbd kbd {
    padding: 0;
    font-size: 1em; }

figure {
  margin: 0 0 1rem; }

img,
svg {
  vertical-align: middle; }

table {
  caption-side: bottom;
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0; }

label {
  display: inline-block; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }
  select:disabled {
    opacity: 1; }

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

textarea {
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }
  legend + * {
    clear: left; }

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0; }

::-webkit-inner-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::file-selector-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

iframe {
  border: 0; }

summary {
  display: list-item;
  cursor: pointer; }

progress {
  vertical-align: baseline; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.035rem;
  font-weight: 400; }

.display-1 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 4.5rem; } }

.display-2 {
  font-size: calc(1.53rem + 3.36vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 4.05rem; } }

.display-3 {
  font-size: calc(1.485rem + 2.82vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 3.6rem; } }

.display-4 {
  font-size: calc(1.44rem + 2.28vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.15rem; } }

.display-5 {
  font-size: calc(1.395rem + 1.74vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-5 {
      font-size: 2.7rem; } }

.display-6 {
  font-size: calc(1.36125rem + 1.335vw);
  font-weight: 700;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-6 {
      font-size: 2.3625rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.125rem; }
  .blockquote > :last-child {
    margin-bottom: 0; }

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #858c97; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

@media (min-width: 1400px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1400px; }

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x)); }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y); }

.col {
  flex: 1 0 0%; }

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto; }

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%; }

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%; }

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%; }

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%; }

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto; }

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%; }

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%; }

.col-3 {
  flex: 0 0 auto;
  width: 25%; }

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%; }

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%; }

.col-6 {
  flex: 0 0 auto;
  width: 50%; }

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%; }

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%; }

.col-9 {
  flex: 0 0 auto;
  width: 75%; }

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%; }

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%; }

.col-12 {
  flex: 0 0 auto;
  width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

.g-0,
.gx-0 {
  --bs-gutter-x: 0; }

.g-0,
.gy-0 {
  --bs-gutter-y: 0; }

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem; }

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem; }

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem; }

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem; }

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem; }

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem; }

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem; }

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem; }

.g-5,
.gx-5 {
  --bs-gutter-x: 2rem; }

.g-5,
.gy-5 {
  --bs-gutter-y: 2rem; }

.g-6,
.gx-6 {
  --bs-gutter-x: 2.5rem; }

.g-6,
.gy-6 {
  --bs-gutter-y: 2.5rem; }

.g-7,
.gx-7 {
  --bs-gutter-x: 3rem; }

.g-7,
.gy-7 {
  --bs-gutter-y: 3rem; }

.g-8,
.gx-8 {
  --bs-gutter-x: 3.5rem; }

.g-8,
.gy-8 {
  --bs-gutter-y: 3.5rem; }

.g-9,
.gx-9 {
  --bs-gutter-x: 4rem; }

.g-9,
.gy-9 {
  --bs-gutter-y: 4rem; }

.g-10,
.gx-10 {
  --bs-gutter-x: 5rem; }

.g-10,
.gy-10 {
  --bs-gutter-y: 5rem; }

.g-11,
.gx-11 {
  --bs-gutter-x: 6rem; }

.g-11,
.gy-11 {
  --bs-gutter-y: 6rem; }

.g-12,
.gx-12 {
  --bs-gutter-x: 7rem; }

.g-12,
.gy-12 {
  --bs-gutter-y: 7rem; }

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%; }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0; }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0; }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem; }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem; }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem; }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem; }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem; }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem; }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem; }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem; }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 2rem; }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 2rem; }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 2.5rem; }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 2.5rem; }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 3rem; }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 3rem; }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 3.5rem; }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 3.5rem; }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 4rem; }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 4rem; }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 5rem; }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 5rem; }
  .g-sm-11,
  .gx-sm-11 {
    --bs-gutter-x: 6rem; }
  .g-sm-11,
  .gy-sm-11 {
    --bs-gutter-y: 6rem; }
  .g-sm-12,
  .gx-sm-12 {
    --bs-gutter-x: 7rem; }
  .g-sm-12,
  .gy-sm-12 {
    --bs-gutter-y: 7rem; } }

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%; }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0; }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0; }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem; }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem; }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem; }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem; }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem; }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem; }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem; }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem; }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 2rem; }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 2rem; }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 2.5rem; }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 2.5rem; }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 3rem; }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 3rem; }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 3.5rem; }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 3.5rem; }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 4rem; }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 4rem; }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 5rem; }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 5rem; }
  .g-md-11,
  .gx-md-11 {
    --bs-gutter-x: 6rem; }
  .g-md-11,
  .gy-md-11 {
    --bs-gutter-y: 6rem; }
  .g-md-12,
  .gx-md-12 {
    --bs-gutter-x: 7rem; }
  .g-md-12,
  .gy-md-12 {
    --bs-gutter-y: 7rem; } }

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%; }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0; }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0; }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem; }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem; }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem; }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem; }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem; }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem; }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem; }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem; }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 2rem; }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 2rem; }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 2.5rem; }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 2.5rem; }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 3rem; }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 3rem; }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 3.5rem; }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 3.5rem; }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 4rem; }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 4rem; }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 5rem; }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 5rem; }
  .g-lg-11,
  .gx-lg-11 {
    --bs-gutter-x: 6rem; }
  .g-lg-11,
  .gy-lg-11 {
    --bs-gutter-y: 6rem; }
  .g-lg-12,
  .gx-lg-12 {
    --bs-gutter-x: 7rem; }
  .g-lg-12,
  .gy-lg-12 {
    --bs-gutter-y: 7rem; } }

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%; }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0; }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0; }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem; }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem; }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 2rem; }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 2rem; }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 2.5rem; }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 2.5rem; }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 3rem; }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 3rem; }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 3.5rem; }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 3.5rem; }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 4rem; }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 4rem; }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 5rem; }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 5rem; }
  .g-xl-11,
  .gx-xl-11 {
    --bs-gutter-x: 6rem; }
  .g-xl-11,
  .gy-xl-11 {
    --bs-gutter-y: 6rem; }
  .g-xl-12,
  .gx-xl-12 {
    --bs-gutter-x: 7rem; }
  .g-xl-12,
  .gy-xl-12 {
    --bs-gutter-y: 7rem; } }

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%; }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto; }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto; }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%; }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%; }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%; }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0; }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0; }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem; }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem; }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem; }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem; }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem; }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem; }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem; }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem; }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 2rem; }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 2rem; }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 2.5rem; }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 2.5rem; }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 3rem; }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 3rem; }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 3.5rem; }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 3.5rem; }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 4rem; }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 4rem; }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 5rem; }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 5rem; }
  .g-xxl-11,
  .gx-xxl-11 {
    --bs-gutter-x: 6rem; }
  .g-xxl-11,
  .gy-xxl-11 {
    --bs-gutter-y: 6rem; }
  .g-xxl-12,
  .gx-xxl-12 {
    --bs-gutter-x: 7rem; }
  .g-xxl-12,
  .gy-xxl-12 {
    --bs-gutter-y: 7rem; } }

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-emphasis-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-emphasis-color);
  --bs-table-striped-bg: rgba(var(--bs-emphasis-color-rgb), 0.05);
  --bs-table-active-color: var(--bs-emphasis-color);
  --bs-table-active-bg: rgba(var(--bs-emphasis-color-rgb), 0.1);
  --bs-table-hover-color: var(--bs-emphasis-color);
  --bs-table-hover-bg: rgba(var(--bs-emphasis-color-rgb), 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color); }
  .table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
    background-color: var(--bs-table-bg);
    border-bottom-width: var(--bs-border-width);
    box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg))); }
  .table > tbody {
    vertical-align: inherit; }
  .table > thead {
    vertical-align: bottom; }

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor; }

.caption-top {
  caption-side: top; }

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem; }

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0; }
  .table-bordered > :not(caption) > * > * {
    border-width: 0 var(--bs-border-width); }

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0; }

.table-borderless > :not(:first-child) {
  border-top-width: 0; }

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg); }

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg); }

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg); }

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #fad7da;
  --bs-table-border-color: #c8acae;
  --bs-table-striped-bg: #eecccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1c2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e7e8ea;
  --bs-table-border-color: #b9babb;
  --bs-table-striped-bg: #dbdcde;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d0d1d3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d6d7d8;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d4f1e3;
  --bs-table-border-color: #aac1b6;
  --bs-table-striped-bg: #c9e5d8;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bfd9cc;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c4dfd2;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #d8e7ff;
  --bs-table-border-color: #adb9cc;
  --bs-table-striped-bg: #cddbf2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d0e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8d6ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #ffead7;
  --bs-table-border-color: #ccbbac;
  --bs-table-striped-bg: #f2decc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d3c2;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd8c7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffe2df;
  --bs-table-border-color: #ccb5b2;
  --bs-table-striped-bg: #f2d7d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6cbc9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd1ce;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f6f9fc;
  --bs-table-border-color: #c5c7ca;
  --bs-table-striped-bg: #eaedef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dde0e3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e6e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #02010a;
  --bs-table-border-color: #35343b;
  --bs-table-striped-bg: #0f0e16;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1b1a23;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #15141c;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color); }

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; } }

.form-label {
  margin-bottom: 0.5rem;
  font-size: 0.85rem;
  color: var(--bs-gray-900); }

.col-form-label {
  padding-top: calc(0.575rem + var(--bs-border-width));
  padding-bottom: calc(0.575rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.7;
  color: var(--bs-gray-900); }

.col-form-label-lg {
  padding-top: calc(0.825rem + var(--bs-border-width));
  padding-bottom: calc(0.825rem + var(--bs-border-width));
  font-size: 0.9rem; }

.col-form-label-sm {
  padding-top: calc(0.425rem + var(--bs-border-width));
  padding-bottom: calc(0.425rem + var(--bs-border-width));
  font-size: 0.792rem; }

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color); }

.form-control {
  display: block;
  width: 100%;
  padding: 0.575rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.7;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-gray-300);
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control[type="file"] {
    overflow: hidden; }
    .form-control[type="file"]:not(:disabled):not([readonly]) {
      cursor: pointer; }
  .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: #f39ca3;
    outline: 0;
    box-shadow: unset; }
  .form-control::-webkit-date-and-time-value {
    min-width: 85px;
    height: 1.7em;
    margin: 0; }
  .form-control::-webkit-datetime-edit {
    display: block;
    padding: 0; }
  .form-control::placeholder {
    color: var(--bs-secondary-color);
    opacity: 1; }
  .form-control:disabled {
    background-color: var(--bs-secondary-bg);
    opacity: 1; }
  .form-control::file-selector-button {
    padding: 0.575rem 1rem;
    margin: -0.575rem -1rem;
    margin-inline-end: 1rem;
    color: var(--bs-body-color);
    background-color: var(--bs-tertiary-bg);
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: var(--bs-border-width);
    border-radius: 0;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-control::file-selector-button {
        transition: none; } }
  .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: var(--bs-secondary-bg); }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.575rem 0;
  margin-bottom: 0;
  line-height: 1.7;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0; }
  .form-control-plaintext:focus {
    outline: 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.7em + 0.85rem + calc(var(--bs-border-width) * 2));
  padding: 0.425rem 1rem;
  font-size: 0.792rem; }
  .form-control-sm::file-selector-button {
    padding: 0.425rem 1rem;
    margin: -0.425rem -1rem;
    margin-inline-end: 1rem; }

.form-control-lg {
  min-height: calc(1.7em + 1.65rem + calc(var(--bs-border-width) * 2));
  padding: 0.825rem 1.75rem;
  font-size: 0.9rem; }
  .form-control-lg::file-selector-button {
    padding: 0.825rem 1.75rem;
    margin: -0.825rem -1.75rem;
    margin-inline-end: 1.75rem; }

textarea.form-control {
  min-height: calc(1.7em + 1.15rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-sm {
  min-height: calc(1.7em + 0.85rem + calc(var(--bs-border-width) * 2)); }

textarea.form-control-lg {
  min-height: calc(1.7em + 1.65rem + calc(var(--bs-border-width) * 2)); }

.form-control-color {
  width: 3rem;
  height: calc(1.7em + 1.15rem + calc(var(--bs-border-width) * 2));
  padding: 0.575rem; }
  .form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer; }
  .form-control-color::-moz-color-swatch {
    border: 0 !important; }
  .form-control-color::-webkit-color-swatch {
    border: 0 !important; }
  .form-control-color.form-control-sm {
    height: calc(1.7em + 0.85rem + calc(var(--bs-border-width) * 2)); }
  .form-control-color.form-control-lg {
    height: calc(1.7em + 1.65rem + calc(var(--bs-border-width) * 2)); }

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23434a57' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.575rem 3rem 0.575rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.7;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-gray-300);
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-select {
      transition: none; } }
  .form-select:focus {
    border-color: #f39ca3;
    outline: 0;
    box-shadow: 0 0 0 0.125rem rgba(230, 57, 70, 0.25); }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    padding-right: 1rem;
    background-image: none; }
  .form-select:disabled {
    background-color: var(--bs-secondary-bg); }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--bs-body-color); }

.form-select-sm {
  padding-top: 0.425rem;
  padding-bottom: 0.425rem;
  padding-left: 1rem;
  font-size: 0.792rem; }

.form-select-lg {
  padding-top: 0.825rem;
  padding-bottom: 0.825rem;
  padding-left: 1.75rem;
  font-size: 0.9rem; }

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23999999' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); }

.form-check {
  display: block;
  min-height: 1.53rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.5em; }

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right; }
  .form-check-reverse .form-check-input {
    float: right;
    margin-right: -1.5em;
    margin-left: 0; }

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.35em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #f39ca3;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(230, 57, 70, 0.25); }
  .form-check-input:checked {
    background-color: #e63946;
    border-color: #e63946; }
    .form-check-input:checked[type="checkbox"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #e63946;
    border-color: #e63946;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e"); }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    cursor: default;
    opacity: 0.5; }

.form-switch {
  padding-left: 3.5em; }
  .form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 3em;
    margin-left: -3.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 0;
    transition: background-position 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-switch .form-check-input {
        transition: none; } }
    .form-switch .form-check-input:focus {
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23f39ca3'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-switch.form-check-reverse {
    padding-right: 3.5em;
    padding-left: 0; }
    .form-switch.form-check-reverse .form-check-input {
      margin-right: -3.5em;
      margin-left: 0; }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }
  .btn-check[disabled] + .btn, .btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65; }

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e"); }

.form-range {
  width: 100%;
  height: 1.25rem;
  padding: 0;
  appearance: none;
  background-color: transparent; }
  .form-range:focus {
    outline: 0; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, unset; }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, unset; }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    appearance: none;
    background-color: #e63946;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #f8c4c8; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    appearance: none;
    background-color: #e63946;
    border: 0;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #f8c4c8; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--bs-secondary-bg);
    border-color: transparent; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: var(--bs-secondary-color); }
    .form-range:disabled::-moz-range-thumb {
      background-color: var(--bs-secondary-color); }

.form-floating {
  position: relative; }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext,
  .form-floating > .form-select {
    height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
    line-height: 1.25; }
  .form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    height: 100%;
    padding: 1rem 1rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .form-floating > label {
        transition: none; } }
  .form-floating > .form-control,
  .form-floating > .form-control-plaintext {
    padding: 1rem 1rem; }
    .form-floating > .form-control::placeholder,
    .form-floating > .form-control-plaintext::placeholder {
      color: transparent; }
    .form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
    .form-floating > .form-control-plaintext:focus,
    .form-floating > .form-control-plaintext:not(:placeholder-shown) {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
    .form-floating > .form-control:-webkit-autofill,
    .form-floating > .form-control-plaintext:-webkit-autofill {
      padding-top: 1.625rem;
      padding-bottom: 0.625rem; }
  .form-floating > .form-select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem; }
  .form-floating > .form-control:focus ~ label,
  .form-floating > .form-control:not(:placeholder-shown) ~ label,
  .form-floating > .form-control-plaintext ~ label,
  .form-floating > .form-select ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
    .form-floating > .form-control:focus ~ label::after,
    .form-floating > .form-control:not(:placeholder-shown) ~ label::after,
    .form-floating > .form-control-plaintext ~ label::after,
    .form-floating > .form-select ~ label::after {
      position: absolute;
      inset: 1rem 0.5rem;
      z-index: -1;
      height: 1.5em;
      content: "";
      background-color: var(--bs-body-bg); }
  .form-floating > .form-control:-webkit-autofill ~ label {
    color: rgba(var(--bs-body-color-rgb), 0.65);
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem); }
  .form-floating > .form-control-plaintext ~ label {
    border-width: var(--bs-border-width) 0; }
  .form-floating > :disabled ~ label,
  .form-floating > .form-control:disabled ~ label {
    color: #858c97; }
    .form-floating > :disabled ~ label::after,
    .form-floating > .form-control:disabled ~ label::after {
      background-color: var(--bs-secondary-bg); }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-floating {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-floating:focus-within {
    z-index: 5; }
  .input-group .btn {
    position: relative;
    z-index: 2; }
    .input-group .btn:focus {
      z-index: 5; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.575rem 1rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.7;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-gray-300); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.825rem 1.75rem;
  font-size: 0.9rem; }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.425rem 1rem;
  font-size: 0.792rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 4rem; }

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1); }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color); }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.792rem;
  color: #fff;
  background-color: var(--bs-success); }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.7em + 1.15rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328bb74' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.2875rem) center;
  background-size: calc(0.85em + 0.575rem) calc(0.85em + 0.575rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.125rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.7em + 1.15rem);
  background-position: top calc(0.425em + 0.2875rem) right calc(0.425em + 0.2875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328bb74' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    padding-right: 5.5rem;
    background-position: right 1rem center, center right 3rem;
    background-size: 16px 12px, calc(0.85em + 0.575rem) calc(0.85em + 0.575rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: var(--bs-form-valid-border-color);
    box-shadow: 0 0 0 0.125rem rgba(var(--bs-success-rgb), 0.25); }

.was-validated .form-control-color:valid, .form-control-color.is-valid {
  width: calc(3rem + calc(1.7em + 1.15rem)); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color); }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: var(--bs-form-valid-color); }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.125rem rgba(var(--bs-success-rgb), 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--bs-form-valid-color); }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid, .was-validated
.input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid, .was-validated
.input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3; }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color); }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.792rem;
  color: #fff;
  background-color: var(--bs-danger); }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.7em + 1.15rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff6f61'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff6f61' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.425em + 0.2875rem) center;
  background-size: calc(0.85em + 0.575rem) calc(0.85em + 0.575rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.125rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.7em + 1.15rem);
  background-position: top calc(0.425em + 0.2875rem) right calc(0.425em + 0.2875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
    --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ff6f61'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ff6f61' stroke='none'/%3e%3c/svg%3e");
    padding-right: 5.5rem;
    background-position: right 1rem center, center right 3rem;
    background-size: 16px 12px, calc(0.85em + 0.575rem) calc(0.85em + 0.575rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: var(--bs-form-invalid-border-color);
    box-shadow: 0 0 0 0.125rem rgba(var(--bs-danger-rgb), 0.25); }

.was-validated .form-control-color:invalid, .form-control-color.is-invalid {
  width: calc(3rem + calc(1.7em + 1.15rem)); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color); }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: var(--bs-form-invalid-color); }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.125rem rgba(var(--bs-danger-rgb), 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-form-invalid-color); }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid, .was-validated
.input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid, .was-validated
.input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4; }

.btn {
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.675rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.9rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.7;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: unset;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  background-color: var(--bs-btn-bg);
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color); }
  .btn-check + .btn:hover {
    color: var(--bs-btn-color);
    background-color: var(--bs-btn-bg);
    border-color: var(--bs-btn-border-color); }
  .btn:focus-visible {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:focus-visible + .btn {
    border-color: var(--bs-btn-hover-border-color);
    outline: 0;
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
    color: var(--bs-btn-active-color);
    background-color: var(--bs-btn-active-bg);
    border-color: var(--bs-btn-active-border-color); }
    .btn-check:checked + .btn:focus-visible,
    :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
      box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn-check:checked:focus-visible + .btn {
    box-shadow: var(--bs-btn-focus-box-shadow); }
  .btn:disabled, .btn.disabled,
  fieldset:disabled .btn {
    color: var(--bs-btn-disabled-color);
    pointer-events: none;
    background-color: var(--bs-btn-disabled-bg);
    border-color: var(--bs-btn-disabled-border-color);
    opacity: var(--bs-btn-disabled-opacity); }

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #e63946;
  --bs-btn-border-color: #e63946;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #c4303c;
  --bs-btn-hover-border-color: #b82e38;
  --bs-btn-focus-shadow-rgb: 234, 87, 98;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #b82e38;
  --bs-btn-active-border-color: #ad2b35;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #e63946;
  --bs-btn-disabled-border-color: #e63946; }

.btn-secondary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #858c97;
  --bs-btn-border-color: #858c97;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #717780;
  --bs-btn-hover-border-color: #6a7079;
  --bs-btn-focus-shadow-rgb: 151, 157, 167;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6a7079;
  --bs-btn-active-border-color: #646971;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #858c97;
  --bs-btn-disabled-border-color: #858c97; }

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #28bb74;
  --bs-btn-border-color: #28bb74;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #229f63;
  --bs-btn-hover-border-color: #20965d;
  --bs-btn-focus-shadow-rgb: 72, 197, 137;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #20965d;
  --bs-btn-active-border-color: #1e8c57;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #28bb74;
  --bs-btn-disabled-border-color: #28bb74; }

.btn-info {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3b86ff;
  --bs-btn-border-color: #3b86ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3272d9;
  --bs-btn-hover-border-color: #2f6bcc;
  --bs-btn-focus-shadow-rgb: 88, 152, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2f6bcc;
  --bs-btn-active-border-color: #2c65bf;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3b86ff;
  --bs-btn-disabled-border-color: #3b86ff; }

.btn-warning {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ff9736;
  --bs-btn-border-color: #ff9736;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d9802e;
  --bs-btn-hover-border-color: #cc792b;
  --bs-btn-focus-shadow-rgb: 255, 167, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc792b;
  --bs-btn-active-border-color: #bf7129;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ff9736;
  --bs-btn-disabled-border-color: #ff9736; }

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #ff6f61;
  --bs-btn-border-color: #ff6f61;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #d95e52;
  --bs-btn-hover-border-color: #cc594e;
  --bs-btn-focus-shadow-rgb: 255, 133, 121;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #cc594e;
  --bs-btn-active-border-color: #bf5349;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #ff6f61;
  --bs-btn-disabled-border-color: #ff6f61; }

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f6f9fc;
  --bs-btn-border-color: #f6f9fc;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d1d4d6;
  --bs-btn-hover-border-color: #c5c7ca;
  --bs-btn-focus-shadow-rgb: 209, 212, 214;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c5c7ca;
  --bs-btn-active-border-color: #b9bbbd;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f6f9fc;
  --bs-btn-disabled-border-color: #f6f9fc; }

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fff;
  --bs-btn-disabled-border-color: #fff; }

.btn-black {
  --bs-btn-color: #fff;
  --bs-btn-bg: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: black;
  --bs-btn-hover-border-color: black;
  --bs-btn-focus-shadow-rgb: 38, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: black;
  --bs-btn-active-border-color: black;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #000;
  --bs-btn-disabled-border-color: #000; }

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #02010a;
  --bs-btn-border-color: #02010a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #28272f;
  --bs-btn-hover-border-color: #1b1a23;
  --bs-btn-focus-shadow-rgb: 40, 39, 47;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #35343b;
  --bs-btn-active-border-color: #1b1a23;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #02010a;
  --bs-btn-disabled-border-color: #02010a; }

.btn-outline-primary {
  --bs-btn-color: #e63946;
  --bs-btn-border-color: #e63946;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e63946;
  --bs-btn-hover-border-color: #e63946;
  --bs-btn-focus-shadow-rgb: 230, 57, 70;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e63946;
  --bs-btn-active-border-color: #e63946;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #e63946;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e63946;
  --bs-gradient: none; }

.btn-outline-secondary {
  --bs-btn-color: #858c97;
  --bs-btn-border-color: #858c97;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #858c97;
  --bs-btn-hover-border-color: #858c97;
  --bs-btn-focus-shadow-rgb: 133, 140, 151;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #858c97;
  --bs-btn-active-border-color: #858c97;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #858c97;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #858c97;
  --bs-gradient: none; }

.btn-outline-success {
  --bs-btn-color: #28bb74;
  --bs-btn-border-color: #28bb74;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #28bb74;
  --bs-btn-hover-border-color: #28bb74;
  --bs-btn-focus-shadow-rgb: 40, 187, 116;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #28bb74;
  --bs-btn-active-border-color: #28bb74;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #28bb74;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #28bb74;
  --bs-gradient: none; }

.btn-outline-info {
  --bs-btn-color: #3b86ff;
  --bs-btn-border-color: #3b86ff;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3b86ff;
  --bs-btn-hover-border-color: #3b86ff;
  --bs-btn-focus-shadow-rgb: 59, 134, 255;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3b86ff;
  --bs-btn-active-border-color: #3b86ff;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #3b86ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3b86ff;
  --bs-gradient: none; }

.btn-outline-warning {
  --bs-btn-color: #ff9736;
  --bs-btn-border-color: #ff9736;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff9736;
  --bs-btn-hover-border-color: #ff9736;
  --bs-btn-focus-shadow-rgb: 255, 151, 54;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff9736;
  --bs-btn-active-border-color: #ff9736;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #ff9736;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff9736;
  --bs-gradient: none; }

.btn-outline-danger {
  --bs-btn-color: #ff6f61;
  --bs-btn-border-color: #ff6f61;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ff6f61;
  --bs-btn-hover-border-color: #ff6f61;
  --bs-btn-focus-shadow-rgb: 255, 111, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ff6f61;
  --bs-btn-active-border-color: #ff6f61;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #ff6f61;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ff6f61;
  --bs-gradient: none; }

.btn-outline-light {
  --bs-btn-color: #f6f9fc;
  --bs-btn-border-color: #f6f9fc;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f6f9fc;
  --bs-btn-hover-border-color: #f6f9fc;
  --bs-btn-focus-shadow-rgb: 246, 249, 252;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f6f9fc;
  --bs-btn-active-border-color: #f6f9fc;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #f6f9fc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f6f9fc;
  --bs-gradient: none; }

.btn-outline-white {
  --bs-btn-color: #fff;
  --bs-btn-border-color: #fff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fff;
  --bs-btn-hover-border-color: #fff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fff;
  --bs-btn-active-border-color: #fff;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fff;
  --bs-gradient: none; }

.btn-outline-black {
  --bs-btn-color: #000;
  --bs-btn-border-color: #000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #000;
  --bs-btn-hover-border-color: #000;
  --bs-btn-focus-shadow-rgb: 0, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #000;
  --bs-btn-active-border-color: #000;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #000;
  --bs-gradient: none; }

.btn-outline-dark {
  --bs-btn-color: #02010a;
  --bs-btn-border-color: #02010a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #02010a;
  --bs-btn-hover-border-color: #02010a;
  --bs-btn-focus-shadow-rgb: 2, 1, 10;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #02010a;
  --bs-btn-active-border-color: #02010a;
  --bs-btn-active-shadow: unset;
  --bs-btn-disabled-color: #02010a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #02010a;
  --bs-gradient: none; }

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: #576071;
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: #e63946;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: #e63946;
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #858c97;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 234, 87, 98;
  text-decoration: none; }
  .btn-link:focus-visible {
    color: var(--bs-btn-color); }
  .btn-link:hover {
    color: var(--bs-btn-hover-color); }

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 0.825rem;
  --bs-btn-padding-x: 1.75rem;
  --bs-btn-font-size: 0.9rem;
  --bs-btn-border-radius: 0.75rem; }

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 0.425rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 0.792rem;
  --bs-btn-border-radius: 0.2rem; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.collapse-horizontal {
        transition: none; } }

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0.7rem;
  --bs-dropdown-padding-y: 0.7rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.855rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-gray-300);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-gray-300);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: var(--bs-box-shadow);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: #e63946;
  --bs-dropdown-link-hover-bg: rgba(var(--bs-primary-rgb), 0.1);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #e63946;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1.25rem;
  --bs-dropdown-item-padding-y: 0.5rem;
  --bs-dropdown-header-color: #858c97;
  --bs-dropdown-header-padding-x: 1.25rem;
  --bs-dropdown-header-padding-y: 0.7rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color); }
  .dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--bs-dropdown-spacer); }

.dropdown-menu-start {
  --bs-position: start; }
  .dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0; }

.dropdown-menu-end {
  --bs-position: end; }
  .dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start; }
    .dropdown-menu-sm-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-sm-end {
    --bs-position: end; }
    .dropdown-menu-sm-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start; }
    .dropdown-menu-md-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-md-end {
    --bs-position: end; }
    .dropdown-menu-md-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start; }
    .dropdown-menu-lg-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-lg-end {
    --bs-position: end; }
    .dropdown-menu-lg-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start; }
    .dropdown-menu-xl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xl-end {
    --bs-position: end; }
    .dropdown-menu-xl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start; }
    .dropdown-menu-xxl-start[data-bs-popper] {
      right: auto;
      left: 0; }
  .dropdown-menu-xxl-end {
    --bs-position: end; }
    .dropdown-menu-xxl-end[data-bs-popper] {
      right: 0;
      left: auto; } }

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer); }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer); }

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropend .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropend .dropdown-toggle::after {
  vertical-align: 0; }

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer); }

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropstart .dropdown-toggle::after {
  display: none; }

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropstart .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: var(--bs-dropdown-link-hover-color);
    background-color: var(--bs-dropdown-link-hover-bg); }
  .dropdown-item.active, .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--bs-dropdown-link-active-bg); }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--bs-dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.792rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color); }

.dropdown-menu-dark {
  --bs-dropdown-color: #e3e9ef;
  --bs-dropdown-bg: #434a57;
  --bs-dropdown-border-color: var(--bs-gray-300);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #e3e9ef;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-gray-300);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #e63946;
  --bs-dropdown-link-disabled-color: #b4bbc3;
  --bs-dropdown-header-color: #b4bbc3; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
  .btn-group > .btn-check:checked + .btn,
  .btn-group > .btn-check:focus + .btn,
  .btn-group > .btn:hover,
  .btn-group > .btn:focus,
  .btn-group > .btn:active,
  .btn-group > .btn.active,
  .btn-group-vertical > .btn-check:checked + .btn,
  .btn-group-vertical > .btn-check:focus + .btn,
  .btn-group-vertical > .btn:hover,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1); }

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropend .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropstart .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.3125rem;
  padding-left: 1.3125rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1); }

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-link {
      transition: none; } }
  .nav-link:hover, .nav-link:focus {
    color: var(--bs-nav-link-hover-color); }
  .nav-link:focus-visible {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(230, 57, 70, 0.25); }
  .nav-link.disabled, .nav-link:disabled {
    color: var(--bs-nav-link-disabled-color);
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color); }
  .nav-tabs .nav-link {
    margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
    border: var(--bs-nav-tabs-border-width) solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: var(--bs-nav-tabs-link-hover-border-color); }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: var(--bs-nav-tabs-link-active-color);
    background-color: var(--bs-nav-tabs-link-active-bg);
    border-color: var(--bs-nav-tabs-link-active-border-color); }
  .nav-tabs .dropdown-menu {
    margin-top: calc(-1 * var(--bs-nav-tabs-border-width)); }

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #e63946; }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: var(--bs-nav-pills-link-active-bg); }

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap); }
  .nav-underline .nav-link {
    padding-right: 0;
    padding-left: 0;
    border-bottom: var(--bs-nav-underline-border-width) solid transparent; }
    .nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
      border-bottom-color: currentcolor; }
  .nav-underline .nav-link.active,
  .nav-underline .show > .nav-link {
    font-weight: 700;
    color: var(--bs-nav-underline-link-active-color);
    border-bottom-color: currentcolor; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.40438rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.0125rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 1rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.0125rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2875, 86, 107, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.25rem;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x); }
  .navbar > .container,
  .navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    color: var(--bs-navbar-brand-hover-color); }

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
    color: var(--bs-navbar-active-color); }
  .navbar-nav .dropdown-menu {
    position: static; }

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color); }
  .navbar-text a,
  .navbar-text a:hover,
  .navbar-text a:focus {
    color: var(--bs-navbar-active-color); }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  transition: var(--bs-navbar-toggler-transition); }
  @media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
      transition: none; } }
  .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width); }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%; }

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto; }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-sm .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-sm .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-md .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-md .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-lg .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-lg .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: var(--bs-navbar-nav-link-padding-x);
        padding-left: var(--bs-navbar-nav-link-padding-x); }
    .navbar-expand-xxl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; }
    .navbar-expand-xxl .offcanvas {
      position: static;
      z-index: auto;
      flex-grow: 1;
      width: auto !important;
      height: auto !important;
      visibility: visible !important;
      background-color: transparent !important;
      border: 0 !important;
      transform: none !important;
      transition: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-header {
        display: none; }
      .navbar-expand-xxl .offcanvas .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible; } }

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x); }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none; }
    .navbar-expand .offcanvas .offcanvas-header {
      display: none; }
    .navbar-expand .offcanvas .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible; }

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 1px;
  --bs-card-border-color: var(--bs-gray-300);
  --bs-card-border-radius: 0.4rem;
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(0.4rem - 1px);
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: transparent;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0; }
    .card > .list-group:last-child {
      border-bottom-width: 0; }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color); }

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color); }

.card-subtitle {
  margin-top: calc(-.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color); }

.card-text:last-child {
  margin-bottom: 0; }

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x); }

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color); }

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color); }

.card-header-tabs {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0; }
  .card-header-tabs .nav-link.active {
    background-color: var(--bs-card-bg);
    border-bottom-color: var(--bs-card-bg); }

.card-header-pills {
  margin-right: calc(-.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-.5 * var(--bs-card-cap-padding-x)); }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding); }

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%; }

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin); }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; } }

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%234b566b' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%235c171c' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: unset;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle); }

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.9rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition); }
  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none; } }
  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color); }
    .accordion-button:not(.collapsed)::after {
      background-image: var(--bs-accordion-btn-active-icon);
      transform: var(--bs-accordion-btn-icon-transform); }
  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition); }
    @media (prefers-reduced-motion: reduce) {
      .accordion-button::after {
        transition: none; } }
  .accordion-button:hover {
    z-index: 2; }
  .accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow); }

.accordion-header {
  margin-bottom: 0; }

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color); }
  .accordion-item:not(:first-of-type) {
    border-top: 0; }

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x); }

.accordion-flush > .accordion-item {
  border-right: 0;
  border-left: 0; }
  .accordion-flush > .accordion-item:first-child {
    border-top: 0; }
  .accordion-flush > .accordion-item:last-child {
    border-bottom: 0; }

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f08890'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23f08890'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e"); }

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg); }

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x); }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: var(--bs-breadcrumb-item-padding-x);
    color: var(--bs-breadcrumb-divider-color);
    content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */; }

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color); }

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.9rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(230, 57, 70, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #e63946;
  --bs-pagination-active-border-color: #e63946;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .page-link {
      transition: none; } }
  .page-link:hover {
    z-index: 2;
    color: var(--bs-pagination-hover-color);
    background-color: var(--bs-pagination-hover-bg);
    border-color: var(--bs-pagination-hover-border-color); }
  .page-link:focus {
    z-index: 3;
    color: var(--bs-pagination-focus-color);
    background-color: var(--bs-pagination-focus-bg);
    outline: 0;
    box-shadow: var(--bs-pagination-focus-box-shadow); }
  .page-link.active,
  .active > .page-link {
    z-index: 3;
    color: var(--bs-pagination-active-color);
    background-color: var(--bs-pagination-active-bg);
    border-color: var(--bs-pagination-active-border-color); }
  .page-link.disabled,
  .disabled > .page-link {
    color: var(--bs-pagination-disabled-color);
    pointer-events: none;
    background-color: var(--bs-pagination-disabled-bg);
    border-color: var(--bs-pagination-disabled-border-color); }

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1); }

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.0125rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg); }

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.792rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm); }

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border); }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color); }

.alert-dismissible {
  padding-right: 3rem; }
  .alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem; }

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis); }

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis); }

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis); }

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis); }

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis); }

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis); }

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis); }

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis); }

.alert-black {
  --bs-alert-color: var(--bs-black-text-emphasis);
  --bs-alert-bg: var(--bs-black-bg-subtle);
  --bs-alert-border-color: var(--bs-black-border-subtle);
  --bs-alert-link-color: var(--bs-black-text-emphasis); }

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis); }

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.675rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #e63946;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg); }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition); }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height); }

.progress-stacked > .progress {
  overflow: visible; }

.progress-stacked > .progress > .progress-bar {
  width: 100%; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #e63946;
  --bs-list-group-active-border-color: #e63946;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-numbered {
  list-style-type: none;
  counter-reset: section; }
  .list-group-numbered > .list-group-item::before {
    content: counters(section, ".") ". ";
    counter-increment: section; }

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: var(--bs-list-group-action-hover-color);
    text-decoration: none;
    background-color: var(--bs-list-group-action-hover-bg); }
  .list-group-item-action:active {
    color: var(--bs-list-group-action-active-color);
    background-color: var(--bs-list-group-action-active-bg); }

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color); }
  .list-group-item.disabled, .list-group-item:disabled {
    color: var(--bs-list-group-disabled-color);
    pointer-events: none;
    background-color: var(--bs-list-group-disabled-bg); }
  .list-group-item.active {
    z-index: 2;
    color: var(--bs-list-group-active-color);
    background-color: var(--bs-list-group-active-bg);
    border-color: var(--bs-list-group-active-border-color); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: calc(-1 * var(--bs-list-group-border-width));
      border-top-width: var(--bs-list-group-border-width); }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: calc(-1 * var(--bs-list-group-border-width));
      border-left-width: var(--bs-list-group-border-width); }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
      border-top-width: var(--bs-list-group-border-width);
      border-left-width: 0; }
      .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: calc(-1 * var(--bs-list-group-border-width));
        border-left-width: var(--bs-list-group-border-width); } }

.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width); }
  .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0; }

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis); }

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis); }

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis); }

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis); }

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis); }

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis); }

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis); }

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis); }

.list-group-item-black {
  --bs-list-group-color: var(--bs-black-text-emphasis);
  --bs-list-group-bg: var(--bs-black-bg-subtle);
  --bs-list-group-border-color: var(--bs-black-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-black-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-black-border-subtle);
  --bs-list-group-active-color: var(--bs-black-bg-subtle);
  --bs-list-group-active-bg: var(--bs-black-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-black-text-emphasis); }

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis); }

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(230, 57, 70, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  opacity: var(--bs-btn-close-opacity); }
  .btn-close:hover {
    color: var(--bs-btn-close-color);
    text-decoration: none;
    opacity: var(--bs-btn-close-hover-opacity); }
  .btn-close:focus {
    outline: 0;
    box-shadow: var(--bs-btn-close-focus-shadow);
    opacity: var(--bs-btn-close-focus-opacity); }
  .btn-close:disabled, .btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: var(--bs-btn-close-disabled-opacity); }

.btn-close-white {
  filter: var(--bs-btn-close-white-filter); }

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter); }

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow); }
  .toast.showing {
    opacity: 0; }
  .toast:not(.show) {
    display: none; }

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none; }
  .toast-container > :not(:last-child) {
    margin-bottom: var(--bs-toast-spacing); }

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color); }
  .toast-header .btn-close {
    margin-right: calc(-.5 * var(--bs-toast-padding-x));
    margin-left: var(--bs-toast-padding-x); }

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word; }

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: var(--bs-box-shadow-sm);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.7;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2); }
  .modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  outline: 0; }

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg); }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: var(--bs-backdrop-opacity); }

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color); }
  .modal-header .btn-close {
    padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto; }

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height); }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding); }

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color); }
  .modal-footer > * {
    margin: calc(var(--bs-modal-footer-gap) * .5); }

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: var(--bs-box-shadow); }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto; }
  .modal-sm {
    --bs-modal-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px; } }

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0; }
  .modal-fullscreen .modal-content {
    height: 100%;
    border: 0; }
  .modal-fullscreen .modal-body {
    overflow-y: auto; }

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-sm-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-sm-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-md-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-md-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-lg-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-lg-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xl-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-xl-down .modal-body {
      overflow-y: auto; } }

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0; }
    .modal-fullscreen-xxl-down .modal-content {
      height: 100%;
      border: 0; }
    .modal-fullscreen-xxl-down .modal-body {
      overflow-y: auto; } }

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.792rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: var(--bs-tooltip-opacity); }
  .tooltip .tooltip-arrow {
    display: block;
    width: var(--bs-tooltip-arrow-width);
    height: var(--bs-tooltip-arrow-height); }
    .tooltip .tooltip-arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
    top: -1px;
    border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-top-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
    right: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * .5) 0;
    border-right-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height)); }
  .bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-bottom-color: var(--bs-tooltip-bg); }

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width); }
  .bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
    left: -1px;
    border-width: calc(var(--bs-tooltip-arrow-width) * .5) 0 calc(var(--bs-tooltip-arrow-width) * .5) var(--bs-tooltip-arrow-height);
    border-left-color: var(--bs-tooltip-bg); }

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg); }

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.792rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: var(--bs-box-shadow);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.9rem;
  --bs-popover-header-color: #152C5B;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color); }
  .popover .popover-arrow {
    display: block;
    width: var(--bs-popover-arrow-width);
    height: var(--bs-popover-arrow-height); }
    .popover .popover-arrow::before, .popover .popover-arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
      border-width: 0; }

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
    bottom: 0;
    border-top-color: var(--bs-popover-arrow-border); }
  .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
    bottom: var(--bs-popover-border-width);
    border-top-color: var(--bs-popover-bg); }

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * .5) 0; }
  .bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
    left: 0;
    border-right-color: var(--bs-popover-arrow-border); }
  .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
    left: var(--bs-popover-border-width);
    border-right-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width)); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    border-width: 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
    top: 0;
    border-bottom-color: var(--bs-popover-arrow-border); }
  .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
    top: var(--bs-popover-border-width);
    border-bottom-color: var(--bs-popover-bg); }

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg); }

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    border-width: calc(var(--bs-popover-arrow-width) * .5) 0 calc(var(--bs-popover-arrow-width) * .5) var(--bs-popover-arrow-height); }
  .bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
    right: 0;
    border-left-color: var(--bs-popover-arrow-border); }
  .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
    right: var(--bs-popover-border-width);
    border-left-color: var(--bs-popover-bg); }

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color); }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")*/; }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") /*rtl:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")*/; }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%; }
  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators [data-bs-target] {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center; }

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000; }

.carousel-dark .carousel-caption {
  color: #000; }

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon, [data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100); }

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target], [data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000; }

[data-bs-theme="dark"] .carousel .carousel-caption, [data-bs-theme="dark"].carousel .carousel-caption {
  color: #000; }

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name); }

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */; } }

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent; }

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0; }

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s; } }

.offcanvas-sm, .offcanvas-md, .offcanvas-lg, .offcanvas-xl, .offcanvas-xxl, .offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: var(--bs-box-shadow-sm);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.7; }

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-sm {
      transition: none; } }

@media (max-width: 575.98px) {
    .offcanvas-sm.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-sm.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-sm.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-sm.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
      transform: none; }
    .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
      visibility: visible; } }

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-sm .offcanvas-header {
      display: none; }
    .offcanvas-sm .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-md {
      transition: none; } }

@media (max-width: 767.98px) {
    .offcanvas-md.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-md.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-md.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-md.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
      transform: none; }
    .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
      visibility: visible; } }

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-md .offcanvas-header {
      display: none; }
    .offcanvas-md .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-lg {
      transition: none; } }

@media (max-width: 991.98px) {
    .offcanvas-lg.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-lg.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-lg.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-lg.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
      transform: none; }
    .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
      visibility: visible; } }

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-lg .offcanvas-header {
      display: none; }
    .offcanvas-lg .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xl {
      transition: none; } }

@media (max-width: 1199.98px) {
    .offcanvas-xl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xl.showing, .offcanvas-xl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xl.showing, .offcanvas-xl.hiding, .offcanvas-xl.show {
      visibility: visible; } }

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xl .offcanvas-header {
      display: none; }
    .offcanvas-xl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

@media (max-width: 1399.98px) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition); } }
  @media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
    .offcanvas-xxl {
      transition: none; } }

@media (max-width: 1399.98px) {
    .offcanvas-xxl.offcanvas-start {
      top: 0;
      left: 0;
      width: var(--bs-offcanvas-width);
      border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(-100%); }
    .offcanvas-xxl.offcanvas-end {
      top: 0;
      right: 0;
      width: var(--bs-offcanvas-width);
      border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateX(100%); }
    .offcanvas-xxl.offcanvas-top {
      top: 0;
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(-100%); }
    .offcanvas-xxl.offcanvas-bottom {
      right: 0;
      left: 0;
      height: var(--bs-offcanvas-height);
      max-height: 100%;
      border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
      transform: translateY(100%); }
    .offcanvas-xxl.showing, .offcanvas-xxl.show:not(.hiding) {
      transform: none; }
    .offcanvas-xxl.showing, .offcanvas-xxl.hiding, .offcanvas-xxl.show {
      visibility: visible; } }

@media (min-width: 1400px) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important; }
    .offcanvas-xxl .offcanvas-header {
      display: none; }
    .offcanvas-xxl .offcanvas-body {
      display: flex;
      flex-grow: 0;
      padding: 0;
      overflow-y: visible;
      background-color: transparent !important; } }

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition); }
  @media (prefers-reduced-motion: reduce) {
    .offcanvas {
      transition: none; } }
  .offcanvas.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%); }
  .offcanvas.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%); }
  .offcanvas.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%); }
  .offcanvas.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%); }
  .offcanvas.showing, .offcanvas.show:not(.hiding) {
    transform: none; }
  .offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
    visibility: visible; }

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .offcanvas-backdrop.fade {
    opacity: 0; }
  .offcanvas-backdrop.show {
    opacity: 0.5; }

.offcanvas-header {
  display: flex;
  align-items: center;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x); }
  .offcanvas-header .btn-close {
    padding: calc(var(--bs-offcanvas-padding-y) * .5) calc(var(--bs-offcanvas-padding-x) * .5);
    margin: calc(-.5 * var(--bs-offcanvas-padding-y)) calc(-.5 * var(--bs-offcanvas-padding-x)) calc(-.5 * var(--bs-offcanvas-padding-y)) auto; }

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height); }

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto; }

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5; }
  .placeholder.btn::before {
    display: inline-block;
    content: ""; }

.placeholder-xs {
  min-height: .6em; }

.placeholder-sm {
  min-height: .8em; }

.placeholder-lg {
  min-height: 1.2em; }

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite; }

@keyframes placeholder-glow {
  50% {
    opacity: 0.2; } }

.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite; }

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%; } }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-success {
  color: #fff !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(var(--bs-info-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-warning {
  color: #fff !important;
  background-color: RGBA(var(--bs-warning-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-black {
  color: #fff !important;
  background-color: RGBA(var(--bs-black-rgb), var(--bs-bg-opacity, 1)) !important; }

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important; }

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-primary:hover, .link-primary:focus {
    color: RGBA(184, 46, 56, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(184, 46, 56, var(--bs-link-underline-opacity, 1)) !important; }

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-secondary:hover, .link-secondary:focus {
    color: RGBA(106, 112, 121, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(106, 112, 121, var(--bs-link-underline-opacity, 1)) !important; }

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-success:hover, .link-success:focus {
    color: RGBA(32, 150, 93, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(32, 150, 93, var(--bs-link-underline-opacity, 1)) !important; }

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-info:hover, .link-info:focus {
    color: RGBA(47, 107, 204, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(47, 107, 204, var(--bs-link-underline-opacity, 1)) !important; }

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-warning:hover, .link-warning:focus {
    color: RGBA(204, 121, 43, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(204, 121, 43, var(--bs-link-underline-opacity, 1)) !important; }

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-danger:hover, .link-danger:focus {
    color: RGBA(204, 89, 78, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(204, 89, 78, var(--bs-link-underline-opacity, 1)) !important; }

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-light:hover, .link-light:focus {
    color: RGBA(248, 250, 253, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(248, 250, 253, var(--bs-link-underline-opacity, 1)) !important; }

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-white:hover, .link-white:focus {
    color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important; }

.link-black {
  color: RGBA(var(--bs-black-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-black-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-black:hover, .link-black:focus {
    color: RGBA(0, 0, 0, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(0, 0, 0, var(--bs-link-underline-opacity, 1)) !important; }

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-dark:hover, .link-dark:focus {
    color: RGBA(2, 1, 8, var(--bs-link-opacity, 1)) !important;
    text-decoration-color: RGBA(2, 1, 8, var(--bs-link-underline-opacity, 1)) !important; }

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }
  .link-body-emphasis:hover, .link-body-emphasis:focus {
    color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
    text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important; }

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color); }

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden; }
  .icon-link > .bi {
    flex-shrink: 0;
    width: 1em;
    height: 1em;
    fill: currentcolor;
    transition: 0.2s ease-in-out transform; }
    @media (prefers-reduced-motion: reduce) {
      .icon-link > .bi {
        transition: none; } }

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0)); }

.ratio {
  position: relative;
  width: 100%; }
  .ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""; }
  .ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.ratio-1x1 {
  --bs-aspect-ratio: 100%; }

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%); }

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%); }

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%); }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020; }

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020; }

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020; } }

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch; }

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch; }

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important; }
  .visually-hidden:not(caption),
  .visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
    position: absolute !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-start {
  float: left !important; }

.float-end {
  float: right !important; }

.float-none {
  float: none !important; }

.object-fit-contain {
  object-fit: contain !important; }

.object-fit-cover {
  object-fit: cover !important; }

.object-fit-fill {
  object-fit: fill !important; }

.object-fit-scale {
  object-fit: scale-down !important; }

.object-fit-none {
  object-fit: none !important; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-1 {
  opacity: 0.1 !important; }

.opacity-2 {
  opacity: 0.2 !important; }

.opacity-3 {
  opacity: 0.3 !important; }

.opacity-4 {
  opacity: 0.4 !important; }

.opacity-5 {
  opacity: 0.5 !important; }

.opacity-6 {
  opacity: 0.6 !important; }

.opacity-7 {
  opacity: 0.7 !important; }

.opacity-8 {
  opacity: 0.8 !important; }

.opacity-9 {
  opacity: 0.9 !important; }

.opacity-10 {
  opacity: 1 !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-visible {
  overflow: visible !important; }

.overflow-scroll {
  overflow: scroll !important; }

.overflow-x-auto {
  overflow-x: auto !important; }

.overflow-x-hidden {
  overflow-x: hidden !important; }

.overflow-x-visible {
  overflow-x: visible !important; }

.overflow-x-scroll {
  overflow-x: scroll !important; }

.overflow-y-auto {
  overflow-y: auto !important; }

.overflow-y-hidden {
  overflow-y: hidden !important; }

.overflow-y-visible {
  overflow-y: visible !important; }

.overflow-y-scroll {
  overflow-y: scroll !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-grid {
  display: grid !important; }

.d-inline-grid {
  display: inline-grid !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.d-none {
  display: none !important; }

.shadow {
  box-shadow: var(--bs-box-shadow) !important; }

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm) !important; }

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg) !important; }

.shadow-none {
  box-shadow: none !important; }

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-black {
  --bs-focus-ring-color: rgba(var(--bs-black-rgb), var(--bs-focus-ring-opacity)); }

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity)); }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.top-0 {
  top: 0 !important; }

.top-50 {
  top: 50% !important; }

.top-100 {
  top: 100% !important; }

.bottom-0 {
  bottom: 0 !important; }

.bottom-50 {
  bottom: 50% !important; }

.bottom-100 {
  bottom: 100% !important; }

.start-0 {
  left: 0 !important; }

.start-50 {
  left: 50% !important; }

.start-100 {
  left: 100% !important; }

.end-0 {
  right: 0 !important; }

.end-50 {
  right: 50% !important; }

.end-100 {
  right: 100% !important; }

.translate-middle {
  transform: translate(-50%, -50%) !important; }

.translate-middle-x {
  transform: translateX(-50%) !important; }

.translate-middle-y {
  transform: translateY(-50%) !important; }

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-end-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important; }

.border-start-0 {
  border-left: 0 !important; }

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important; }

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important; }

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important; }

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important; }

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity)) !important; }

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important; }

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important; }

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important; }

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important; }

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important; }

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important; }

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important; }

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important; }

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important; }

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important; }

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important; }

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important; }

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important; }

.border-1 {
  border-width: 1px !important; }

.border-2 {
  border-width: 2px !important; }

.border-3 {
  border-width: 3px !important; }

.border-4 {
  border-width: 4px !important; }

.border-5 {
  border-width: 5px !important; }

.border-opacity-10 {
  --bs-border-opacity: 0.1; }

.border-opacity-25 {
  --bs-border-opacity: 0.25; }

.border-opacity-50 {
  --bs-border-opacity: 0.5; }

.border-opacity-75 {
  --bs-border-opacity: 0.75; }

.border-opacity-100 {
  --bs-border-opacity: 1; }

.w-5 {
  width: 5% !important; }

.w-10 {
  width: 10% !important; }

.w-15 {
  width: 15% !important; }

.w-25 {
  width: 25% !important; }

.w-30 {
  width: 30% !important; }

.w-35 {
  width: 35% !important; }

.w-40 {
  width: 40% !important; }

.w-45 {
  width: 45% !important; }

.w-50 {
  width: 50% !important; }

.w-55 {
  width: 55% !important; }

.w-60 {
  width: 60% !important; }

.w-65 {
  width: 65% !important; }

.w-75 {
  width: 75% !important; }

.w-80 {
  width: 80% !important; }

.w-85 {
  width: 85% !important; }

.w-90 {
  width: 90% !important; }

.w-95 {
  width: 95% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-50 {
  min-height: 50vh !important; }

.min-vh-65 {
  min-height: 65vh !important; }

.min-vh-75 {
  min-height: 75vh !important; }

.min-vh-85 {
  min-height: 85vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.justify-content-evenly {
  justify-content: space-evenly !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.m-5 {
  margin: 2rem !important; }

.m-6 {
  margin: 2.5rem !important; }

.m-7 {
  margin: 3rem !important; }

.m-8 {
  margin: 3.5rem !important; }

.m-9 {
  margin: 4rem !important; }

.m-10 {
  margin: 5rem !important; }

.m-11 {
  margin: 6rem !important; }

.m-12 {
  margin: 7rem !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important; }

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important; }

.mx-5 {
  margin-right: 2rem !important;
  margin-left: 2rem !important; }

.mx-6 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important; }

.mx-7 {
  margin-right: 3rem !important;
  margin-left: 3rem !important; }

.mx-8 {
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important; }

.mx-9 {
  margin-right: 4rem !important;
  margin-left: 4rem !important; }

.mx-10 {
  margin-right: 5rem !important;
  margin-left: 5rem !important; }

.mx-11 {
  margin-right: 6rem !important;
  margin-left: 6rem !important; }

.mx-12 {
  margin-right: 7rem !important;
  margin-left: 7rem !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important; }

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important; }

.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important; }

.my-6 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.my-7 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important; }

.my-8 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important; }

.my-9 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important; }

.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.my-11 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important; }

.my-12 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 1.5rem !important; }

.mt-5 {
  margin-top: 2rem !important; }

.mt-6 {
  margin-top: 2.5rem !important; }

.mt-7 {
  margin-top: 3rem !important; }

.mt-8 {
  margin-top: 3.5rem !important; }

.mt-9 {
  margin-top: 4rem !important; }

.mt-10 {
  margin-top: 5rem !important; }

.mt-11 {
  margin-top: 6rem !important; }

.mt-12 {
  margin-top: 7rem !important; }

.mt-auto {
  margin-top: auto !important; }

.me-0 {
  margin-right: 0 !important; }

.me-1 {
  margin-right: 0.25rem !important; }

.me-2 {
  margin-right: 0.5rem !important; }

.me-3 {
  margin-right: 1rem !important; }

.me-4 {
  margin-right: 1.5rem !important; }

.me-5 {
  margin-right: 2rem !important; }

.me-6 {
  margin-right: 2.5rem !important; }

.me-7 {
  margin-right: 3rem !important; }

.me-8 {
  margin-right: 3.5rem !important; }

.me-9 {
  margin-right: 4rem !important; }

.me-10 {
  margin-right: 5rem !important; }

.me-11 {
  margin-right: 6rem !important; }

.me-12 {
  margin-right: 7rem !important; }

.me-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 1.5rem !important; }

.mb-5 {
  margin-bottom: 2rem !important; }

.mb-6 {
  margin-bottom: 2.5rem !important; }

.mb-7 {
  margin-bottom: 3rem !important; }

.mb-8 {
  margin-bottom: 3.5rem !important; }

.mb-9 {
  margin-bottom: 4rem !important; }

.mb-10 {
  margin-bottom: 5rem !important; }

.mb-11 {
  margin-bottom: 6rem !important; }

.mb-12 {
  margin-bottom: 7rem !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ms-0 {
  margin-left: 0 !important; }

.ms-1 {
  margin-left: 0.25rem !important; }

.ms-2 {
  margin-left: 0.5rem !important; }

.ms-3 {
  margin-left: 1rem !important; }

.ms-4 {
  margin-left: 1.5rem !important; }

.ms-5 {
  margin-left: 2rem !important; }

.ms-6 {
  margin-left: 2.5rem !important; }

.ms-7 {
  margin-left: 3rem !important; }

.ms-8 {
  margin-left: 3.5rem !important; }

.ms-9 {
  margin-left: 4rem !important; }

.ms-10 {
  margin-left: 5rem !important; }

.ms-11 {
  margin-left: 6rem !important; }

.ms-12 {
  margin-left: 7rem !important; }

.ms-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.m-n5 {
  margin: -2rem !important; }

.m-n6 {
  margin: -2.5rem !important; }

.m-n7 {
  margin: -3rem !important; }

.m-n8 {
  margin: -3.5rem !important; }

.m-n9 {
  margin: -4rem !important; }

.m-n10 {
  margin: -5rem !important; }

.m-n11 {
  margin: -6rem !important; }

.m-n12 {
  margin: -7rem !important; }

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important; }

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important; }

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important; }

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important; }

.mx-n5 {
  margin-right: -2rem !important;
  margin-left: -2rem !important; }

.mx-n6 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important; }

.mx-n7 {
  margin-right: -3rem !important;
  margin-left: -3rem !important; }

.mx-n8 {
  margin-right: -3.5rem !important;
  margin-left: -3.5rem !important; }

.mx-n9 {
  margin-right: -4rem !important;
  margin-left: -4rem !important; }

.mx-n10 {
  margin-right: -5rem !important;
  margin-left: -5rem !important; }

.mx-n11 {
  margin-right: -6rem !important;
  margin-left: -6rem !important; }

.mx-n12 {
  margin-right: -7rem !important;
  margin-left: -7rem !important; }

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important; }

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important; }

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important; }

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important; }

.my-n5 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important; }

.my-n6 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important; }

.my-n7 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important; }

.my-n8 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important; }

.my-n9 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important; }

.my-n10 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important; }

.my-n11 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important; }

.my-n12 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important; }

.mt-n1 {
  margin-top: -0.25rem !important; }

.mt-n2 {
  margin-top: -0.5rem !important; }

.mt-n3 {
  margin-top: -1rem !important; }

.mt-n4 {
  margin-top: -1.5rem !important; }

.mt-n5 {
  margin-top: -2rem !important; }

.mt-n6 {
  margin-top: -2.5rem !important; }

.mt-n7 {
  margin-top: -3rem !important; }

.mt-n8 {
  margin-top: -3.5rem !important; }

.mt-n9 {
  margin-top: -4rem !important; }

.mt-n10 {
  margin-top: -5rem !important; }

.mt-n11 {
  margin-top: -6rem !important; }

.mt-n12 {
  margin-top: -7rem !important; }

.me-n1 {
  margin-right: -0.25rem !important; }

.me-n2 {
  margin-right: -0.5rem !important; }

.me-n3 {
  margin-right: -1rem !important; }

.me-n4 {
  margin-right: -1.5rem !important; }

.me-n5 {
  margin-right: -2rem !important; }

.me-n6 {
  margin-right: -2.5rem !important; }

.me-n7 {
  margin-right: -3rem !important; }

.me-n8 {
  margin-right: -3.5rem !important; }

.me-n9 {
  margin-right: -4rem !important; }

.me-n10 {
  margin-right: -5rem !important; }

.me-n11 {
  margin-right: -6rem !important; }

.me-n12 {
  margin-right: -7rem !important; }

.mb-n1 {
  margin-bottom: -0.25rem !important; }

.mb-n2 {
  margin-bottom: -0.5rem !important; }

.mb-n3 {
  margin-bottom: -1rem !important; }

.mb-n4 {
  margin-bottom: -1.5rem !important; }

.mb-n5 {
  margin-bottom: -2rem !important; }

.mb-n6 {
  margin-bottom: -2.5rem !important; }

.mb-n7 {
  margin-bottom: -3rem !important; }

.mb-n8 {
  margin-bottom: -3.5rem !important; }

.mb-n9 {
  margin-bottom: -4rem !important; }

.mb-n10 {
  margin-bottom: -5rem !important; }

.mb-n11 {
  margin-bottom: -6rem !important; }

.mb-n12 {
  margin-bottom: -7rem !important; }

.ms-n1 {
  margin-left: -0.25rem !important; }

.ms-n2 {
  margin-left: -0.5rem !important; }

.ms-n3 {
  margin-left: -1rem !important; }

.ms-n4 {
  margin-left: -1.5rem !important; }

.ms-n5 {
  margin-left: -2rem !important; }

.ms-n6 {
  margin-left: -2.5rem !important; }

.ms-n7 {
  margin-left: -3rem !important; }

.ms-n8 {
  margin-left: -3.5rem !important; }

.ms-n9 {
  margin-left: -4rem !important; }

.ms-n10 {
  margin-left: -5rem !important; }

.ms-n11 {
  margin-left: -6rem !important; }

.ms-n12 {
  margin-left: -7rem !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.p-5 {
  padding: 2rem !important; }

.p-6 {
  padding: 2.5rem !important; }

.p-7 {
  padding: 3rem !important; }

.p-8 {
  padding: 3.5rem !important; }

.p-9 {
  padding: 4rem !important; }

.p-10 {
  padding: 5rem !important; }

.p-11 {
  padding: 6rem !important; }

.p-12 {
  padding: 7rem !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important; }

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important; }

.px-5 {
  padding-right: 2rem !important;
  padding-left: 2rem !important; }

.px-6 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important; }

.px-7 {
  padding-right: 3rem !important;
  padding-left: 3rem !important; }

.px-8 {
  padding-right: 3.5rem !important;
  padding-left: 3.5rem !important; }

.px-9 {
  padding-right: 4rem !important;
  padding-left: 4rem !important; }

.px-10 {
  padding-right: 5rem !important;
  padding-left: 5rem !important; }

.px-11 {
  padding-right: 6rem !important;
  padding-left: 6rem !important; }

.px-12 {
  padding-right: 7rem !important;
  padding-left: 7rem !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important; }

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important; }

.py-5 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important; }

.py-6 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.py-7 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important; }

.py-8 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important; }

.py-9 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important; }

.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.py-11 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important; }

.py-12 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 1.5rem !important; }

.pt-5 {
  padding-top: 2rem !important; }

.pt-6 {
  padding-top: 2.5rem !important; }

.pt-7 {
  padding-top: 3rem !important; }

.pt-8 {
  padding-top: 3.5rem !important; }

.pt-9 {
  padding-top: 4rem !important; }

.pt-10 {
  padding-top: 5rem !important; }

.pt-11 {
  padding-top: 6rem !important; }

.pt-12 {
  padding-top: 7rem !important; }

.pe-0 {
  padding-right: 0 !important; }

.pe-1 {
  padding-right: 0.25rem !important; }

.pe-2 {
  padding-right: 0.5rem !important; }

.pe-3 {
  padding-right: 1rem !important; }

.pe-4 {
  padding-right: 1.5rem !important; }

.pe-5 {
  padding-right: 2rem !important; }

.pe-6 {
  padding-right: 2.5rem !important; }

.pe-7 {
  padding-right: 3rem !important; }

.pe-8 {
  padding-right: 3.5rem !important; }

.pe-9 {
  padding-right: 4rem !important; }

.pe-10 {
  padding-right: 5rem !important; }

.pe-11 {
  padding-right: 6rem !important; }

.pe-12 {
  padding-right: 7rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 1.5rem !important; }

.pb-5 {
  padding-bottom: 2rem !important; }

.pb-6 {
  padding-bottom: 2.5rem !important; }

.pb-7 {
  padding-bottom: 3rem !important; }

.pb-8 {
  padding-bottom: 3.5rem !important; }

.pb-9 {
  padding-bottom: 4rem !important; }

.pb-10 {
  padding-bottom: 5rem !important; }

.pb-11 {
  padding-bottom: 6rem !important; }

.pb-12 {
  padding-bottom: 7rem !important; }

.ps-0 {
  padding-left: 0 !important; }

.ps-1 {
  padding-left: 0.25rem !important; }

.ps-2 {
  padding-left: 0.5rem !important; }

.ps-3 {
  padding-left: 1rem !important; }

.ps-4 {
  padding-left: 1.5rem !important; }

.ps-5 {
  padding-left: 2rem !important; }

.ps-6 {
  padding-left: 2.5rem !important; }

.ps-7 {
  padding-left: 3rem !important; }

.ps-8 {
  padding-left: 3.5rem !important; }

.ps-9 {
  padding-left: 4rem !important; }

.ps-10 {
  padding-left: 5rem !important; }

.ps-11 {
  padding-left: 6rem !important; }

.ps-12 {
  padding-left: 7rem !important; }

.gap-0 {
  gap: 0 !important; }

.gap-1 {
  gap: 0.25rem !important; }

.gap-2 {
  gap: 0.5rem !important; }

.gap-3 {
  gap: 1rem !important; }

.gap-4 {
  gap: 1.5rem !important; }

.gap-5 {
  gap: 2rem !important; }

.gap-6 {
  gap: 2.5rem !important; }

.gap-7 {
  gap: 3rem !important; }

.gap-8 {
  gap: 3.5rem !important; }

.gap-9 {
  gap: 4rem !important; }

.gap-10 {
  gap: 5rem !important; }

.gap-11 {
  gap: 6rem !important; }

.gap-12 {
  gap: 7rem !important; }

.row-gap-0 {
  row-gap: 0 !important; }

.row-gap-1 {
  row-gap: 0.25rem !important; }

.row-gap-2 {
  row-gap: 0.5rem !important; }

.row-gap-3 {
  row-gap: 1rem !important; }

.row-gap-4 {
  row-gap: 1.5rem !important; }

.row-gap-5 {
  row-gap: 2rem !important; }

.row-gap-6 {
  row-gap: 2.5rem !important; }

.row-gap-7 {
  row-gap: 3rem !important; }

.row-gap-8 {
  row-gap: 3.5rem !important; }

.row-gap-9 {
  row-gap: 4rem !important; }

.row-gap-10 {
  row-gap: 5rem !important; }

.row-gap-11 {
  row-gap: 6rem !important; }

.row-gap-12 {
  row-gap: 7rem !important; }

.column-gap-0 {
  column-gap: 0 !important; }

.column-gap-1 {
  column-gap: 0.25rem !important; }

.column-gap-2 {
  column-gap: 0.5rem !important; }

.column-gap-3 {
  column-gap: 1rem !important; }

.column-gap-4 {
  column-gap: 1.5rem !important; }

.column-gap-5 {
  column-gap: 2rem !important; }

.column-gap-6 {
  column-gap: 2.5rem !important; }

.column-gap-7 {
  column-gap: 3rem !important; }

.column-gap-8 {
  column-gap: 3.5rem !important; }

.column-gap-9 {
  column-gap: 4rem !important; }

.column-gap-10 {
  column-gap: 5rem !important; }

.column-gap-11 {
  column-gap: 6rem !important; }

.column-gap-12 {
  column-gap: 7rem !important; }

.font-monospace {
  font-family: var(--bs-font-monospace) !important; }

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important; }

.fs-2 {
  font-size: calc(1.305rem + 0.66vw) !important; }

.fs-3 {
  font-size: calc(1.2825rem + 0.39vw) !important; }

.fs-4 {
  font-size: calc(1.26rem + 0.12vw) !important; }

.fs-5 {
  font-size: 1.125rem !important; }

.fs-6 {
  font-size: 0.9rem !important; }

.fs-xl {
  font-size: 1.125rem !important; }

.fs-lg {
  font-size: 1.0125rem !important; }

.fs-base {
  font-size: 0.9rem !important; }

.fs-sm {
  font-size: 0.792rem !important; }

.fs-xs {
  font-size: 0.72rem !important; }

.fst-italic {
  font-style: italic !important; }

.fst-normal {
  font-style: normal !important; }

.fw-lighter {
  font-weight: lighter !important; }

.fw-light {
  font-weight: 300 !important; }

.fw-normal {
  font-weight: 400 !important; }

.fw-medium {
  font-weight: 500 !important; }

.fw-semibold {
  font-weight: 600 !important; }

.fw-bold {
  font-weight: 700 !important; }

.fw-bolder {
  font-weight: bolder !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.4 !important; }

.lh-base {
  line-height: 1.7 !important; }

.lh-lg {
  line-height: 1.8 !important; }

.text-start {
  text-align: left !important; }

.text-end {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important; }

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important; }

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important; }

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important; }

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important; }

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important; }

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important; }

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important; }

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important; }

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important; }

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important; }

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important; }

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important; }

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important; }

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important; }

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important; }

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important; }

.text-opacity-10 {
  --bs-text-opacity: 0.1; }

.text-opacity-20 {
  --bs-text-opacity: 0.2; }

.text-opacity-30 {
  --bs-text-opacity: 0.3; }

.text-opacity-40 {
  --bs-text-opacity: 0.4; }

.text-opacity-60 {
  --bs-text-opacity: 0.6; }

.text-opacity-70 {
  --bs-text-opacity: 0.7; }

.text-opacity-75 {
  --bs-text-opacity: 0.75; }

.text-opacity-80 {
  --bs-text-opacity: 0.8; }

.text-opacity-85 {
  --bs-text-opacity: 0.85; }

.text-opacity-90 {
  --bs-text-opacity: 0.9; }

.text-opacity-95 {
  --bs-text-opacity: 0.95; }

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important; }

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important; }

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important; }

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important; }

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important; }

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important; }

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important; }

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important; }

.link-opacity-10 {
  --bs-link-opacity: 0.1; }

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1; }

.link-opacity-25 {
  --bs-link-opacity: 0.25; }

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25; }

.link-opacity-50 {
  --bs-link-opacity: 0.5; }

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5; }

.link-opacity-75 {
  --bs-link-opacity: 0.75; }

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75; }

.link-opacity-100 {
  --bs-link-opacity: 1; }

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1; }

.link-offset-1 {
  text-underline-offset: 0.125em !important; }

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important; }

.link-offset-2 {
  text-underline-offset: 0.25em !important; }

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important; }

.link-offset-3 {
  text-underline-offset: 0.375em !important; }

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important; }

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-black {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-black-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important; }

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important; }

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0; }

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1; }

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25; }

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5; }

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75; }

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1; }

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1; }

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important; }

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important; }

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important; }

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important; }

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important; }

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important; }

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important; }

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important; }

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important; }

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important; }

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important; }

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important; }

.bg-opacity-10 {
  --bs-bg-opacity: 0.1; }

.bg-opacity-20 {
  --bs-bg-opacity: 0.2; }

.bg-opacity-30 {
  --bs-bg-opacity: 0.3; }

.bg-opacity-40 {
  --bs-bg-opacity: 0.4; }

.bg-opacity-50 {
  --bs-bg-opacity: 0.5; }

.bg-opacity-60 {
  --bs-bg-opacity: 0.6; }

.bg-opacity-70 {
  --bs-bg-opacity: 0.7; }

.bg-opacity-80 {
  --bs-bg-opacity: 0.8; }

.bg-opacity-90 {
  --bs-bg-opacity: 0.9; }

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important; }

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important; }

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important; }

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important; }

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important; }

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important; }

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important; }

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important; }

.bg-gradient {
  background-image: var(--bs-gradient) !important; }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.pe-none {
  pointer-events: none !important; }

.pe-auto {
  pointer-events: auto !important; }

.rounded {
  border-radius: var(--bs-border-radius) !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important; }

.rounded-2 {
  border-radius: var(--bs-border-radius) !important; }

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important; }

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important; }

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important; }

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important; }

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important; }

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important; }

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important; }

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important; }

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important; }

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important; }

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important; }

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important; }

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important; }

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important; }

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important; }

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important; }

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important; }

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important; }

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important; }

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important; }

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important; }

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important; }

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.z-n1 {
  z-index: -1 !important; }

.z-0 {
  z-index: 0 !important; }

.z-1 {
  z-index: 1 !important; }

.z-2 {
  z-index: 2 !important; }

.z-3 {
  z-index: 3 !important; }

.fw-100 {
  font-weight: 100 !important; }

.fw-200 {
  font-weight: 200 !important; }

.fw-300 {
  font-weight: 300 !important; }

.fw-400 {
  font-weight: 400 !important; }

.fw-500 {
  font-weight: 500 !important; }

.fw-600 {
  font-weight: 600 !important; }

.fw-700 {
  font-weight: 700 !important; }

.fw-800 {
  font-weight: 800 !important; }

.fw-900 {
  font-weight: 900 !important; }

.w-px-50 {
  width: 50px !important; }

.w-px-100 {
  width: 100px !important; }

.w-px-150 {
  width: 150px !important; }

.w-px-200 {
  width: 200px !important; }

.w-px-250 {
  width: 250px !important; }

.w-px-300 {
  width: 300px !important; }

.w-px-350 {
  width: 350px !important; }

.letter-spacing-1 {
  letter-spacing: 1px !important; }

.letter-spacing-2 {
  letter-spacing: 2px !important; }

.letter-spacing-3 {
  letter-spacing: 3px !important; }

.letter-spacing-4 {
  letter-spacing: 4px !important; }

.letter-spacing-5 {
  letter-spacing: 5px !important; }

.letter-spacing-6 {
  letter-spacing: 6px !important; }

.z-index-1 {
  z-index: 1 !important; }

.z-index-2 {
  z-index: 2 !important; }

.z-index-3 {
  z-index: 3 !important; }

.z-index-4 {
  z-index: 4 !important; }

.z-index-5 {
  z-index: 5 !important; }

.z-index-6 {
  z-index: 6 !important; }

.z-index-7 {
  z-index: 7 !important; }

.z-index-8 {
  z-index: 8 !important; }

.z-index-9 {
  z-index: 9 !important; }

.z-index--1 {
  z-index: -1 !important; }

.z-index--2 {
  z-index: -2 !important; }

.z-index--3 {
  z-index: -3 !important; }

.z-index--4 {
  z-index: -4 !important; }

.z-index--5 {
  z-index: -5 !important; }

.z-index--6 {
  z-index: -6 !important; }

.z-index--7 {
  z-index: -7 !important; }

.z-index--8 {
  z-index: -8 !important; }

.z-index--9 {
  z-index: -9 !important; }

.min-h-150px {
  min-height: 150px !important; }

.min-h-200px {
  min-height: 200px !important; }

.min-h-250px {
  min-height: 250px !important; }

.min-h-350px {
  min-height: 350px !important; }

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important; }
  .float-sm-end {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .object-fit-sm-contain {
    object-fit: contain !important; }
  .object-fit-sm-cover {
    object-fit: cover !important; }
  .object-fit-sm-fill {
    object-fit: fill !important; }
  .object-fit-sm-scale {
    object-fit: scale-down !important; }
  .object-fit-sm-none {
    object-fit: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-grid {
    display: grid !important; }
  .d-sm-inline-grid {
    display: inline-grid !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .d-sm-none {
    display: none !important; }
  .w-sm-5 {
    width: 5% !important; }
  .w-sm-10 {
    width: 10% !important; }
  .w-sm-15 {
    width: 15% !important; }
  .w-sm-25 {
    width: 25% !important; }
  .w-sm-30 {
    width: 30% !important; }
  .w-sm-35 {
    width: 35% !important; }
  .w-sm-40 {
    width: 40% !important; }
  .w-sm-45 {
    width: 45% !important; }
  .w-sm-50 {
    width: 50% !important; }
  .w-sm-55 {
    width: 55% !important; }
  .w-sm-60 {
    width: 60% !important; }
  .w-sm-65 {
    width: 65% !important; }
  .w-sm-75 {
    width: 75% !important; }
  .w-sm-80 {
    width: 80% !important; }
  .w-sm-85 {
    width: 85% !important; }
  .w-sm-90 {
    width: 90% !important; }
  .w-sm-95 {
    width: 95% !important; }
  .w-sm-100 {
    width: 100% !important; }
  .w-sm-auto {
    width: auto !important; }
  .min-vh-sm-50 {
    min-height: 50vh !important; }
  .min-vh-sm-65 {
    min-height: 65vh !important; }
  .min-vh-sm-75 {
    min-height: 75vh !important; }
  .min-vh-sm-85 {
    min-height: 85vh !important; }
  .min-vh-sm-100 {
    min-height: 100vh !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .m-sm-5 {
    margin: 2rem !important; }
  .m-sm-6 {
    margin: 2.5rem !important; }
  .m-sm-7 {
    margin: 3rem !important; }
  .m-sm-8 {
    margin: 3.5rem !important; }
  .m-sm-9 {
    margin: 4rem !important; }
  .m-sm-10 {
    margin: 5rem !important; }
  .m-sm-11 {
    margin: 6rem !important; }
  .m-sm-12 {
    margin: 7rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-sm-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-sm-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-sm-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-sm-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-sm-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-sm-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-sm-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-sm-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-sm-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-sm-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-sm-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-sm-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-sm-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-sm-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-sm-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 1.5rem !important; }
  .mt-sm-5 {
    margin-top: 2rem !important; }
  .mt-sm-6 {
    margin-top: 2.5rem !important; }
  .mt-sm-7 {
    margin-top: 3rem !important; }
  .mt-sm-8 {
    margin-top: 3.5rem !important; }
  .mt-sm-9 {
    margin-top: 4rem !important; }
  .mt-sm-10 {
    margin-top: 5rem !important; }
  .mt-sm-11 {
    margin-top: 6rem !important; }
  .mt-sm-12 {
    margin-top: 7rem !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .me-sm-0 {
    margin-right: 0 !important; }
  .me-sm-1 {
    margin-right: 0.25rem !important; }
  .me-sm-2 {
    margin-right: 0.5rem !important; }
  .me-sm-3 {
    margin-right: 1rem !important; }
  .me-sm-4 {
    margin-right: 1.5rem !important; }
  .me-sm-5 {
    margin-right: 2rem !important; }
  .me-sm-6 {
    margin-right: 2.5rem !important; }
  .me-sm-7 {
    margin-right: 3rem !important; }
  .me-sm-8 {
    margin-right: 3.5rem !important; }
  .me-sm-9 {
    margin-right: 4rem !important; }
  .me-sm-10 {
    margin-right: 5rem !important; }
  .me-sm-11 {
    margin-right: 6rem !important; }
  .me-sm-12 {
    margin-right: 7rem !important; }
  .me-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important; }
  .mb-sm-5 {
    margin-bottom: 2rem !important; }
  .mb-sm-6 {
    margin-bottom: 2.5rem !important; }
  .mb-sm-7 {
    margin-bottom: 3rem !important; }
  .mb-sm-8 {
    margin-bottom: 3.5rem !important; }
  .mb-sm-9 {
    margin-bottom: 4rem !important; }
  .mb-sm-10 {
    margin-bottom: 5rem !important; }
  .mb-sm-11 {
    margin-bottom: 6rem !important; }
  .mb-sm-12 {
    margin-bottom: 7rem !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ms-sm-0 {
    margin-left: 0 !important; }
  .ms-sm-1 {
    margin-left: 0.25rem !important; }
  .ms-sm-2 {
    margin-left: 0.5rem !important; }
  .ms-sm-3 {
    margin-left: 1rem !important; }
  .ms-sm-4 {
    margin-left: 1.5rem !important; }
  .ms-sm-5 {
    margin-left: 2rem !important; }
  .ms-sm-6 {
    margin-left: 2.5rem !important; }
  .ms-sm-7 {
    margin-left: 3rem !important; }
  .ms-sm-8 {
    margin-left: 3.5rem !important; }
  .ms-sm-9 {
    margin-left: 4rem !important; }
  .ms-sm-10 {
    margin-left: 5rem !important; }
  .ms-sm-11 {
    margin-left: 6rem !important; }
  .ms-sm-12 {
    margin-left: 7rem !important; }
  .ms-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .m-sm-n5 {
    margin: -2rem !important; }
  .m-sm-n6 {
    margin: -2.5rem !important; }
  .m-sm-n7 {
    margin: -3rem !important; }
  .m-sm-n8 {
    margin: -3.5rem !important; }
  .m-sm-n9 {
    margin: -4rem !important; }
  .m-sm-n10 {
    margin: -5rem !important; }
  .m-sm-n11 {
    margin: -6rem !important; }
  .m-sm-n12 {
    margin: -7rem !important; }
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-sm-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-sm-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-sm-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-sm-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-sm-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-sm-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-sm-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-sm-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important; }
  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-sm-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-sm-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-sm-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-sm-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-sm-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-sm-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-sm-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-sm-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important; }
  .mt-sm-n1 {
    margin-top: -0.25rem !important; }
  .mt-sm-n2 {
    margin-top: -0.5rem !important; }
  .mt-sm-n3 {
    margin-top: -1rem !important; }
  .mt-sm-n4 {
    margin-top: -1.5rem !important; }
  .mt-sm-n5 {
    margin-top: -2rem !important; }
  .mt-sm-n6 {
    margin-top: -2.5rem !important; }
  .mt-sm-n7 {
    margin-top: -3rem !important; }
  .mt-sm-n8 {
    margin-top: -3.5rem !important; }
  .mt-sm-n9 {
    margin-top: -4rem !important; }
  .mt-sm-n10 {
    margin-top: -5rem !important; }
  .mt-sm-n11 {
    margin-top: -6rem !important; }
  .mt-sm-n12 {
    margin-top: -7rem !important; }
  .me-sm-n1 {
    margin-right: -0.25rem !important; }
  .me-sm-n2 {
    margin-right: -0.5rem !important; }
  .me-sm-n3 {
    margin-right: -1rem !important; }
  .me-sm-n4 {
    margin-right: -1.5rem !important; }
  .me-sm-n5 {
    margin-right: -2rem !important; }
  .me-sm-n6 {
    margin-right: -2.5rem !important; }
  .me-sm-n7 {
    margin-right: -3rem !important; }
  .me-sm-n8 {
    margin-right: -3.5rem !important; }
  .me-sm-n9 {
    margin-right: -4rem !important; }
  .me-sm-n10 {
    margin-right: -5rem !important; }
  .me-sm-n11 {
    margin-right: -6rem !important; }
  .me-sm-n12 {
    margin-right: -7rem !important; }
  .mb-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-sm-n3 {
    margin-bottom: -1rem !important; }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-sm-n5 {
    margin-bottom: -2rem !important; }
  .mb-sm-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-sm-n7 {
    margin-bottom: -3rem !important; }
  .mb-sm-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-sm-n9 {
    margin-bottom: -4rem !important; }
  .mb-sm-n10 {
    margin-bottom: -5rem !important; }
  .mb-sm-n11 {
    margin-bottom: -6rem !important; }
  .mb-sm-n12 {
    margin-bottom: -7rem !important; }
  .ms-sm-n1 {
    margin-left: -0.25rem !important; }
  .ms-sm-n2 {
    margin-left: -0.5rem !important; }
  .ms-sm-n3 {
    margin-left: -1rem !important; }
  .ms-sm-n4 {
    margin-left: -1.5rem !important; }
  .ms-sm-n5 {
    margin-left: -2rem !important; }
  .ms-sm-n6 {
    margin-left: -2.5rem !important; }
  .ms-sm-n7 {
    margin-left: -3rem !important; }
  .ms-sm-n8 {
    margin-left: -3.5rem !important; }
  .ms-sm-n9 {
    margin-left: -4rem !important; }
  .ms-sm-n10 {
    margin-left: -5rem !important; }
  .ms-sm-n11 {
    margin-left: -6rem !important; }
  .ms-sm-n12 {
    margin-left: -7rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .p-sm-5 {
    padding: 2rem !important; }
  .p-sm-6 {
    padding: 2.5rem !important; }
  .p-sm-7 {
    padding: 3rem !important; }
  .p-sm-8 {
    padding: 3.5rem !important; }
  .p-sm-9 {
    padding: 4rem !important; }
  .p-sm-10 {
    padding: 5rem !important; }
  .p-sm-11 {
    padding: 6rem !important; }
  .p-sm-12 {
    padding: 7rem !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-sm-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-sm-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-sm-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-sm-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-sm-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-sm-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-sm-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-sm-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-sm-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-sm-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-sm-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-sm-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-sm-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-sm-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-sm-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 1.5rem !important; }
  .pt-sm-5 {
    padding-top: 2rem !important; }
  .pt-sm-6 {
    padding-top: 2.5rem !important; }
  .pt-sm-7 {
    padding-top: 3rem !important; }
  .pt-sm-8 {
    padding-top: 3.5rem !important; }
  .pt-sm-9 {
    padding-top: 4rem !important; }
  .pt-sm-10 {
    padding-top: 5rem !important; }
  .pt-sm-11 {
    padding-top: 6rem !important; }
  .pt-sm-12 {
    padding-top: 7rem !important; }
  .pe-sm-0 {
    padding-right: 0 !important; }
  .pe-sm-1 {
    padding-right: 0.25rem !important; }
  .pe-sm-2 {
    padding-right: 0.5rem !important; }
  .pe-sm-3 {
    padding-right: 1rem !important; }
  .pe-sm-4 {
    padding-right: 1.5rem !important; }
  .pe-sm-5 {
    padding-right: 2rem !important; }
  .pe-sm-6 {
    padding-right: 2.5rem !important; }
  .pe-sm-7 {
    padding-right: 3rem !important; }
  .pe-sm-8 {
    padding-right: 3.5rem !important; }
  .pe-sm-9 {
    padding-right: 4rem !important; }
  .pe-sm-10 {
    padding-right: 5rem !important; }
  .pe-sm-11 {
    padding-right: 6rem !important; }
  .pe-sm-12 {
    padding-right: 7rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pb-sm-5 {
    padding-bottom: 2rem !important; }
  .pb-sm-6 {
    padding-bottom: 2.5rem !important; }
  .pb-sm-7 {
    padding-bottom: 3rem !important; }
  .pb-sm-8 {
    padding-bottom: 3.5rem !important; }
  .pb-sm-9 {
    padding-bottom: 4rem !important; }
  .pb-sm-10 {
    padding-bottom: 5rem !important; }
  .pb-sm-11 {
    padding-bottom: 6rem !important; }
  .pb-sm-12 {
    padding-bottom: 7rem !important; }
  .ps-sm-0 {
    padding-left: 0 !important; }
  .ps-sm-1 {
    padding-left: 0.25rem !important; }
  .ps-sm-2 {
    padding-left: 0.5rem !important; }
  .ps-sm-3 {
    padding-left: 1rem !important; }
  .ps-sm-4 {
    padding-left: 1.5rem !important; }
  .ps-sm-5 {
    padding-left: 2rem !important; }
  .ps-sm-6 {
    padding-left: 2.5rem !important; }
  .ps-sm-7 {
    padding-left: 3rem !important; }
  .ps-sm-8 {
    padding-left: 3.5rem !important; }
  .ps-sm-9 {
    padding-left: 4rem !important; }
  .ps-sm-10 {
    padding-left: 5rem !important; }
  .ps-sm-11 {
    padding-left: 6rem !important; }
  .ps-sm-12 {
    padding-left: 7rem !important; }
  .gap-sm-0 {
    gap: 0 !important; }
  .gap-sm-1 {
    gap: 0.25rem !important; }
  .gap-sm-2 {
    gap: 0.5rem !important; }
  .gap-sm-3 {
    gap: 1rem !important; }
  .gap-sm-4 {
    gap: 1.5rem !important; }
  .gap-sm-5 {
    gap: 2rem !important; }
  .gap-sm-6 {
    gap: 2.5rem !important; }
  .gap-sm-7 {
    gap: 3rem !important; }
  .gap-sm-8 {
    gap: 3.5rem !important; }
  .gap-sm-9 {
    gap: 4rem !important; }
  .gap-sm-10 {
    gap: 5rem !important; }
  .gap-sm-11 {
    gap: 6rem !important; }
  .gap-sm-12 {
    gap: 7rem !important; }
  .row-gap-sm-0 {
    row-gap: 0 !important; }
  .row-gap-sm-1 {
    row-gap: 0.25rem !important; }
  .row-gap-sm-2 {
    row-gap: 0.5rem !important; }
  .row-gap-sm-3 {
    row-gap: 1rem !important; }
  .row-gap-sm-4 {
    row-gap: 1.5rem !important; }
  .row-gap-sm-5 {
    row-gap: 2rem !important; }
  .row-gap-sm-6 {
    row-gap: 2.5rem !important; }
  .row-gap-sm-7 {
    row-gap: 3rem !important; }
  .row-gap-sm-8 {
    row-gap: 3.5rem !important; }
  .row-gap-sm-9 {
    row-gap: 4rem !important; }
  .row-gap-sm-10 {
    row-gap: 5rem !important; }
  .row-gap-sm-11 {
    row-gap: 6rem !important; }
  .row-gap-sm-12 {
    row-gap: 7rem !important; }
  .column-gap-sm-0 {
    column-gap: 0 !important; }
  .column-gap-sm-1 {
    column-gap: 0.25rem !important; }
  .column-gap-sm-2 {
    column-gap: 0.5rem !important; }
  .column-gap-sm-3 {
    column-gap: 1rem !important; }
  .column-gap-sm-4 {
    column-gap: 1.5rem !important; }
  .column-gap-sm-5 {
    column-gap: 2rem !important; }
  .column-gap-sm-6 {
    column-gap: 2.5rem !important; }
  .column-gap-sm-7 {
    column-gap: 3rem !important; }
  .column-gap-sm-8 {
    column-gap: 3.5rem !important; }
  .column-gap-sm-9 {
    column-gap: 4rem !important; }
  .column-gap-sm-10 {
    column-gap: 5rem !important; }
  .column-gap-sm-11 {
    column-gap: 6rem !important; }
  .column-gap-sm-12 {
    column-gap: 7rem !important; }
  .text-sm-start {
    text-align: left !important; }
  .text-sm-end {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; }
  .w-px-sm-50 {
    width: 50px !important; }
  .w-px-sm-100 {
    width: 100px !important; }
  .w-px-sm-150 {
    width: 150px !important; }
  .w-px-sm-200 {
    width: 200px !important; }
  .w-px-sm-250 {
    width: 250px !important; }
  .w-px-sm-300 {
    width: 300px !important; }
  .w-px-sm-350 {
    width: 350px !important; }
  .min-h-sm-150px {
    min-height: 150px !important; }
  .min-h-sm-200px {
    min-height: 200px !important; }
  .min-h-sm-250px {
    min-height: 250px !important; }
  .min-h-sm-350px {
    min-height: 350px !important; } }

@media (min-width: 768px) {
  .float-md-start {
    float: left !important; }
  .float-md-end {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .object-fit-md-contain {
    object-fit: contain !important; }
  .object-fit-md-cover {
    object-fit: cover !important; }
  .object-fit-md-fill {
    object-fit: fill !important; }
  .object-fit-md-scale {
    object-fit: scale-down !important; }
  .object-fit-md-none {
    object-fit: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-grid {
    display: grid !important; }
  .d-md-inline-grid {
    display: inline-grid !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .d-md-none {
    display: none !important; }
  .w-md-5 {
    width: 5% !important; }
  .w-md-10 {
    width: 10% !important; }
  .w-md-15 {
    width: 15% !important; }
  .w-md-25 {
    width: 25% !important; }
  .w-md-30 {
    width: 30% !important; }
  .w-md-35 {
    width: 35% !important; }
  .w-md-40 {
    width: 40% !important; }
  .w-md-45 {
    width: 45% !important; }
  .w-md-50 {
    width: 50% !important; }
  .w-md-55 {
    width: 55% !important; }
  .w-md-60 {
    width: 60% !important; }
  .w-md-65 {
    width: 65% !important; }
  .w-md-75 {
    width: 75% !important; }
  .w-md-80 {
    width: 80% !important; }
  .w-md-85 {
    width: 85% !important; }
  .w-md-90 {
    width: 90% !important; }
  .w-md-95 {
    width: 95% !important; }
  .w-md-100 {
    width: 100% !important; }
  .w-md-auto {
    width: auto !important; }
  .min-vh-md-50 {
    min-height: 50vh !important; }
  .min-vh-md-65 {
    min-height: 65vh !important; }
  .min-vh-md-75 {
    min-height: 75vh !important; }
  .min-vh-md-85 {
    min-height: 85vh !important; }
  .min-vh-md-100 {
    min-height: 100vh !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .justify-content-md-evenly {
    justify-content: space-evenly !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .m-md-5 {
    margin: 2rem !important; }
  .m-md-6 {
    margin: 2.5rem !important; }
  .m-md-7 {
    margin: 3rem !important; }
  .m-md-8 {
    margin: 3.5rem !important; }
  .m-md-9 {
    margin: 4rem !important; }
  .m-md-10 {
    margin: 5rem !important; }
  .m-md-11 {
    margin: 6rem !important; }
  .m-md-12 {
    margin: 7rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-md-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-md-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-md-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-md-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-md-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-md-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-md-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-md-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-md-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-md-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-md-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-md-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-md-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-md-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-md-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 1.5rem !important; }
  .mt-md-5 {
    margin-top: 2rem !important; }
  .mt-md-6 {
    margin-top: 2.5rem !important; }
  .mt-md-7 {
    margin-top: 3rem !important; }
  .mt-md-8 {
    margin-top: 3.5rem !important; }
  .mt-md-9 {
    margin-top: 4rem !important; }
  .mt-md-10 {
    margin-top: 5rem !important; }
  .mt-md-11 {
    margin-top: 6rem !important; }
  .mt-md-12 {
    margin-top: 7rem !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .me-md-0 {
    margin-right: 0 !important; }
  .me-md-1 {
    margin-right: 0.25rem !important; }
  .me-md-2 {
    margin-right: 0.5rem !important; }
  .me-md-3 {
    margin-right: 1rem !important; }
  .me-md-4 {
    margin-right: 1.5rem !important; }
  .me-md-5 {
    margin-right: 2rem !important; }
  .me-md-6 {
    margin-right: 2.5rem !important; }
  .me-md-7 {
    margin-right: 3rem !important; }
  .me-md-8 {
    margin-right: 3.5rem !important; }
  .me-md-9 {
    margin-right: 4rem !important; }
  .me-md-10 {
    margin-right: 5rem !important; }
  .me-md-11 {
    margin-right: 6rem !important; }
  .me-md-12 {
    margin-right: 7rem !important; }
  .me-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 1.5rem !important; }
  .mb-md-5 {
    margin-bottom: 2rem !important; }
  .mb-md-6 {
    margin-bottom: 2.5rem !important; }
  .mb-md-7 {
    margin-bottom: 3rem !important; }
  .mb-md-8 {
    margin-bottom: 3.5rem !important; }
  .mb-md-9 {
    margin-bottom: 4rem !important; }
  .mb-md-10 {
    margin-bottom: 5rem !important; }
  .mb-md-11 {
    margin-bottom: 6rem !important; }
  .mb-md-12 {
    margin-bottom: 7rem !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ms-md-0 {
    margin-left: 0 !important; }
  .ms-md-1 {
    margin-left: 0.25rem !important; }
  .ms-md-2 {
    margin-left: 0.5rem !important; }
  .ms-md-3 {
    margin-left: 1rem !important; }
  .ms-md-4 {
    margin-left: 1.5rem !important; }
  .ms-md-5 {
    margin-left: 2rem !important; }
  .ms-md-6 {
    margin-left: 2.5rem !important; }
  .ms-md-7 {
    margin-left: 3rem !important; }
  .ms-md-8 {
    margin-left: 3.5rem !important; }
  .ms-md-9 {
    margin-left: 4rem !important; }
  .ms-md-10 {
    margin-left: 5rem !important; }
  .ms-md-11 {
    margin-left: 6rem !important; }
  .ms-md-12 {
    margin-left: 7rem !important; }
  .ms-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .m-md-n5 {
    margin: -2rem !important; }
  .m-md-n6 {
    margin: -2.5rem !important; }
  .m-md-n7 {
    margin: -3rem !important; }
  .m-md-n8 {
    margin: -3.5rem !important; }
  .m-md-n9 {
    margin: -4rem !important; }
  .m-md-n10 {
    margin: -5rem !important; }
  .m-md-n11 {
    margin: -6rem !important; }
  .m-md-n12 {
    margin: -7rem !important; }
  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-md-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-md-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-md-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-md-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-md-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-md-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-md-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-md-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important; }
  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-md-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-md-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-md-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-md-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-md-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-md-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-md-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-md-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important; }
  .mt-md-n1 {
    margin-top: -0.25rem !important; }
  .mt-md-n2 {
    margin-top: -0.5rem !important; }
  .mt-md-n3 {
    margin-top: -1rem !important; }
  .mt-md-n4 {
    margin-top: -1.5rem !important; }
  .mt-md-n5 {
    margin-top: -2rem !important; }
  .mt-md-n6 {
    margin-top: -2.5rem !important; }
  .mt-md-n7 {
    margin-top: -3rem !important; }
  .mt-md-n8 {
    margin-top: -3.5rem !important; }
  .mt-md-n9 {
    margin-top: -4rem !important; }
  .mt-md-n10 {
    margin-top: -5rem !important; }
  .mt-md-n11 {
    margin-top: -6rem !important; }
  .mt-md-n12 {
    margin-top: -7rem !important; }
  .me-md-n1 {
    margin-right: -0.25rem !important; }
  .me-md-n2 {
    margin-right: -0.5rem !important; }
  .me-md-n3 {
    margin-right: -1rem !important; }
  .me-md-n4 {
    margin-right: -1.5rem !important; }
  .me-md-n5 {
    margin-right: -2rem !important; }
  .me-md-n6 {
    margin-right: -2.5rem !important; }
  .me-md-n7 {
    margin-right: -3rem !important; }
  .me-md-n8 {
    margin-right: -3.5rem !important; }
  .me-md-n9 {
    margin-right: -4rem !important; }
  .me-md-n10 {
    margin-right: -5rem !important; }
  .me-md-n11 {
    margin-right: -6rem !important; }
  .me-md-n12 {
    margin-right: -7rem !important; }
  .mb-md-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-md-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-md-n3 {
    margin-bottom: -1rem !important; }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-md-n5 {
    margin-bottom: -2rem !important; }
  .mb-md-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-md-n7 {
    margin-bottom: -3rem !important; }
  .mb-md-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-md-n9 {
    margin-bottom: -4rem !important; }
  .mb-md-n10 {
    margin-bottom: -5rem !important; }
  .mb-md-n11 {
    margin-bottom: -6rem !important; }
  .mb-md-n12 {
    margin-bottom: -7rem !important; }
  .ms-md-n1 {
    margin-left: -0.25rem !important; }
  .ms-md-n2 {
    margin-left: -0.5rem !important; }
  .ms-md-n3 {
    margin-left: -1rem !important; }
  .ms-md-n4 {
    margin-left: -1.5rem !important; }
  .ms-md-n5 {
    margin-left: -2rem !important; }
  .ms-md-n6 {
    margin-left: -2.5rem !important; }
  .ms-md-n7 {
    margin-left: -3rem !important; }
  .ms-md-n8 {
    margin-left: -3.5rem !important; }
  .ms-md-n9 {
    margin-left: -4rem !important; }
  .ms-md-n10 {
    margin-left: -5rem !important; }
  .ms-md-n11 {
    margin-left: -6rem !important; }
  .ms-md-n12 {
    margin-left: -7rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .p-md-5 {
    padding: 2rem !important; }
  .p-md-6 {
    padding: 2.5rem !important; }
  .p-md-7 {
    padding: 3rem !important; }
  .p-md-8 {
    padding: 3.5rem !important; }
  .p-md-9 {
    padding: 4rem !important; }
  .p-md-10 {
    padding: 5rem !important; }
  .p-md-11 {
    padding: 6rem !important; }
  .p-md-12 {
    padding: 7rem !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-md-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-md-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-md-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-md-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-md-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-md-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-md-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-md-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-md-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-md-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-md-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-md-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-md-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-md-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-md-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 1.5rem !important; }
  .pt-md-5 {
    padding-top: 2rem !important; }
  .pt-md-6 {
    padding-top: 2.5rem !important; }
  .pt-md-7 {
    padding-top: 3rem !important; }
  .pt-md-8 {
    padding-top: 3.5rem !important; }
  .pt-md-9 {
    padding-top: 4rem !important; }
  .pt-md-10 {
    padding-top: 5rem !important; }
  .pt-md-11 {
    padding-top: 6rem !important; }
  .pt-md-12 {
    padding-top: 7rem !important; }
  .pe-md-0 {
    padding-right: 0 !important; }
  .pe-md-1 {
    padding-right: 0.25rem !important; }
  .pe-md-2 {
    padding-right: 0.5rem !important; }
  .pe-md-3 {
    padding-right: 1rem !important; }
  .pe-md-4 {
    padding-right: 1.5rem !important; }
  .pe-md-5 {
    padding-right: 2rem !important; }
  .pe-md-6 {
    padding-right: 2.5rem !important; }
  .pe-md-7 {
    padding-right: 3rem !important; }
  .pe-md-8 {
    padding-right: 3.5rem !important; }
  .pe-md-9 {
    padding-right: 4rem !important; }
  .pe-md-10 {
    padding-right: 5rem !important; }
  .pe-md-11 {
    padding-right: 6rem !important; }
  .pe-md-12 {
    padding-right: 7rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 1.5rem !important; }
  .pb-md-5 {
    padding-bottom: 2rem !important; }
  .pb-md-6 {
    padding-bottom: 2.5rem !important; }
  .pb-md-7 {
    padding-bottom: 3rem !important; }
  .pb-md-8 {
    padding-bottom: 3.5rem !important; }
  .pb-md-9 {
    padding-bottom: 4rem !important; }
  .pb-md-10 {
    padding-bottom: 5rem !important; }
  .pb-md-11 {
    padding-bottom: 6rem !important; }
  .pb-md-12 {
    padding-bottom: 7rem !important; }
  .ps-md-0 {
    padding-left: 0 !important; }
  .ps-md-1 {
    padding-left: 0.25rem !important; }
  .ps-md-2 {
    padding-left: 0.5rem !important; }
  .ps-md-3 {
    padding-left: 1rem !important; }
  .ps-md-4 {
    padding-left: 1.5rem !important; }
  .ps-md-5 {
    padding-left: 2rem !important; }
  .ps-md-6 {
    padding-left: 2.5rem !important; }
  .ps-md-7 {
    padding-left: 3rem !important; }
  .ps-md-8 {
    padding-left: 3.5rem !important; }
  .ps-md-9 {
    padding-left: 4rem !important; }
  .ps-md-10 {
    padding-left: 5rem !important; }
  .ps-md-11 {
    padding-left: 6rem !important; }
  .ps-md-12 {
    padding-left: 7rem !important; }
  .gap-md-0 {
    gap: 0 !important; }
  .gap-md-1 {
    gap: 0.25rem !important; }
  .gap-md-2 {
    gap: 0.5rem !important; }
  .gap-md-3 {
    gap: 1rem !important; }
  .gap-md-4 {
    gap: 1.5rem !important; }
  .gap-md-5 {
    gap: 2rem !important; }
  .gap-md-6 {
    gap: 2.5rem !important; }
  .gap-md-7 {
    gap: 3rem !important; }
  .gap-md-8 {
    gap: 3.5rem !important; }
  .gap-md-9 {
    gap: 4rem !important; }
  .gap-md-10 {
    gap: 5rem !important; }
  .gap-md-11 {
    gap: 6rem !important; }
  .gap-md-12 {
    gap: 7rem !important; }
  .row-gap-md-0 {
    row-gap: 0 !important; }
  .row-gap-md-1 {
    row-gap: 0.25rem !important; }
  .row-gap-md-2 {
    row-gap: 0.5rem !important; }
  .row-gap-md-3 {
    row-gap: 1rem !important; }
  .row-gap-md-4 {
    row-gap: 1.5rem !important; }
  .row-gap-md-5 {
    row-gap: 2rem !important; }
  .row-gap-md-6 {
    row-gap: 2.5rem !important; }
  .row-gap-md-7 {
    row-gap: 3rem !important; }
  .row-gap-md-8 {
    row-gap: 3.5rem !important; }
  .row-gap-md-9 {
    row-gap: 4rem !important; }
  .row-gap-md-10 {
    row-gap: 5rem !important; }
  .row-gap-md-11 {
    row-gap: 6rem !important; }
  .row-gap-md-12 {
    row-gap: 7rem !important; }
  .column-gap-md-0 {
    column-gap: 0 !important; }
  .column-gap-md-1 {
    column-gap: 0.25rem !important; }
  .column-gap-md-2 {
    column-gap: 0.5rem !important; }
  .column-gap-md-3 {
    column-gap: 1rem !important; }
  .column-gap-md-4 {
    column-gap: 1.5rem !important; }
  .column-gap-md-5 {
    column-gap: 2rem !important; }
  .column-gap-md-6 {
    column-gap: 2.5rem !important; }
  .column-gap-md-7 {
    column-gap: 3rem !important; }
  .column-gap-md-8 {
    column-gap: 3.5rem !important; }
  .column-gap-md-9 {
    column-gap: 4rem !important; }
  .column-gap-md-10 {
    column-gap: 5rem !important; }
  .column-gap-md-11 {
    column-gap: 6rem !important; }
  .column-gap-md-12 {
    column-gap: 7rem !important; }
  .text-md-start {
    text-align: left !important; }
  .text-md-end {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; }
  .w-px-md-50 {
    width: 50px !important; }
  .w-px-md-100 {
    width: 100px !important; }
  .w-px-md-150 {
    width: 150px !important; }
  .w-px-md-200 {
    width: 200px !important; }
  .w-px-md-250 {
    width: 250px !important; }
  .w-px-md-300 {
    width: 300px !important; }
  .w-px-md-350 {
    width: 350px !important; }
  .min-h-md-150px {
    min-height: 150px !important; }
  .min-h-md-200px {
    min-height: 200px !important; }
  .min-h-md-250px {
    min-height: 250px !important; }
  .min-h-md-350px {
    min-height: 350px !important; } }

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important; }
  .float-lg-end {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .object-fit-lg-contain {
    object-fit: contain !important; }
  .object-fit-lg-cover {
    object-fit: cover !important; }
  .object-fit-lg-fill {
    object-fit: fill !important; }
  .object-fit-lg-scale {
    object-fit: scale-down !important; }
  .object-fit-lg-none {
    object-fit: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-grid {
    display: grid !important; }
  .d-lg-inline-grid {
    display: inline-grid !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .d-lg-none {
    display: none !important; }
  .w-lg-5 {
    width: 5% !important; }
  .w-lg-10 {
    width: 10% !important; }
  .w-lg-15 {
    width: 15% !important; }
  .w-lg-25 {
    width: 25% !important; }
  .w-lg-30 {
    width: 30% !important; }
  .w-lg-35 {
    width: 35% !important; }
  .w-lg-40 {
    width: 40% !important; }
  .w-lg-45 {
    width: 45% !important; }
  .w-lg-50 {
    width: 50% !important; }
  .w-lg-55 {
    width: 55% !important; }
  .w-lg-60 {
    width: 60% !important; }
  .w-lg-65 {
    width: 65% !important; }
  .w-lg-75 {
    width: 75% !important; }
  .w-lg-80 {
    width: 80% !important; }
  .w-lg-85 {
    width: 85% !important; }
  .w-lg-90 {
    width: 90% !important; }
  .w-lg-95 {
    width: 95% !important; }
  .w-lg-100 {
    width: 100% !important; }
  .w-lg-auto {
    width: auto !important; }
  .min-vh-lg-50 {
    min-height: 50vh !important; }
  .min-vh-lg-65 {
    min-height: 65vh !important; }
  .min-vh-lg-75 {
    min-height: 75vh !important; }
  .min-vh-lg-85 {
    min-height: 85vh !important; }
  .min-vh-lg-100 {
    min-height: 100vh !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .m-lg-5 {
    margin: 2rem !important; }
  .m-lg-6 {
    margin: 2.5rem !important; }
  .m-lg-7 {
    margin: 3rem !important; }
  .m-lg-8 {
    margin: 3.5rem !important; }
  .m-lg-9 {
    margin: 4rem !important; }
  .m-lg-10 {
    margin: 5rem !important; }
  .m-lg-11 {
    margin: 6rem !important; }
  .m-lg-12 {
    margin: 7rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-lg-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-lg-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-lg-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-lg-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-lg-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-lg-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-lg-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-lg-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-lg-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-lg-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-lg-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-lg-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-lg-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-lg-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-lg-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 1.5rem !important; }
  .mt-lg-5 {
    margin-top: 2rem !important; }
  .mt-lg-6 {
    margin-top: 2.5rem !important; }
  .mt-lg-7 {
    margin-top: 3rem !important; }
  .mt-lg-8 {
    margin-top: 3.5rem !important; }
  .mt-lg-9 {
    margin-top: 4rem !important; }
  .mt-lg-10 {
    margin-top: 5rem !important; }
  .mt-lg-11 {
    margin-top: 6rem !important; }
  .mt-lg-12 {
    margin-top: 7rem !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .me-lg-0 {
    margin-right: 0 !important; }
  .me-lg-1 {
    margin-right: 0.25rem !important; }
  .me-lg-2 {
    margin-right: 0.5rem !important; }
  .me-lg-3 {
    margin-right: 1rem !important; }
  .me-lg-4 {
    margin-right: 1.5rem !important; }
  .me-lg-5 {
    margin-right: 2rem !important; }
  .me-lg-6 {
    margin-right: 2.5rem !important; }
  .me-lg-7 {
    margin-right: 3rem !important; }
  .me-lg-8 {
    margin-right: 3.5rem !important; }
  .me-lg-9 {
    margin-right: 4rem !important; }
  .me-lg-10 {
    margin-right: 5rem !important; }
  .me-lg-11 {
    margin-right: 6rem !important; }
  .me-lg-12 {
    margin-right: 7rem !important; }
  .me-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important; }
  .mb-lg-5 {
    margin-bottom: 2rem !important; }
  .mb-lg-6 {
    margin-bottom: 2.5rem !important; }
  .mb-lg-7 {
    margin-bottom: 3rem !important; }
  .mb-lg-8 {
    margin-bottom: 3.5rem !important; }
  .mb-lg-9 {
    margin-bottom: 4rem !important; }
  .mb-lg-10 {
    margin-bottom: 5rem !important; }
  .mb-lg-11 {
    margin-bottom: 6rem !important; }
  .mb-lg-12 {
    margin-bottom: 7rem !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ms-lg-0 {
    margin-left: 0 !important; }
  .ms-lg-1 {
    margin-left: 0.25rem !important; }
  .ms-lg-2 {
    margin-left: 0.5rem !important; }
  .ms-lg-3 {
    margin-left: 1rem !important; }
  .ms-lg-4 {
    margin-left: 1.5rem !important; }
  .ms-lg-5 {
    margin-left: 2rem !important; }
  .ms-lg-6 {
    margin-left: 2.5rem !important; }
  .ms-lg-7 {
    margin-left: 3rem !important; }
  .ms-lg-8 {
    margin-left: 3.5rem !important; }
  .ms-lg-9 {
    margin-left: 4rem !important; }
  .ms-lg-10 {
    margin-left: 5rem !important; }
  .ms-lg-11 {
    margin-left: 6rem !important; }
  .ms-lg-12 {
    margin-left: 7rem !important; }
  .ms-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .m-lg-n5 {
    margin: -2rem !important; }
  .m-lg-n6 {
    margin: -2.5rem !important; }
  .m-lg-n7 {
    margin: -3rem !important; }
  .m-lg-n8 {
    margin: -3.5rem !important; }
  .m-lg-n9 {
    margin: -4rem !important; }
  .m-lg-n10 {
    margin: -5rem !important; }
  .m-lg-n11 {
    margin: -6rem !important; }
  .m-lg-n12 {
    margin: -7rem !important; }
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-lg-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-lg-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-lg-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-lg-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-lg-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-lg-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-lg-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-lg-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important; }
  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-lg-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-lg-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-lg-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-lg-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-lg-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-lg-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-lg-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-lg-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important; }
  .mt-lg-n1 {
    margin-top: -0.25rem !important; }
  .mt-lg-n2 {
    margin-top: -0.5rem !important; }
  .mt-lg-n3 {
    margin-top: -1rem !important; }
  .mt-lg-n4 {
    margin-top: -1.5rem !important; }
  .mt-lg-n5 {
    margin-top: -2rem !important; }
  .mt-lg-n6 {
    margin-top: -2.5rem !important; }
  .mt-lg-n7 {
    margin-top: -3rem !important; }
  .mt-lg-n8 {
    margin-top: -3.5rem !important; }
  .mt-lg-n9 {
    margin-top: -4rem !important; }
  .mt-lg-n10 {
    margin-top: -5rem !important; }
  .mt-lg-n11 {
    margin-top: -6rem !important; }
  .mt-lg-n12 {
    margin-top: -7rem !important; }
  .me-lg-n1 {
    margin-right: -0.25rem !important; }
  .me-lg-n2 {
    margin-right: -0.5rem !important; }
  .me-lg-n3 {
    margin-right: -1rem !important; }
  .me-lg-n4 {
    margin-right: -1.5rem !important; }
  .me-lg-n5 {
    margin-right: -2rem !important; }
  .me-lg-n6 {
    margin-right: -2.5rem !important; }
  .me-lg-n7 {
    margin-right: -3rem !important; }
  .me-lg-n8 {
    margin-right: -3.5rem !important; }
  .me-lg-n9 {
    margin-right: -4rem !important; }
  .me-lg-n10 {
    margin-right: -5rem !important; }
  .me-lg-n11 {
    margin-right: -6rem !important; }
  .me-lg-n12 {
    margin-right: -7rem !important; }
  .mb-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-lg-n3 {
    margin-bottom: -1rem !important; }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-lg-n5 {
    margin-bottom: -2rem !important; }
  .mb-lg-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-lg-n7 {
    margin-bottom: -3rem !important; }
  .mb-lg-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-lg-n9 {
    margin-bottom: -4rem !important; }
  .mb-lg-n10 {
    margin-bottom: -5rem !important; }
  .mb-lg-n11 {
    margin-bottom: -6rem !important; }
  .mb-lg-n12 {
    margin-bottom: -7rem !important; }
  .ms-lg-n1 {
    margin-left: -0.25rem !important; }
  .ms-lg-n2 {
    margin-left: -0.5rem !important; }
  .ms-lg-n3 {
    margin-left: -1rem !important; }
  .ms-lg-n4 {
    margin-left: -1.5rem !important; }
  .ms-lg-n5 {
    margin-left: -2rem !important; }
  .ms-lg-n6 {
    margin-left: -2.5rem !important; }
  .ms-lg-n7 {
    margin-left: -3rem !important; }
  .ms-lg-n8 {
    margin-left: -3.5rem !important; }
  .ms-lg-n9 {
    margin-left: -4rem !important; }
  .ms-lg-n10 {
    margin-left: -5rem !important; }
  .ms-lg-n11 {
    margin-left: -6rem !important; }
  .ms-lg-n12 {
    margin-left: -7rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .p-lg-5 {
    padding: 2rem !important; }
  .p-lg-6 {
    padding: 2.5rem !important; }
  .p-lg-7 {
    padding: 3rem !important; }
  .p-lg-8 {
    padding: 3.5rem !important; }
  .p-lg-9 {
    padding: 4rem !important; }
  .p-lg-10 {
    padding: 5rem !important; }
  .p-lg-11 {
    padding: 6rem !important; }
  .p-lg-12 {
    padding: 7rem !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-lg-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-lg-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-lg-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-lg-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-lg-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-lg-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-lg-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-lg-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-lg-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-lg-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-lg-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-lg-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-lg-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-lg-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-lg-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 1.5rem !important; }
  .pt-lg-5 {
    padding-top: 2rem !important; }
  .pt-lg-6 {
    padding-top: 2.5rem !important; }
  .pt-lg-7 {
    padding-top: 3rem !important; }
  .pt-lg-8 {
    padding-top: 3.5rem !important; }
  .pt-lg-9 {
    padding-top: 4rem !important; }
  .pt-lg-10 {
    padding-top: 5rem !important; }
  .pt-lg-11 {
    padding-top: 6rem !important; }
  .pt-lg-12 {
    padding-top: 7rem !important; }
  .pe-lg-0 {
    padding-right: 0 !important; }
  .pe-lg-1 {
    padding-right: 0.25rem !important; }
  .pe-lg-2 {
    padding-right: 0.5rem !important; }
  .pe-lg-3 {
    padding-right: 1rem !important; }
  .pe-lg-4 {
    padding-right: 1.5rem !important; }
  .pe-lg-5 {
    padding-right: 2rem !important; }
  .pe-lg-6 {
    padding-right: 2.5rem !important; }
  .pe-lg-7 {
    padding-right: 3rem !important; }
  .pe-lg-8 {
    padding-right: 3.5rem !important; }
  .pe-lg-9 {
    padding-right: 4rem !important; }
  .pe-lg-10 {
    padding-right: 5rem !important; }
  .pe-lg-11 {
    padding-right: 6rem !important; }
  .pe-lg-12 {
    padding-right: 7rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pb-lg-5 {
    padding-bottom: 2rem !important; }
  .pb-lg-6 {
    padding-bottom: 2.5rem !important; }
  .pb-lg-7 {
    padding-bottom: 3rem !important; }
  .pb-lg-8 {
    padding-bottom: 3.5rem !important; }
  .pb-lg-9 {
    padding-bottom: 4rem !important; }
  .pb-lg-10 {
    padding-bottom: 5rem !important; }
  .pb-lg-11 {
    padding-bottom: 6rem !important; }
  .pb-lg-12 {
    padding-bottom: 7rem !important; }
  .ps-lg-0 {
    padding-left: 0 !important; }
  .ps-lg-1 {
    padding-left: 0.25rem !important; }
  .ps-lg-2 {
    padding-left: 0.5rem !important; }
  .ps-lg-3 {
    padding-left: 1rem !important; }
  .ps-lg-4 {
    padding-left: 1.5rem !important; }
  .ps-lg-5 {
    padding-left: 2rem !important; }
  .ps-lg-6 {
    padding-left: 2.5rem !important; }
  .ps-lg-7 {
    padding-left: 3rem !important; }
  .ps-lg-8 {
    padding-left: 3.5rem !important; }
  .ps-lg-9 {
    padding-left: 4rem !important; }
  .ps-lg-10 {
    padding-left: 5rem !important; }
  .ps-lg-11 {
    padding-left: 6rem !important; }
  .ps-lg-12 {
    padding-left: 7rem !important; }
  .gap-lg-0 {
    gap: 0 !important; }
  .gap-lg-1 {
    gap: 0.25rem !important; }
  .gap-lg-2 {
    gap: 0.5rem !important; }
  .gap-lg-3 {
    gap: 1rem !important; }
  .gap-lg-4 {
    gap: 1.5rem !important; }
  .gap-lg-5 {
    gap: 2rem !important; }
  .gap-lg-6 {
    gap: 2.5rem !important; }
  .gap-lg-7 {
    gap: 3rem !important; }
  .gap-lg-8 {
    gap: 3.5rem !important; }
  .gap-lg-9 {
    gap: 4rem !important; }
  .gap-lg-10 {
    gap: 5rem !important; }
  .gap-lg-11 {
    gap: 6rem !important; }
  .gap-lg-12 {
    gap: 7rem !important; }
  .row-gap-lg-0 {
    row-gap: 0 !important; }
  .row-gap-lg-1 {
    row-gap: 0.25rem !important; }
  .row-gap-lg-2 {
    row-gap: 0.5rem !important; }
  .row-gap-lg-3 {
    row-gap: 1rem !important; }
  .row-gap-lg-4 {
    row-gap: 1.5rem !important; }
  .row-gap-lg-5 {
    row-gap: 2rem !important; }
  .row-gap-lg-6 {
    row-gap: 2.5rem !important; }
  .row-gap-lg-7 {
    row-gap: 3rem !important; }
  .row-gap-lg-8 {
    row-gap: 3.5rem !important; }
  .row-gap-lg-9 {
    row-gap: 4rem !important; }
  .row-gap-lg-10 {
    row-gap: 5rem !important; }
  .row-gap-lg-11 {
    row-gap: 6rem !important; }
  .row-gap-lg-12 {
    row-gap: 7rem !important; }
  .column-gap-lg-0 {
    column-gap: 0 !important; }
  .column-gap-lg-1 {
    column-gap: 0.25rem !important; }
  .column-gap-lg-2 {
    column-gap: 0.5rem !important; }
  .column-gap-lg-3 {
    column-gap: 1rem !important; }
  .column-gap-lg-4 {
    column-gap: 1.5rem !important; }
  .column-gap-lg-5 {
    column-gap: 2rem !important; }
  .column-gap-lg-6 {
    column-gap: 2.5rem !important; }
  .column-gap-lg-7 {
    column-gap: 3rem !important; }
  .column-gap-lg-8 {
    column-gap: 3.5rem !important; }
  .column-gap-lg-9 {
    column-gap: 4rem !important; }
  .column-gap-lg-10 {
    column-gap: 5rem !important; }
  .column-gap-lg-11 {
    column-gap: 6rem !important; }
  .column-gap-lg-12 {
    column-gap: 7rem !important; }
  .text-lg-start {
    text-align: left !important; }
  .text-lg-end {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; }
  .w-px-lg-50 {
    width: 50px !important; }
  .w-px-lg-100 {
    width: 100px !important; }
  .w-px-lg-150 {
    width: 150px !important; }
  .w-px-lg-200 {
    width: 200px !important; }
  .w-px-lg-250 {
    width: 250px !important; }
  .w-px-lg-300 {
    width: 300px !important; }
  .w-px-lg-350 {
    width: 350px !important; }
  .min-h-lg-150px {
    min-height: 150px !important; }
  .min-h-lg-200px {
    min-height: 200px !important; }
  .min-h-lg-250px {
    min-height: 250px !important; }
  .min-h-lg-350px {
    min-height: 350px !important; } }

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important; }
  .float-xl-end {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .object-fit-xl-contain {
    object-fit: contain !important; }
  .object-fit-xl-cover {
    object-fit: cover !important; }
  .object-fit-xl-fill {
    object-fit: fill !important; }
  .object-fit-xl-scale {
    object-fit: scale-down !important; }
  .object-fit-xl-none {
    object-fit: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-grid {
    display: grid !important; }
  .d-xl-inline-grid {
    display: inline-grid !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .d-xl-none {
    display: none !important; }
  .w-xl-5 {
    width: 5% !important; }
  .w-xl-10 {
    width: 10% !important; }
  .w-xl-15 {
    width: 15% !important; }
  .w-xl-25 {
    width: 25% !important; }
  .w-xl-30 {
    width: 30% !important; }
  .w-xl-35 {
    width: 35% !important; }
  .w-xl-40 {
    width: 40% !important; }
  .w-xl-45 {
    width: 45% !important; }
  .w-xl-50 {
    width: 50% !important; }
  .w-xl-55 {
    width: 55% !important; }
  .w-xl-60 {
    width: 60% !important; }
  .w-xl-65 {
    width: 65% !important; }
  .w-xl-75 {
    width: 75% !important; }
  .w-xl-80 {
    width: 80% !important; }
  .w-xl-85 {
    width: 85% !important; }
  .w-xl-90 {
    width: 90% !important; }
  .w-xl-95 {
    width: 95% !important; }
  .w-xl-100 {
    width: 100% !important; }
  .w-xl-auto {
    width: auto !important; }
  .min-vh-xl-50 {
    min-height: 50vh !important; }
  .min-vh-xl-65 {
    min-height: 65vh !important; }
  .min-vh-xl-75 {
    min-height: 75vh !important; }
  .min-vh-xl-85 {
    min-height: 85vh !important; }
  .min-vh-xl-100 {
    min-height: 100vh !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .m-xl-5 {
    margin: 2rem !important; }
  .m-xl-6 {
    margin: 2.5rem !important; }
  .m-xl-7 {
    margin: 3rem !important; }
  .m-xl-8 {
    margin: 3.5rem !important; }
  .m-xl-9 {
    margin: 4rem !important; }
  .m-xl-10 {
    margin: 5rem !important; }
  .m-xl-11 {
    margin: 6rem !important; }
  .m-xl-12 {
    margin: 7rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xl-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xl-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 1.5rem !important; }
  .mt-xl-5 {
    margin-top: 2rem !important; }
  .mt-xl-6 {
    margin-top: 2.5rem !important; }
  .mt-xl-7 {
    margin-top: 3rem !important; }
  .mt-xl-8 {
    margin-top: 3.5rem !important; }
  .mt-xl-9 {
    margin-top: 4rem !important; }
  .mt-xl-10 {
    margin-top: 5rem !important; }
  .mt-xl-11 {
    margin-top: 6rem !important; }
  .mt-xl-12 {
    margin-top: 7rem !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .me-xl-0 {
    margin-right: 0 !important; }
  .me-xl-1 {
    margin-right: 0.25rem !important; }
  .me-xl-2 {
    margin-right: 0.5rem !important; }
  .me-xl-3 {
    margin-right: 1rem !important; }
  .me-xl-4 {
    margin-right: 1.5rem !important; }
  .me-xl-5 {
    margin-right: 2rem !important; }
  .me-xl-6 {
    margin-right: 2.5rem !important; }
  .me-xl-7 {
    margin-right: 3rem !important; }
  .me-xl-8 {
    margin-right: 3.5rem !important; }
  .me-xl-9 {
    margin-right: 4rem !important; }
  .me-xl-10 {
    margin-right: 5rem !important; }
  .me-xl-11 {
    margin-right: 6rem !important; }
  .me-xl-12 {
    margin-right: 7rem !important; }
  .me-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xl-5 {
    margin-bottom: 2rem !important; }
  .mb-xl-6 {
    margin-bottom: 2.5rem !important; }
  .mb-xl-7 {
    margin-bottom: 3rem !important; }
  .mb-xl-8 {
    margin-bottom: 3.5rem !important; }
  .mb-xl-9 {
    margin-bottom: 4rem !important; }
  .mb-xl-10 {
    margin-bottom: 5rem !important; }
  .mb-xl-11 {
    margin-bottom: 6rem !important; }
  .mb-xl-12 {
    margin-bottom: 7rem !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ms-xl-0 {
    margin-left: 0 !important; }
  .ms-xl-1 {
    margin-left: 0.25rem !important; }
  .ms-xl-2 {
    margin-left: 0.5rem !important; }
  .ms-xl-3 {
    margin-left: 1rem !important; }
  .ms-xl-4 {
    margin-left: 1.5rem !important; }
  .ms-xl-5 {
    margin-left: 2rem !important; }
  .ms-xl-6 {
    margin-left: 2.5rem !important; }
  .ms-xl-7 {
    margin-left: 3rem !important; }
  .ms-xl-8 {
    margin-left: 3.5rem !important; }
  .ms-xl-9 {
    margin-left: 4rem !important; }
  .ms-xl-10 {
    margin-left: 5rem !important; }
  .ms-xl-11 {
    margin-left: 6rem !important; }
  .ms-xl-12 {
    margin-left: 7rem !important; }
  .ms-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .m-xl-n5 {
    margin: -2rem !important; }
  .m-xl-n6 {
    margin: -2.5rem !important; }
  .m-xl-n7 {
    margin: -3rem !important; }
  .m-xl-n8 {
    margin: -3.5rem !important; }
  .m-xl-n9 {
    margin: -4rem !important; }
  .m-xl-n10 {
    margin: -5rem !important; }
  .m-xl-n11 {
    margin: -6rem !important; }
  .m-xl-n12 {
    margin: -7rem !important; }
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-xl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-xl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-xl-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important; }
  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-xl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-xl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-xl-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important; }
  .mt-xl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xl-n3 {
    margin-top: -1rem !important; }
  .mt-xl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xl-n5 {
    margin-top: -2rem !important; }
  .mt-xl-n6 {
    margin-top: -2.5rem !important; }
  .mt-xl-n7 {
    margin-top: -3rem !important; }
  .mt-xl-n8 {
    margin-top: -3.5rem !important; }
  .mt-xl-n9 {
    margin-top: -4rem !important; }
  .mt-xl-n10 {
    margin-top: -5rem !important; }
  .mt-xl-n11 {
    margin-top: -6rem !important; }
  .mt-xl-n12 {
    margin-top: -7rem !important; }
  .me-xl-n1 {
    margin-right: -0.25rem !important; }
  .me-xl-n2 {
    margin-right: -0.5rem !important; }
  .me-xl-n3 {
    margin-right: -1rem !important; }
  .me-xl-n4 {
    margin-right: -1.5rem !important; }
  .me-xl-n5 {
    margin-right: -2rem !important; }
  .me-xl-n6 {
    margin-right: -2.5rem !important; }
  .me-xl-n7 {
    margin-right: -3rem !important; }
  .me-xl-n8 {
    margin-right: -3.5rem !important; }
  .me-xl-n9 {
    margin-right: -4rem !important; }
  .me-xl-n10 {
    margin-right: -5rem !important; }
  .me-xl-n11 {
    margin-right: -6rem !important; }
  .me-xl-n12 {
    margin-right: -7rem !important; }
  .mb-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xl-n5 {
    margin-bottom: -2rem !important; }
  .mb-xl-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-xl-n7 {
    margin-bottom: -3rem !important; }
  .mb-xl-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-xl-n9 {
    margin-bottom: -4rem !important; }
  .mb-xl-n10 {
    margin-bottom: -5rem !important; }
  .mb-xl-n11 {
    margin-bottom: -6rem !important; }
  .mb-xl-n12 {
    margin-bottom: -7rem !important; }
  .ms-xl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xl-n3 {
    margin-left: -1rem !important; }
  .ms-xl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xl-n5 {
    margin-left: -2rem !important; }
  .ms-xl-n6 {
    margin-left: -2.5rem !important; }
  .ms-xl-n7 {
    margin-left: -3rem !important; }
  .ms-xl-n8 {
    margin-left: -3.5rem !important; }
  .ms-xl-n9 {
    margin-left: -4rem !important; }
  .ms-xl-n10 {
    margin-left: -5rem !important; }
  .ms-xl-n11 {
    margin-left: -6rem !important; }
  .ms-xl-n12 {
    margin-left: -7rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .p-xl-5 {
    padding: 2rem !important; }
  .p-xl-6 {
    padding: 2.5rem !important; }
  .p-xl-7 {
    padding: 3rem !important; }
  .p-xl-8 {
    padding: 3.5rem !important; }
  .p-xl-9 {
    padding: 4rem !important; }
  .p-xl-10 {
    padding: 5rem !important; }
  .p-xl-11 {
    padding: 6rem !important; }
  .p-xl-12 {
    padding: 7rem !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xl-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xl-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 1.5rem !important; }
  .pt-xl-5 {
    padding-top: 2rem !important; }
  .pt-xl-6 {
    padding-top: 2.5rem !important; }
  .pt-xl-7 {
    padding-top: 3rem !important; }
  .pt-xl-8 {
    padding-top: 3.5rem !important; }
  .pt-xl-9 {
    padding-top: 4rem !important; }
  .pt-xl-10 {
    padding-top: 5rem !important; }
  .pt-xl-11 {
    padding-top: 6rem !important; }
  .pt-xl-12 {
    padding-top: 7rem !important; }
  .pe-xl-0 {
    padding-right: 0 !important; }
  .pe-xl-1 {
    padding-right: 0.25rem !important; }
  .pe-xl-2 {
    padding-right: 0.5rem !important; }
  .pe-xl-3 {
    padding-right: 1rem !important; }
  .pe-xl-4 {
    padding-right: 1.5rem !important; }
  .pe-xl-5 {
    padding-right: 2rem !important; }
  .pe-xl-6 {
    padding-right: 2.5rem !important; }
  .pe-xl-7 {
    padding-right: 3rem !important; }
  .pe-xl-8 {
    padding-right: 3.5rem !important; }
  .pe-xl-9 {
    padding-right: 4rem !important; }
  .pe-xl-10 {
    padding-right: 5rem !important; }
  .pe-xl-11 {
    padding-right: 6rem !important; }
  .pe-xl-12 {
    padding-right: 7rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xl-5 {
    padding-bottom: 2rem !important; }
  .pb-xl-6 {
    padding-bottom: 2.5rem !important; }
  .pb-xl-7 {
    padding-bottom: 3rem !important; }
  .pb-xl-8 {
    padding-bottom: 3.5rem !important; }
  .pb-xl-9 {
    padding-bottom: 4rem !important; }
  .pb-xl-10 {
    padding-bottom: 5rem !important; }
  .pb-xl-11 {
    padding-bottom: 6rem !important; }
  .pb-xl-12 {
    padding-bottom: 7rem !important; }
  .ps-xl-0 {
    padding-left: 0 !important; }
  .ps-xl-1 {
    padding-left: 0.25rem !important; }
  .ps-xl-2 {
    padding-left: 0.5rem !important; }
  .ps-xl-3 {
    padding-left: 1rem !important; }
  .ps-xl-4 {
    padding-left: 1.5rem !important; }
  .ps-xl-5 {
    padding-left: 2rem !important; }
  .ps-xl-6 {
    padding-left: 2.5rem !important; }
  .ps-xl-7 {
    padding-left: 3rem !important; }
  .ps-xl-8 {
    padding-left: 3.5rem !important; }
  .ps-xl-9 {
    padding-left: 4rem !important; }
  .ps-xl-10 {
    padding-left: 5rem !important; }
  .ps-xl-11 {
    padding-left: 6rem !important; }
  .ps-xl-12 {
    padding-left: 7rem !important; }
  .gap-xl-0 {
    gap: 0 !important; }
  .gap-xl-1 {
    gap: 0.25rem !important; }
  .gap-xl-2 {
    gap: 0.5rem !important; }
  .gap-xl-3 {
    gap: 1rem !important; }
  .gap-xl-4 {
    gap: 1.5rem !important; }
  .gap-xl-5 {
    gap: 2rem !important; }
  .gap-xl-6 {
    gap: 2.5rem !important; }
  .gap-xl-7 {
    gap: 3rem !important; }
  .gap-xl-8 {
    gap: 3.5rem !important; }
  .gap-xl-9 {
    gap: 4rem !important; }
  .gap-xl-10 {
    gap: 5rem !important; }
  .gap-xl-11 {
    gap: 6rem !important; }
  .gap-xl-12 {
    gap: 7rem !important; }
  .row-gap-xl-0 {
    row-gap: 0 !important; }
  .row-gap-xl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xl-3 {
    row-gap: 1rem !important; }
  .row-gap-xl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xl-5 {
    row-gap: 2rem !important; }
  .row-gap-xl-6 {
    row-gap: 2.5rem !important; }
  .row-gap-xl-7 {
    row-gap: 3rem !important; }
  .row-gap-xl-8 {
    row-gap: 3.5rem !important; }
  .row-gap-xl-9 {
    row-gap: 4rem !important; }
  .row-gap-xl-10 {
    row-gap: 5rem !important; }
  .row-gap-xl-11 {
    row-gap: 6rem !important; }
  .row-gap-xl-12 {
    row-gap: 7rem !important; }
  .column-gap-xl-0 {
    column-gap: 0 !important; }
  .column-gap-xl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xl-3 {
    column-gap: 1rem !important; }
  .column-gap-xl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xl-5 {
    column-gap: 2rem !important; }
  .column-gap-xl-6 {
    column-gap: 2.5rem !important; }
  .column-gap-xl-7 {
    column-gap: 3rem !important; }
  .column-gap-xl-8 {
    column-gap: 3.5rem !important; }
  .column-gap-xl-9 {
    column-gap: 4rem !important; }
  .column-gap-xl-10 {
    column-gap: 5rem !important; }
  .column-gap-xl-11 {
    column-gap: 6rem !important; }
  .column-gap-xl-12 {
    column-gap: 7rem !important; }
  .text-xl-start {
    text-align: left !important; }
  .text-xl-end {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; }
  .w-px-xl-50 {
    width: 50px !important; }
  .w-px-xl-100 {
    width: 100px !important; }
  .w-px-xl-150 {
    width: 150px !important; }
  .w-px-xl-200 {
    width: 200px !important; }
  .w-px-xl-250 {
    width: 250px !important; }
  .w-px-xl-300 {
    width: 300px !important; }
  .w-px-xl-350 {
    width: 350px !important; }
  .min-h-xl-150px {
    min-height: 150px !important; }
  .min-h-xl-200px {
    min-height: 200px !important; }
  .min-h-xl-250px {
    min-height: 250px !important; }
  .min-h-xl-350px {
    min-height: 350px !important; } }

@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important; }
  .float-xxl-end {
    float: right !important; }
  .float-xxl-none {
    float: none !important; }
  .object-fit-xxl-contain {
    object-fit: contain !important; }
  .object-fit-xxl-cover {
    object-fit: cover !important; }
  .object-fit-xxl-fill {
    object-fit: fill !important; }
  .object-fit-xxl-scale {
    object-fit: scale-down !important; }
  .object-fit-xxl-none {
    object-fit: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-grid {
    display: grid !important; }
  .d-xxl-inline-grid {
    display: inline-grid !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; }
  .d-xxl-none {
    display: none !important; }
  .w-xxl-5 {
    width: 5% !important; }
  .w-xxl-10 {
    width: 10% !important; }
  .w-xxl-15 {
    width: 15% !important; }
  .w-xxl-25 {
    width: 25% !important; }
  .w-xxl-30 {
    width: 30% !important; }
  .w-xxl-35 {
    width: 35% !important; }
  .w-xxl-40 {
    width: 40% !important; }
  .w-xxl-45 {
    width: 45% !important; }
  .w-xxl-50 {
    width: 50% !important; }
  .w-xxl-55 {
    width: 55% !important; }
  .w-xxl-60 {
    width: 60% !important; }
  .w-xxl-65 {
    width: 65% !important; }
  .w-xxl-75 {
    width: 75% !important; }
  .w-xxl-80 {
    width: 80% !important; }
  .w-xxl-85 {
    width: 85% !important; }
  .w-xxl-90 {
    width: 90% !important; }
  .w-xxl-95 {
    width: 95% !important; }
  .w-xxl-100 {
    width: 100% !important; }
  .w-xxl-auto {
    width: auto !important; }
  .min-vh-xxl-50 {
    min-height: 50vh !important; }
  .min-vh-xxl-65 {
    min-height: 65vh !important; }
  .min-vh-xxl-75 {
    min-height: 75vh !important; }
  .min-vh-xxl-85 {
    min-height: 85vh !important; }
  .min-vh-xxl-100 {
    min-height: 100vh !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; }
  .order-xxl-first {
    order: -1 !important; }
  .order-xxl-0 {
    order: 0 !important; }
  .order-xxl-1 {
    order: 1 !important; }
  .order-xxl-2 {
    order: 2 !important; }
  .order-xxl-3 {
    order: 3 !important; }
  .order-xxl-4 {
    order: 4 !important; }
  .order-xxl-5 {
    order: 5 !important; }
  .order-xxl-last {
    order: 6 !important; }
  .m-xxl-0 {
    margin: 0 !important; }
  .m-xxl-1 {
    margin: 0.25rem !important; }
  .m-xxl-2 {
    margin: 0.5rem !important; }
  .m-xxl-3 {
    margin: 1rem !important; }
  .m-xxl-4 {
    margin: 1.5rem !important; }
  .m-xxl-5 {
    margin: 2rem !important; }
  .m-xxl-6 {
    margin: 2.5rem !important; }
  .m-xxl-7 {
    margin: 3rem !important; }
  .m-xxl-8 {
    margin: 3.5rem !important; }
  .m-xxl-9 {
    margin: 4rem !important; }
  .m-xxl-10 {
    margin: 5rem !important; }
  .m-xxl-11 {
    margin: 6rem !important; }
  .m-xxl-12 {
    margin: 7rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  .mx-xxl-5 {
    margin-right: 2rem !important;
    margin-left: 2rem !important; }
  .mx-xxl-6 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important; }
  .mx-xxl-7 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  .mx-xxl-8 {
    margin-right: 3.5rem !important;
    margin-left: 3.5rem !important; }
  .mx-xxl-9 {
    margin-right: 4rem !important;
    margin-left: 4rem !important; }
  .mx-xxl-10 {
    margin-right: 5rem !important;
    margin-left: 5rem !important; }
  .mx-xxl-11 {
    margin-right: 6rem !important;
    margin-left: 6rem !important; }
  .mx-xxl-12 {
    margin-right: 7rem !important;
    margin-left: 7rem !important; }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  .my-xxl-5 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important; }
  .my-xxl-6 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important; }
  .my-xxl-7 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  .my-xxl-8 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important; }
  .my-xxl-9 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important; }
  .my-xxl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important; }
  .my-xxl-11 {
    margin-top: 6rem !important;
    margin-bottom: 6rem !important; }
  .my-xxl-12 {
    margin-top: 7rem !important;
    margin-bottom: 7rem !important; }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xxl-0 {
    margin-top: 0 !important; }
  .mt-xxl-1 {
    margin-top: 0.25rem !important; }
  .mt-xxl-2 {
    margin-top: 0.5rem !important; }
  .mt-xxl-3 {
    margin-top: 1rem !important; }
  .mt-xxl-4 {
    margin-top: 1.5rem !important; }
  .mt-xxl-5 {
    margin-top: 2rem !important; }
  .mt-xxl-6 {
    margin-top: 2.5rem !important; }
  .mt-xxl-7 {
    margin-top: 3rem !important; }
  .mt-xxl-8 {
    margin-top: 3.5rem !important; }
  .mt-xxl-9 {
    margin-top: 4rem !important; }
  .mt-xxl-10 {
    margin-top: 5rem !important; }
  .mt-xxl-11 {
    margin-top: 6rem !important; }
  .mt-xxl-12 {
    margin-top: 7rem !important; }
  .mt-xxl-auto {
    margin-top: auto !important; }
  .me-xxl-0 {
    margin-right: 0 !important; }
  .me-xxl-1 {
    margin-right: 0.25rem !important; }
  .me-xxl-2 {
    margin-right: 0.5rem !important; }
  .me-xxl-3 {
    margin-right: 1rem !important; }
  .me-xxl-4 {
    margin-right: 1.5rem !important; }
  .me-xxl-5 {
    margin-right: 2rem !important; }
  .me-xxl-6 {
    margin-right: 2.5rem !important; }
  .me-xxl-7 {
    margin-right: 3rem !important; }
  .me-xxl-8 {
    margin-right: 3.5rem !important; }
  .me-xxl-9 {
    margin-right: 4rem !important; }
  .me-xxl-10 {
    margin-right: 5rem !important; }
  .me-xxl-11 {
    margin-right: 6rem !important; }
  .me-xxl-12 {
    margin-right: 7rem !important; }
  .me-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-0 {
    margin-bottom: 0 !important; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xxl-3 {
    margin-bottom: 1rem !important; }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important; }
  .mb-xxl-5 {
    margin-bottom: 2rem !important; }
  .mb-xxl-6 {
    margin-bottom: 2.5rem !important; }
  .mb-xxl-7 {
    margin-bottom: 3rem !important; }
  .mb-xxl-8 {
    margin-bottom: 3.5rem !important; }
  .mb-xxl-9 {
    margin-bottom: 4rem !important; }
  .mb-xxl-10 {
    margin-bottom: 5rem !important; }
  .mb-xxl-11 {
    margin-bottom: 6rem !important; }
  .mb-xxl-12 {
    margin-bottom: 7rem !important; }
  .mb-xxl-auto {
    margin-bottom: auto !important; }
  .ms-xxl-0 {
    margin-left: 0 !important; }
  .ms-xxl-1 {
    margin-left: 0.25rem !important; }
  .ms-xxl-2 {
    margin-left: 0.5rem !important; }
  .ms-xxl-3 {
    margin-left: 1rem !important; }
  .ms-xxl-4 {
    margin-left: 1.5rem !important; }
  .ms-xxl-5 {
    margin-left: 2rem !important; }
  .ms-xxl-6 {
    margin-left: 2.5rem !important; }
  .ms-xxl-7 {
    margin-left: 3rem !important; }
  .ms-xxl-8 {
    margin-left: 3.5rem !important; }
  .ms-xxl-9 {
    margin-left: 4rem !important; }
  .ms-xxl-10 {
    margin-left: 5rem !important; }
  .ms-xxl-11 {
    margin-left: 6rem !important; }
  .ms-xxl-12 {
    margin-left: 7rem !important; }
  .ms-xxl-auto {
    margin-left: auto !important; }
  .m-xxl-n1 {
    margin: -0.25rem !important; }
  .m-xxl-n2 {
    margin: -0.5rem !important; }
  .m-xxl-n3 {
    margin: -1rem !important; }
  .m-xxl-n4 {
    margin: -1.5rem !important; }
  .m-xxl-n5 {
    margin: -2rem !important; }
  .m-xxl-n6 {
    margin: -2.5rem !important; }
  .m-xxl-n7 {
    margin: -3rem !important; }
  .m-xxl-n8 {
    margin: -3.5rem !important; }
  .m-xxl-n9 {
    margin: -4rem !important; }
  .m-xxl-n10 {
    margin: -5rem !important; }
  .m-xxl-n11 {
    margin: -6rem !important; }
  .m-xxl-n12 {
    margin: -7rem !important; }
  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important; }
  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important; }
  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important; }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important; }
  .mx-xxl-n5 {
    margin-right: -2rem !important;
    margin-left: -2rem !important; }
  .mx-xxl-n6 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important; }
  .mx-xxl-n7 {
    margin-right: -3rem !important;
    margin-left: -3rem !important; }
  .mx-xxl-n8 {
    margin-right: -3.5rem !important;
    margin-left: -3.5rem !important; }
  .mx-xxl-n9 {
    margin-right: -4rem !important;
    margin-left: -4rem !important; }
  .mx-xxl-n10 {
    margin-right: -5rem !important;
    margin-left: -5rem !important; }
  .mx-xxl-n11 {
    margin-right: -6rem !important;
    margin-left: -6rem !important; }
  .mx-xxl-n12 {
    margin-right: -7rem !important;
    margin-left: -7rem !important; }
  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important; }
  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important; }
  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important; }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important; }
  .my-xxl-n5 {
    margin-top: -2rem !important;
    margin-bottom: -2rem !important; }
  .my-xxl-n6 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important; }
  .my-xxl-n7 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important; }
  .my-xxl-n8 {
    margin-top: -3.5rem !important;
    margin-bottom: -3.5rem !important; }
  .my-xxl-n9 {
    margin-top: -4rem !important;
    margin-bottom: -4rem !important; }
  .my-xxl-n10 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important; }
  .my-xxl-n11 {
    margin-top: -6rem !important;
    margin-bottom: -6rem !important; }
  .my-xxl-n12 {
    margin-top: -7rem !important;
    margin-bottom: -7rem !important; }
  .mt-xxl-n1 {
    margin-top: -0.25rem !important; }
  .mt-xxl-n2 {
    margin-top: -0.5rem !important; }
  .mt-xxl-n3 {
    margin-top: -1rem !important; }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important; }
  .mt-xxl-n5 {
    margin-top: -2rem !important; }
  .mt-xxl-n6 {
    margin-top: -2.5rem !important; }
  .mt-xxl-n7 {
    margin-top: -3rem !important; }
  .mt-xxl-n8 {
    margin-top: -3.5rem !important; }
  .mt-xxl-n9 {
    margin-top: -4rem !important; }
  .mt-xxl-n10 {
    margin-top: -5rem !important; }
  .mt-xxl-n11 {
    margin-top: -6rem !important; }
  .mt-xxl-n12 {
    margin-top: -7rem !important; }
  .me-xxl-n1 {
    margin-right: -0.25rem !important; }
  .me-xxl-n2 {
    margin-right: -0.5rem !important; }
  .me-xxl-n3 {
    margin-right: -1rem !important; }
  .me-xxl-n4 {
    margin-right: -1.5rem !important; }
  .me-xxl-n5 {
    margin-right: -2rem !important; }
  .me-xxl-n6 {
    margin-right: -2.5rem !important; }
  .me-xxl-n7 {
    margin-right: -3rem !important; }
  .me-xxl-n8 {
    margin-right: -3.5rem !important; }
  .me-xxl-n9 {
    margin-right: -4rem !important; }
  .me-xxl-n10 {
    margin-right: -5rem !important; }
  .me-xxl-n11 {
    margin-right: -6rem !important; }
  .me-xxl-n12 {
    margin-right: -7rem !important; }
  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important; }
  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important; }
  .mb-xxl-n3 {
    margin-bottom: -1rem !important; }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important; }
  .mb-xxl-n5 {
    margin-bottom: -2rem !important; }
  .mb-xxl-n6 {
    margin-bottom: -2.5rem !important; }
  .mb-xxl-n7 {
    margin-bottom: -3rem !important; }
  .mb-xxl-n8 {
    margin-bottom: -3.5rem !important; }
  .mb-xxl-n9 {
    margin-bottom: -4rem !important; }
  .mb-xxl-n10 {
    margin-bottom: -5rem !important; }
  .mb-xxl-n11 {
    margin-bottom: -6rem !important; }
  .mb-xxl-n12 {
    margin-bottom: -7rem !important; }
  .ms-xxl-n1 {
    margin-left: -0.25rem !important; }
  .ms-xxl-n2 {
    margin-left: -0.5rem !important; }
  .ms-xxl-n3 {
    margin-left: -1rem !important; }
  .ms-xxl-n4 {
    margin-left: -1.5rem !important; }
  .ms-xxl-n5 {
    margin-left: -2rem !important; }
  .ms-xxl-n6 {
    margin-left: -2.5rem !important; }
  .ms-xxl-n7 {
    margin-left: -3rem !important; }
  .ms-xxl-n8 {
    margin-left: -3.5rem !important; }
  .ms-xxl-n9 {
    margin-left: -4rem !important; }
  .ms-xxl-n10 {
    margin-left: -5rem !important; }
  .ms-xxl-n11 {
    margin-left: -6rem !important; }
  .ms-xxl-n12 {
    margin-left: -7rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .p-xxl-1 {
    padding: 0.25rem !important; }
  .p-xxl-2 {
    padding: 0.5rem !important; }
  .p-xxl-3 {
    padding: 1rem !important; }
  .p-xxl-4 {
    padding: 1.5rem !important; }
  .p-xxl-5 {
    padding: 2rem !important; }
  .p-xxl-6 {
    padding: 2.5rem !important; }
  .p-xxl-7 {
    padding: 3rem !important; }
  .p-xxl-8 {
    padding: 3.5rem !important; }
  .p-xxl-9 {
    padding: 4rem !important; }
  .p-xxl-10 {
    padding: 5rem !important; }
  .p-xxl-11 {
    padding: 6rem !important; }
  .p-xxl-12 {
    padding: 7rem !important; }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  .px-xxl-5 {
    padding-right: 2rem !important;
    padding-left: 2rem !important; }
  .px-xxl-6 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important; }
  .px-xxl-7 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  .px-xxl-8 {
    padding-right: 3.5rem !important;
    padding-left: 3.5rem !important; }
  .px-xxl-9 {
    padding-right: 4rem !important;
    padding-left: 4rem !important; }
  .px-xxl-10 {
    padding-right: 5rem !important;
    padding-left: 5rem !important; }
  .px-xxl-11 {
    padding-right: 6rem !important;
    padding-left: 6rem !important; }
  .px-xxl-12 {
    padding-right: 7rem !important;
    padding-left: 7rem !important; }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  .py-xxl-5 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important; }
  .py-xxl-6 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important; }
  .py-xxl-7 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  .py-xxl-8 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important; }
  .py-xxl-9 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important; }
  .py-xxl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important; }
  .py-xxl-11 {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important; }
  .py-xxl-12 {
    padding-top: 7rem !important;
    padding-bottom: 7rem !important; }
  .pt-xxl-0 {
    padding-top: 0 !important; }
  .pt-xxl-1 {
    padding-top: 0.25rem !important; }
  .pt-xxl-2 {
    padding-top: 0.5rem !important; }
  .pt-xxl-3 {
    padding-top: 1rem !important; }
  .pt-xxl-4 {
    padding-top: 1.5rem !important; }
  .pt-xxl-5 {
    padding-top: 2rem !important; }
  .pt-xxl-6 {
    padding-top: 2.5rem !important; }
  .pt-xxl-7 {
    padding-top: 3rem !important; }
  .pt-xxl-8 {
    padding-top: 3.5rem !important; }
  .pt-xxl-9 {
    padding-top: 4rem !important; }
  .pt-xxl-10 {
    padding-top: 5rem !important; }
  .pt-xxl-11 {
    padding-top: 6rem !important; }
  .pt-xxl-12 {
    padding-top: 7rem !important; }
  .pe-xxl-0 {
    padding-right: 0 !important; }
  .pe-xxl-1 {
    padding-right: 0.25rem !important; }
  .pe-xxl-2 {
    padding-right: 0.5rem !important; }
  .pe-xxl-3 {
    padding-right: 1rem !important; }
  .pe-xxl-4 {
    padding-right: 1.5rem !important; }
  .pe-xxl-5 {
    padding-right: 2rem !important; }
  .pe-xxl-6 {
    padding-right: 2.5rem !important; }
  .pe-xxl-7 {
    padding-right: 3rem !important; }
  .pe-xxl-8 {
    padding-right: 3.5rem !important; }
  .pe-xxl-9 {
    padding-right: 4rem !important; }
  .pe-xxl-10 {
    padding-right: 5rem !important; }
  .pe-xxl-11 {
    padding-right: 6rem !important; }
  .pe-xxl-12 {
    padding-right: 7rem !important; }
  .pb-xxl-0 {
    padding-bottom: 0 !important; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xxl-3 {
    padding-bottom: 1rem !important; }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important; }
  .pb-xxl-5 {
    padding-bottom: 2rem !important; }
  .pb-xxl-6 {
    padding-bottom: 2.5rem !important; }
  .pb-xxl-7 {
    padding-bottom: 3rem !important; }
  .pb-xxl-8 {
    padding-bottom: 3.5rem !important; }
  .pb-xxl-9 {
    padding-bottom: 4rem !important; }
  .pb-xxl-10 {
    padding-bottom: 5rem !important; }
  .pb-xxl-11 {
    padding-bottom: 6rem !important; }
  .pb-xxl-12 {
    padding-bottom: 7rem !important; }
  .ps-xxl-0 {
    padding-left: 0 !important; }
  .ps-xxl-1 {
    padding-left: 0.25rem !important; }
  .ps-xxl-2 {
    padding-left: 0.5rem !important; }
  .ps-xxl-3 {
    padding-left: 1rem !important; }
  .ps-xxl-4 {
    padding-left: 1.5rem !important; }
  .ps-xxl-5 {
    padding-left: 2rem !important; }
  .ps-xxl-6 {
    padding-left: 2.5rem !important; }
  .ps-xxl-7 {
    padding-left: 3rem !important; }
  .ps-xxl-8 {
    padding-left: 3.5rem !important; }
  .ps-xxl-9 {
    padding-left: 4rem !important; }
  .ps-xxl-10 {
    padding-left: 5rem !important; }
  .ps-xxl-11 {
    padding-left: 6rem !important; }
  .ps-xxl-12 {
    padding-left: 7rem !important; }
  .gap-xxl-0 {
    gap: 0 !important; }
  .gap-xxl-1 {
    gap: 0.25rem !important; }
  .gap-xxl-2 {
    gap: 0.5rem !important; }
  .gap-xxl-3 {
    gap: 1rem !important; }
  .gap-xxl-4 {
    gap: 1.5rem !important; }
  .gap-xxl-5 {
    gap: 2rem !important; }
  .gap-xxl-6 {
    gap: 2.5rem !important; }
  .gap-xxl-7 {
    gap: 3rem !important; }
  .gap-xxl-8 {
    gap: 3.5rem !important; }
  .gap-xxl-9 {
    gap: 4rem !important; }
  .gap-xxl-10 {
    gap: 5rem !important; }
  .gap-xxl-11 {
    gap: 6rem !important; }
  .gap-xxl-12 {
    gap: 7rem !important; }
  .row-gap-xxl-0 {
    row-gap: 0 !important; }
  .row-gap-xxl-1 {
    row-gap: 0.25rem !important; }
  .row-gap-xxl-2 {
    row-gap: 0.5rem !important; }
  .row-gap-xxl-3 {
    row-gap: 1rem !important; }
  .row-gap-xxl-4 {
    row-gap: 1.5rem !important; }
  .row-gap-xxl-5 {
    row-gap: 2rem !important; }
  .row-gap-xxl-6 {
    row-gap: 2.5rem !important; }
  .row-gap-xxl-7 {
    row-gap: 3rem !important; }
  .row-gap-xxl-8 {
    row-gap: 3.5rem !important; }
  .row-gap-xxl-9 {
    row-gap: 4rem !important; }
  .row-gap-xxl-10 {
    row-gap: 5rem !important; }
  .row-gap-xxl-11 {
    row-gap: 6rem !important; }
  .row-gap-xxl-12 {
    row-gap: 7rem !important; }
  .column-gap-xxl-0 {
    column-gap: 0 !important; }
  .column-gap-xxl-1 {
    column-gap: 0.25rem !important; }
  .column-gap-xxl-2 {
    column-gap: 0.5rem !important; }
  .column-gap-xxl-3 {
    column-gap: 1rem !important; }
  .column-gap-xxl-4 {
    column-gap: 1.5rem !important; }
  .column-gap-xxl-5 {
    column-gap: 2rem !important; }
  .column-gap-xxl-6 {
    column-gap: 2.5rem !important; }
  .column-gap-xxl-7 {
    column-gap: 3rem !important; }
  .column-gap-xxl-8 {
    column-gap: 3.5rem !important; }
  .column-gap-xxl-9 {
    column-gap: 4rem !important; }
  .column-gap-xxl-10 {
    column-gap: 5rem !important; }
  .column-gap-xxl-11 {
    column-gap: 6rem !important; }
  .column-gap-xxl-12 {
    column-gap: 7rem !important; }
  .text-xxl-start {
    text-align: left !important; }
  .text-xxl-end {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; }
  .w-px-xxl-50 {
    width: 50px !important; }
  .w-px-xxl-100 {
    width: 100px !important; }
  .w-px-xxl-150 {
    width: 150px !important; }
  .w-px-xxl-200 {
    width: 200px !important; }
  .w-px-xxl-250 {
    width: 250px !important; }
  .w-px-xxl-300 {
    width: 300px !important; }
  .w-px-xxl-350 {
    width: 350px !important; }
  .min-h-xxl-150px {
    min-height: 150px !important; }
  .min-h-xxl-200px {
    min-height: 200px !important; }
  .min-h-xxl-250px {
    min-height: 250px !important; }
  .min-h-xxl-350px {
    min-height: 350px !important; } }

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important; }
  .fs-2 {
    font-size: 1.8rem !important; }
  .fs-3 {
    font-size: 1.575rem !important; }
  .fs-4 {
    font-size: 1.35rem !important; } }

@media print {
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-grid {
    display: grid !important; }
  .d-print-inline-grid {
    display: inline-grid !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; }
  .d-print-none {
    display: none !important; } }

body .header-full-mobile-link .navbar-nav > .nav-item .mob-menu {
  padding: 15px;
  height: inherit;
  font-size: 0.95rem;
  line-height: 1.7;
  width: 100%;
  text-align: right; }

.logo-light {
  display: none; }

[data-bs-theme="dark"] .logo-dark {
  display: none; }

[data-bs-theme="dark"] .logo-light {
  display: block; }

[data-bs-theme="light"] .logo-light {
  display: none; }

.header-transparent .logo-dark {
  display: none; }

[data-bs-theme="light"] .header-transparent .logo-light {
  display: block; }

[data-bs-theme="light"] .header-transparent.fixed-header .logo-light {
  display: none; }

[data-bs-theme="light"] .header-transparent.fixed-header .logo-dark {
  display: block; }

.fixed-header .navbar {
  background-color: var(--bs-bg-mode-re);
  box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1); }

.header-main {
  transition: ease all 0.35s; }
  .header-main:not(.fixed-top) {
    position: relative;
    z-index: 1030; }

/* Header Fluid
-----------------------------------*/
.navbar-toggler-px-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2875, 86, 107, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-toggler {
  width: 40px;
  height: 40px;
  border-radius: 0.25rem;
  padding: 0;
  color: var(--bs-text-mode);
  border: none; }

.px-navbar-toggler-icon {
  width: 60%;
  height: 2px;
  background: currentColor;
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 -8px 0 0 currentColor,0 8px 0 0 currentColor; }

/* Header Fluid
-----------------------------------*/
@media (min-width: 1400px) {
  .header-fluid .container-fluid, .header-fluid .container-sm, .header-fluid .container-md, .header-fluid .container-lg, .header-fluid .container-xl, .header-fluid .container-xxl {
    padding-left: 35px;
    padding-right: 35px; } }

/* Header Search
-----------------------------------*/
.px-search-full {
  z-index: 9999; }
  .px-search-full .search-close {
    position: fixed;
    top: 15px;
    right: 15px;
    width: 40px;
    height: 40px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    color: var(--bs-white);
    background: var(--bs-primary);
    border: 2px solid var(--bs-primary);
    border-radius: 50%; }
    .px-search-full .search-close:hover {
      background: var(--bs-dark); }

/* Header Top
-----------------------------------*/
.fixed-header .header-top {
  visibility: hidden;
  pointer-events: none;
  transition: ease visibility 0.35s; }

/* Header Nav Color
-----------------------------------*/
.navbar-dark .nav .nav-link {
  color: rgba(255, 255, 255, 0.55); }
  .navbar-dark .nav .nav-link:hover {
    color: var(--bs-white); }

.navbar-light .nav .nav-link {
  color: rgba(var(--bs-emphasis-color-rgb), 0.65); }
  .navbar-light .nav .nav-link:hover {
    color: var(--bs-primary); }

/* Cart Count
-----------------------------------*/
header [data-cart-items] {
  position: relative; }
  header [data-cart-items]:before {
    content: attr(data-cart-items);
    position: absolute;
    top: -0.65rem;
    right: -0.65rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    font-size: 10px;
    font-weight: 400;
    border-radius: 50%;
    border: 1px solid var(--bs-white);
    background-color: var(--bs-primary);
    color: var(--bs-white); }

/* menu-label
-----------------------------------*/
.menu-label {
  background: var(--bs-danger);
  color: var(--bs-white);
  position: relative;
  padding: 2px 5px;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 1;
  margin-left: 10px; }
  .menu-label:after {
    content: "";
    position: absolute;
    top: 4px;
    left: -5px;
    border-top: 5px solid transparent;
    border-right: 5px solid var(--bs-danger); }
  .dropdown-mega-menu .menu-label {
    margin-left: 4px;
    display: inline-flex;
    top: -4px; }

@media (min-width: 576px) {
  .navbar-expand-sm .dropdown-mega-menu {
    width: 100%;
    background: var(--bs-body-bg); }
  .navbar-expand-sm > .container {
    position: relative; }
  .navbar-expand-sm .dropdown-menu-md {
    min-width: 22rem; }
  .navbar-expand-sm .dropdown-menu-lg {
    min-width: 35rem; }
  .navbar-expand-sm .dropdown-menu-xl {
    min-width: 42rem; }
  .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f282";
    font-family: bootstrap-icons;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 0.55rem;
    border: none;
    line-height: 10px; }
  .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0; }
    .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
      left: 0;
      transform: translate(0, 0); }
    .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
      right: 0;
      transform: translate(0, 0); }
    .navbar-expand-sm .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
      position: relative; }
  .navbar-expand-sm .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%; }
  .navbar-expand-sm .navbar-nav > .nav-item.dropdown-full {
    position: static; }
  .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0; }
    .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu > .dropdown {
      position: relative; }
      .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
        position: relative; }
        .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
          content: "\f285";
          font-family: bootstrap-icons;
          color: currentColor;
          margin-left: 8px;
          display: inline-block;
          font-size: 0.85rem;
          border: none;
          position: absolute;
          top: 9px;
          right: 7px;
          font-weight: 600; }
      .navbar-expand-sm .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
        display: block; }
  .navbar-expand-sm .navbar-nav > .nav-item:hover > .dropdown-menu {
    display: block; }
  .navbar-expand-sm .navbar-nav > .nav-item .mob-menu {
    display: none; }
  .navbar-expand-sm .navbar-nav > .nav-item .dropdown-item {
    font-size: 0.90rem; }
  .navbar-expand-sm .navbar-nav .vertical-dropdown {
    position: static; }
    .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu {
      position: absolute;
      left: 0;
      z-index: 1;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      background: transparent;
      pointer-events: none;
      border: none; }
      .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li {
        width: 250px;
        background: var(--bs-bg-mode-re);
        pointer-events: auto;
        position: static !important;
        box-shadow: 0 9px 11px -7px rgba(var(--bs-black-rgb), 0.1); }
        .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li + li {
          border-top: 1px solid var(--bs-gray-300); }
        .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a {
          position: relative;
          padding: 0.8rem 1rem;
          --bs-dropdown-link-active-bg: null;
          --bs-dropdown-link-hover-bg: null; }
          .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a:after {
            content: "";
            position: absolute;
            border-top: 10px solid transparent;
            border-right: 10px solid var(--bs-gray-300);
            border-bottom: 10px solid transparent;
            right: 0;
            opacity: 0; }
        .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a {
          color: var(--bs-primary); }
          .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a:after {
            opacity: 1; }
        .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover .vertical-mm-in {
          display: block; }
      .navbar-expand-sm .navbar-nav .vertical-dropdown .vertical-dropdown-menu .vertical-mm-in {
        position: absolute;
        top: 0;
        left: 250px;
        right: 0;
        background: var(--bs-bg-mode-re);
        padding: 25px;
        min-height: 100%;
        display: none;
        pointer-events: auto;
        border-left: 1px solid var(--bs-gray-300);
        box-shadow: 8px 8px 11px -8px rgba(var(--bs-black-rgb), 0.1); } }

@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--bs-body-bg);
    z-index: 11; }
  .navbar-expand-sm .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-sm .navbar-nav .nav-item > .nav-link {
      padding: 15px;
      color: var(--bs-text-mode); }
    .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu {
      background: var(--bs-gray-100);
      margin: 0;
      border-radius: 0; }
      .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li {
        position: relative; }
        .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu li .mob-menu {
          line-height: 34px;
          height: 34px; }
      .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
        background: var(--bs-gray-100); }
        .navbar-expand-sm .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
          position: relative; }
  .navbar-expand-sm .vertical-dropdown-menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .navbar-expand-sm .vertical-dropdown-menu .vertical-mm-in {
      display: none;
      padding: 20px;
      background: var(--bs-white); }
    .navbar-expand-sm .vertical-dropdown-menu > li .dropdown-item {
      padding: 0.6rem 1rem; }
    .navbar-expand-sm .vertical-dropdown-menu > li .mob-menu {
      line-height: 45px !important;
      height: 45px !important; }
    .navbar-expand-sm .vertical-dropdown-menu > li + li {
      border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-sm .vertical-dropdown-menu > li.open-menu-parent .vertical-mm-in {
      display: block; } }

@media (min-width: 768px) {
  .navbar-expand-md .dropdown-mega-menu {
    width: 100%;
    background: var(--bs-body-bg); }
  .navbar-expand-md > .container {
    position: relative; }
  .navbar-expand-md .dropdown-menu-md {
    min-width: 22rem; }
  .navbar-expand-md .dropdown-menu-lg {
    min-width: 35rem; }
  .navbar-expand-md .dropdown-menu-xl {
    min-width: 42rem; }
  .navbar-expand-md .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f282";
    font-family: bootstrap-icons;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 0.55rem;
    border: none;
    line-height: 10px; }
  .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0; }
    .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
      left: 0;
      transform: translate(0, 0); }
    .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
      right: 0;
      transform: translate(0, 0); }
    .navbar-expand-md .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
      position: relative; }
  .navbar-expand-md .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%; }
  .navbar-expand-md .navbar-nav > .nav-item.dropdown-full {
    position: static; }
  .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0; }
    .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu > .dropdown {
      position: relative; }
      .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
        position: relative; }
        .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
          content: "\f285";
          font-family: bootstrap-icons;
          color: currentColor;
          margin-left: 8px;
          display: inline-block;
          font-size: 0.85rem;
          border: none;
          position: absolute;
          top: 9px;
          right: 7px;
          font-weight: 600; }
      .navbar-expand-md .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
        display: block; }
  .navbar-expand-md .navbar-nav > .nav-item:hover > .dropdown-menu {
    display: block; }
  .navbar-expand-md .navbar-nav > .nav-item .mob-menu {
    display: none; }
  .navbar-expand-md .navbar-nav > .nav-item .dropdown-item {
    font-size: 0.90rem; }
  .navbar-expand-md .navbar-nav .vertical-dropdown {
    position: static; }
    .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu {
      position: absolute;
      left: 0;
      z-index: 1;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      background: transparent;
      pointer-events: none;
      border: none; }
      .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li {
        width: 250px;
        background: var(--bs-bg-mode-re);
        pointer-events: auto;
        position: static !important;
        box-shadow: 0 9px 11px -7px rgba(var(--bs-black-rgb), 0.1); }
        .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li + li {
          border-top: 1px solid var(--bs-gray-300); }
        .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a {
          position: relative;
          padding: 0.8rem 1rem;
          --bs-dropdown-link-active-bg: null;
          --bs-dropdown-link-hover-bg: null; }
          .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a:after {
            content: "";
            position: absolute;
            border-top: 10px solid transparent;
            border-right: 10px solid var(--bs-gray-300);
            border-bottom: 10px solid transparent;
            right: 0;
            opacity: 0; }
        .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a {
          color: var(--bs-primary); }
          .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a:after {
            opacity: 1; }
        .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover .vertical-mm-in {
          display: block; }
      .navbar-expand-md .navbar-nav .vertical-dropdown .vertical-dropdown-menu .vertical-mm-in {
        position: absolute;
        top: 0;
        left: 250px;
        right: 0;
        background: var(--bs-bg-mode-re);
        padding: 25px;
        min-height: 100%;
        display: none;
        pointer-events: auto;
        border-left: 1px solid var(--bs-gray-300);
        box-shadow: 8px 8px 11px -8px rgba(var(--bs-black-rgb), 0.1); } }

@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--bs-body-bg);
    z-index: 11; }
  .navbar-expand-md .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-md .navbar-nav .nav-item > .nav-link {
      padding: 15px;
      color: var(--bs-text-mode); }
    .navbar-expand-md .navbar-nav .nav-item .dropdown-menu {
      background: var(--bs-gray-100);
      margin: 0;
      border-radius: 0; }
      .navbar-expand-md .navbar-nav .nav-item .dropdown-menu li {
        position: relative; }
        .navbar-expand-md .navbar-nav .nav-item .dropdown-menu li .mob-menu {
          line-height: 34px;
          height: 34px; }
      .navbar-expand-md .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
        background: var(--bs-gray-100); }
        .navbar-expand-md .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
          position: relative; }
  .navbar-expand-md .vertical-dropdown-menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .navbar-expand-md .vertical-dropdown-menu .vertical-mm-in {
      display: none;
      padding: 20px;
      background: var(--bs-white); }
    .navbar-expand-md .vertical-dropdown-menu > li .dropdown-item {
      padding: 0.6rem 1rem; }
    .navbar-expand-md .vertical-dropdown-menu > li .mob-menu {
      line-height: 45px !important;
      height: 45px !important; }
    .navbar-expand-md .vertical-dropdown-menu > li + li {
      border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-md .vertical-dropdown-menu > li.open-menu-parent .vertical-mm-in {
      display: block; } }

@media (min-width: 992px) {
  .navbar-expand-lg .dropdown-mega-menu {
    width: 100%;
    background: var(--bs-body-bg); }
  .navbar-expand-lg > .container {
    position: relative; }
  .navbar-expand-lg .dropdown-menu-md {
    min-width: 22rem; }
  .navbar-expand-lg .dropdown-menu-lg {
    min-width: 35rem; }
  .navbar-expand-lg .dropdown-menu-xl {
    min-width: 42rem; }
  .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f282";
    font-family: bootstrap-icons;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 0.55rem;
    border: none;
    line-height: 10px; }
  .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0; }
    .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
      left: 0;
      transform: translate(0, 0); }
    .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
      right: 0;
      transform: translate(0, 0); }
    .navbar-expand-lg .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
      position: relative; }
  .navbar-expand-lg .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%; }
  .navbar-expand-lg .navbar-nav > .nav-item.dropdown-full {
    position: static; }
  .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0; }
    .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu > .dropdown {
      position: relative; }
      .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
        position: relative; }
        .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
          content: "\f285";
          font-family: bootstrap-icons;
          color: currentColor;
          margin-left: 8px;
          display: inline-block;
          font-size: 0.85rem;
          border: none;
          position: absolute;
          top: 9px;
          right: 7px;
          font-weight: 600; }
      .navbar-expand-lg .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
        display: block; }
  .navbar-expand-lg .navbar-nav > .nav-item:hover > .dropdown-menu {
    display: block; }
  .navbar-expand-lg .navbar-nav > .nav-item .mob-menu {
    display: none; }
  .navbar-expand-lg .navbar-nav > .nav-item .dropdown-item {
    font-size: 0.90rem; }
  .navbar-expand-lg .navbar-nav .vertical-dropdown {
    position: static; }
    .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu {
      position: absolute;
      left: 0;
      z-index: 1;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      background: transparent;
      pointer-events: none;
      border: none; }
      .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li {
        width: 250px;
        background: var(--bs-bg-mode-re);
        pointer-events: auto;
        position: static !important;
        box-shadow: 0 9px 11px -7px rgba(var(--bs-black-rgb), 0.1); }
        .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li + li {
          border-top: 1px solid var(--bs-gray-300); }
        .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a {
          position: relative;
          padding: 0.8rem 1rem;
          --bs-dropdown-link-active-bg: null;
          --bs-dropdown-link-hover-bg: null; }
          .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a:after {
            content: "";
            position: absolute;
            border-top: 10px solid transparent;
            border-right: 10px solid var(--bs-gray-300);
            border-bottom: 10px solid transparent;
            right: 0;
            opacity: 0; }
        .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a {
          color: var(--bs-primary); }
          .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a:after {
            opacity: 1; }
        .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover .vertical-mm-in {
          display: block; }
      .navbar-expand-lg .navbar-nav .vertical-dropdown .vertical-dropdown-menu .vertical-mm-in {
        position: absolute;
        top: 0;
        left: 250px;
        right: 0;
        background: var(--bs-bg-mode-re);
        padding: 25px;
        min-height: 100%;
        display: none;
        pointer-events: auto;
        border-left: 1px solid var(--bs-gray-300);
        box-shadow: 8px 8px 11px -8px rgba(var(--bs-black-rgb), 0.1); } }

@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--bs-body-bg);
    z-index: 11; }
  .navbar-expand-lg .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-lg .navbar-nav .nav-item > .nav-link {
      padding: 15px;
      color: var(--bs-text-mode); }
    .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu {
      background: var(--bs-gray-100);
      margin: 0;
      border-radius: 0; }
      .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu li {
        position: relative; }
        .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu li .mob-menu {
          line-height: 34px;
          height: 34px; }
      .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
        background: var(--bs-gray-100); }
        .navbar-expand-lg .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
          position: relative; }
  .navbar-expand-lg .vertical-dropdown-menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .navbar-expand-lg .vertical-dropdown-menu .vertical-mm-in {
      display: none;
      padding: 20px;
      background: var(--bs-white); }
    .navbar-expand-lg .vertical-dropdown-menu > li .dropdown-item {
      padding: 0.6rem 1rem; }
    .navbar-expand-lg .vertical-dropdown-menu > li .mob-menu {
      line-height: 45px !important;
      height: 45px !important; }
    .navbar-expand-lg .vertical-dropdown-menu > li + li {
      border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-lg .vertical-dropdown-menu > li.open-menu-parent .vertical-mm-in {
      display: block; } }

@media (min-width: 1200px) {
  .navbar-expand-xl .dropdown-mega-menu {
    width: 100%;
    background: var(--bs-body-bg); }
  .navbar-expand-xl > .container {
    position: relative; }
  .navbar-expand-xl .dropdown-menu-md {
    min-width: 22rem; }
  .navbar-expand-xl .dropdown-menu-lg {
    min-width: 35rem; }
  .navbar-expand-xl .dropdown-menu-xl {
    min-width: 42rem; }
  .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f282";
    font-family: bootstrap-icons;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 0.55rem;
    border: none;
    line-height: 10px; }
  .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0; }
    .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
      left: 0;
      transform: translate(0, 0); }
    .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
      right: 0;
      transform: translate(0, 0); }
    .navbar-expand-xl .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
      position: relative; }
  .navbar-expand-xl .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%; }
  .navbar-expand-xl .navbar-nav > .nav-item.dropdown-full {
    position: static; }
  .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0; }
    .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu > .dropdown {
      position: relative; }
      .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
        position: relative; }
        .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
          content: "\f285";
          font-family: bootstrap-icons;
          color: currentColor;
          margin-left: 8px;
          display: inline-block;
          font-size: 0.85rem;
          border: none;
          position: absolute;
          top: 9px;
          right: 7px;
          font-weight: 600; }
      .navbar-expand-xl .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
        display: block; }
  .navbar-expand-xl .navbar-nav > .nav-item:hover > .dropdown-menu {
    display: block; }
  .navbar-expand-xl .navbar-nav > .nav-item .mob-menu {
    display: none; }
  .navbar-expand-xl .navbar-nav > .nav-item .dropdown-item {
    font-size: 0.90rem; }
  .navbar-expand-xl .navbar-nav .vertical-dropdown {
    position: static; }
    .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu {
      position: absolute;
      left: 0;
      z-index: 1;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      background: transparent;
      pointer-events: none;
      border: none; }
      .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li {
        width: 250px;
        background: var(--bs-bg-mode-re);
        pointer-events: auto;
        position: static !important;
        box-shadow: 0 9px 11px -7px rgba(var(--bs-black-rgb), 0.1); }
        .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li + li {
          border-top: 1px solid var(--bs-gray-300); }
        .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a {
          position: relative;
          padding: 0.8rem 1rem;
          --bs-dropdown-link-active-bg: null;
          --bs-dropdown-link-hover-bg: null; }
          .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a:after {
            content: "";
            position: absolute;
            border-top: 10px solid transparent;
            border-right: 10px solid var(--bs-gray-300);
            border-bottom: 10px solid transparent;
            right: 0;
            opacity: 0; }
        .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a {
          color: var(--bs-primary); }
          .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a:after {
            opacity: 1; }
        .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover .vertical-mm-in {
          display: block; }
      .navbar-expand-xl .navbar-nav .vertical-dropdown .vertical-dropdown-menu .vertical-mm-in {
        position: absolute;
        top: 0;
        left: 250px;
        right: 0;
        background: var(--bs-bg-mode-re);
        padding: 25px;
        min-height: 100%;
        display: none;
        pointer-events: auto;
        border-left: 1px solid var(--bs-gray-300);
        box-shadow: 8px 8px 11px -8px rgba(var(--bs-black-rgb), 0.1); } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--bs-body-bg);
    z-index: 11; }
  .navbar-expand-xl .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-xl .navbar-nav .nav-item > .nav-link {
      padding: 15px;
      color: var(--bs-text-mode); }
    .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu {
      background: var(--bs-gray-100);
      margin: 0;
      border-radius: 0; }
      .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu li {
        position: relative; }
        .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu li .mob-menu {
          line-height: 34px;
          height: 34px; }
      .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
        background: var(--bs-gray-100); }
        .navbar-expand-xl .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
          position: relative; }
  .navbar-expand-xl .vertical-dropdown-menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .navbar-expand-xl .vertical-dropdown-menu .vertical-mm-in {
      display: none;
      padding: 20px;
      background: var(--bs-white); }
    .navbar-expand-xl .vertical-dropdown-menu > li .dropdown-item {
      padding: 0.6rem 1rem; }
    .navbar-expand-xl .vertical-dropdown-menu > li .mob-menu {
      line-height: 45px !important;
      height: 45px !important; }
    .navbar-expand-xl .vertical-dropdown-menu > li + li {
      border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-xl .vertical-dropdown-menu > li.open-menu-parent .vertical-mm-in {
      display: block; } }

@media (min-width: 1400px) {
  .navbar-expand-xxl .dropdown-mega-menu {
    width: 100%;
    background: var(--bs-body-bg); }
  .navbar-expand-xxl > .container {
    position: relative; }
  .navbar-expand-xxl .dropdown-menu-md {
    min-width: 22rem; }
  .navbar-expand-xxl .dropdown-menu-lg {
    min-width: 35rem; }
  .navbar-expand-xxl .dropdown-menu-xl {
    min-width: 42rem; }
  .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .nav-link:after {
    content: "\f282";
    font-family: bootstrap-icons;
    color: currentColor;
    margin-left: 8px;
    display: inline-block;
    font-size: 0.55rem;
    border: none;
    line-height: 10px; }
  .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu {
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0; }
    .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
      left: 0;
      transform: translate(0, 0); }
    .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
      right: 0;
      transform: translate(0, 0); }
    .navbar-expand-xxl .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
      position: relative; }
  .navbar-expand-xxl .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
    position: absolute;
    top: 0;
    left: 100%; }
  .navbar-expand-xxl .navbar-nav > .nav-item.dropdown-full {
    position: static; }
  .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu {
    display: none;
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1);
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 0; }
    .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu > .dropdown {
      position: relative; }
      .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
        position: relative; }
        .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
          content: "\f285";
          font-family: bootstrap-icons;
          color: currentColor;
          margin-left: 8px;
          display: inline-block;
          font-size: 0.85rem;
          border: none;
          position: absolute;
          top: 9px;
          right: 7px;
          font-weight: 600; }
      .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
        display: block; }
  .navbar-expand-xxl .navbar-nav > .nav-item:hover > .dropdown-menu {
    display: block; }
  .navbar-expand-xxl .navbar-nav > .nav-item .mob-menu {
    display: none; }
  .navbar-expand-xxl .navbar-nav > .nav-item .dropdown-item {
    font-size: 0.90rem; }
  .navbar-expand-xxl .navbar-nav .vertical-dropdown {
    position: static; }
    .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu {
      position: absolute;
      left: 0;
      z-index: 1;
      margin: 0;
      padding: 0;
      list-style: none;
      width: 100%;
      background: transparent;
      pointer-events: none;
      border: none; }
      .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li {
        width: 250px;
        background: var(--bs-bg-mode-re);
        pointer-events: auto;
        position: static !important;
        box-shadow: 0 9px 11px -7px rgba(var(--bs-black-rgb), 0.1); }
        .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li + li {
          border-top: 1px solid var(--bs-gray-300); }
        .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a {
          position: relative;
          padding: 0.8rem 1rem;
          --bs-dropdown-link-active-bg: null;
          --bs-dropdown-link-hover-bg: null; }
          .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a:after {
            content: "";
            position: absolute;
            border-top: 10px solid transparent;
            border-right: 10px solid var(--bs-gray-300);
            border-bottom: 10px solid transparent;
            right: 0;
            opacity: 0; }
        .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a {
          color: var(--bs-primary); }
          .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a:after {
            opacity: 1; }
        .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover .vertical-mm-in {
          display: block; }
      .navbar-expand-xxl .navbar-nav .vertical-dropdown .vertical-dropdown-menu .vertical-mm-in {
        position: absolute;
        top: 0;
        left: 250px;
        right: 0;
        background: var(--bs-bg-mode-re);
        padding: 25px;
        min-height: 100%;
        display: none;
        pointer-events: auto;
        border-left: 1px solid var(--bs-gray-300);
        box-shadow: 8px 8px 11px -8px rgba(var(--bs-black-rgb), 0.1); } }

@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-collapse {
    max-height: 70vh;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: var(--bs-body-bg);
    z-index: 11; }
  .navbar-expand-xxl .navbar-nav .nav-item {
    margin: 0;
    border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-xxl .navbar-nav .nav-item > .nav-link {
      padding: 15px;
      color: var(--bs-text-mode); }
    .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu {
      background: var(--bs-gray-100);
      margin: 0;
      border-radius: 0; }
      .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu li {
        position: relative; }
        .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu li .mob-menu {
          line-height: 34px;
          height: 34px; }
      .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
        background: var(--bs-gray-100); }
        .navbar-expand-xxl .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
          position: relative; }
  .navbar-expand-xxl .vertical-dropdown-menu {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
    .navbar-expand-xxl .vertical-dropdown-menu .vertical-mm-in {
      display: none;
      padding: 20px;
      background: var(--bs-white); }
    .navbar-expand-xxl .vertical-dropdown-menu > li .dropdown-item {
      padding: 0.6rem 1rem; }
    .navbar-expand-xxl .vertical-dropdown-menu > li .mob-menu {
      line-height: 45px !important;
      height: 45px !important; }
    .navbar-expand-xxl .vertical-dropdown-menu > li + li {
      border-top: 1px solid var(--bs-gray-300); }
    .navbar-expand-xxl .vertical-dropdown-menu > li.open-menu-parent .vertical-mm-in {
      display: block; } }

.navbar-expand .dropdown-mega-menu {
  width: 100%;
  background: var(--bs-body-bg); }

.navbar-expand > .container {
  position: relative; }

.navbar-expand .dropdown-menu-md {
  min-width: 22rem; }

.navbar-expand .dropdown-menu-lg {
  min-width: 35rem; }

.navbar-expand .dropdown-menu-xl {
  min-width: 42rem; }

.navbar-expand .navbar-nav > .nav-item.dropdown > .nav-link:after {
  content: "\f282";
  font-family: bootstrap-icons;
  color: currentColor;
  margin-left: 8px;
  display: inline-block;
  font-size: 0.55rem;
  border: none;
  line-height: 10px; }

.navbar-expand .navbar-nav > .nav-item.dropdown > .dropdown-menu {
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0; }
  .navbar-expand .navbar-nav > .nav-item.dropdown > .dropdown-menu.left {
    left: 0;
    transform: translate(0, 0); }
  .navbar-expand .navbar-nav > .nav-item.dropdown > .dropdown-menu.right {
    right: 0;
    transform: translate(0, 0); }
  .navbar-expand .navbar-nav > .nav-item.dropdown > .dropdown-menu > li {
    position: relative; }

.navbar-expand .navbar-nav > .nav-item.dropdown .dropdown-menu-sub {
  position: absolute;
  top: 0;
  left: 100%; }

.navbar-expand .navbar-nav > .nav-item.dropdown-full {
  position: static; }

.navbar-expand .navbar-nav > .nav-item .dropdown-menu {
  display: none;
  box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-radius: 0; }
  .navbar-expand .navbar-nav > .nav-item .dropdown-menu > .dropdown {
    position: relative; }
    .navbar-expand .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item {
      position: relative; }
      .navbar-expand .navbar-nav > .nav-item .dropdown-menu > .dropdown > .dropdown-item:after {
        content: "\f285";
        font-family: bootstrap-icons;
        color: currentColor;
        margin-left: 8px;
        display: inline-block;
        font-size: 0.85rem;
        border: none;
        position: absolute;
        top: 9px;
        right: 7px;
        font-weight: 600; }
    .navbar-expand .navbar-nav > .nav-item .dropdown-menu > .dropdown:hover .dropdown-menu-sub {
      display: block; }

.navbar-expand .navbar-nav > .nav-item:hover > .dropdown-menu {
  display: block; }

.navbar-expand .navbar-nav > .nav-item .mob-menu {
  display: none; }

.navbar-expand .navbar-nav > .nav-item .dropdown-item {
  font-size: 0.90rem; }

.navbar-expand .navbar-nav .vertical-dropdown {
  position: static; }
  .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu {
    position: absolute;
    left: 0;
    z-index: 1;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    background: transparent;
    pointer-events: none;
    border: none; }
    .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li {
      width: 250px;
      background: var(--bs-bg-mode-re);
      pointer-events: auto;
      position: static !important;
      box-shadow: 0 9px 11px -7px rgba(var(--bs-black-rgb), 0.1); }
      .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li + li {
        border-top: 1px solid var(--bs-gray-300); }
      .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a {
        position: relative;
        padding: 0.8rem 1rem;
        --bs-dropdown-link-active-bg: null;
        --bs-dropdown-link-hover-bg: null; }
        .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li > a:after {
          content: "";
          position: absolute;
          border-top: 10px solid transparent;
          border-right: 10px solid var(--bs-gray-300);
          border-bottom: 10px solid transparent;
          right: 0;
          opacity: 0; }
      .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a {
        color: var(--bs-primary); }
        .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover > a:after {
          opacity: 1; }
      .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu > li:hover .vertical-mm-in {
        display: block; }
    .navbar-expand .navbar-nav .vertical-dropdown .vertical-dropdown-menu .vertical-mm-in {
      position: absolute;
      top: 0;
      left: 250px;
      right: 0;
      background: var(--bs-bg-mode-re);
      padding: 25px;
      min-height: 100%;
      display: none;
      pointer-events: auto;
      border-left: 1px solid var(--bs-gray-300);
      box-shadow: 8px 8px 11px -8px rgba(var(--bs-black-rgb), 0.1); }

.navbar-expand .navbar-collapse {
  max-height: 70vh;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: var(--bs-body-bg);
  z-index: 11; }

.navbar-expand .navbar-nav .nav-item {
  margin: 0;
  border-top: 1px solid var(--bs-gray-300); }
  .navbar-expand .navbar-nav .nav-item > .nav-link {
    padding: 15px;
    color: var(--bs-text-mode); }
  .navbar-expand .navbar-nav .nav-item .dropdown-menu {
    background: var(--bs-gray-100);
    margin: 0;
    border-radius: 0; }
    .navbar-expand .navbar-nav .nav-item .dropdown-menu li {
      position: relative; }
      .navbar-expand .navbar-nav .nav-item .dropdown-menu li .mob-menu {
        line-height: 34px;
        height: 34px; }
    .navbar-expand .navbar-nav .nav-item .dropdown-menu .dropdown-menu {
      background: var(--bs-gray-100); }
      .navbar-expand .navbar-nav .nav-item .dropdown-menu .dropdown-menu li {
        position: relative; }

.navbar-expand .vertical-dropdown-menu {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }
  .navbar-expand .vertical-dropdown-menu .vertical-mm-in {
    display: none;
    padding: 20px;
    background: var(--bs-white); }
  .navbar-expand .vertical-dropdown-menu > li .dropdown-item {
    padding: 0.6rem 1rem; }
  .navbar-expand .vertical-dropdown-menu > li .mob-menu {
    line-height: 45px !important;
    height: 45px !important; }
  .navbar-expand .vertical-dropdown-menu > li + li {
    border-top: 1px solid var(--bs-gray-300); }
  .navbar-expand .vertical-dropdown-menu > li.open-menu-parent .vertical-mm-in {
    display: block; }

.header-option-1 .header-top {
  border-bottom: 1px solid rgba(var(--bs-white-rgb), 0.1); }

@media (max-width: 575.98px) {
  .header-option-1 .navbar-expand-sm {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 576px) {
  .header-option-1 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    line-height: 60px; } }

@media (max-width: 767.98px) {
  .header-option-1 .navbar-expand-md {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 768px) {
  .header-option-1 .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    line-height: 60px; } }

@media (max-width: 991.98px) {
  .header-option-1 .navbar-expand-lg {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 992px) {
  .header-option-1 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    line-height: 60px; } }

@media (max-width: 1199.98px) {
  .header-option-1 .navbar-expand-xl {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 1200px) {
  .header-option-1 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    line-height: 60px; } }

@media (max-width: 1399.98px) {
  .header-option-1 .navbar-expand-xxl {
    padding-top: 10px;
    padding-bottom: 10px; } }

@media (min-width: 1400px) {
  .header-option-1 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    line-height: 60px; } }

.header-option-1 .navbar-expand {
  padding-top: 10px;
  padding-bottom: 10px; }
  .header-option-1 .navbar-expand .navbar-nav > .nav-item > .nav-link {
    line-height: 60px; }

.header-option-1 .header-right .nav-link {
  padding: 0.3rem 0.6rem; }
  .header-option-1 .header-right .nav-link i {
    font-size: 1.3rem; }

.header-option-1 .navbar-toggler {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0; }
  .header-option-1 .navbar-toggler .navbar-toggler-icon {
    width: 1.8em;
    height: 1.8em; }

.header-option-1 .navbar-nav > .nav-item > .nav-link {
  font-weight: 500;
  text-transform: none;
  font-size: 0.95rem; }

.header-option-1 .navbar-nav > .nav-item .mob-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  color: var(--bs-text-mode); }
  .header-option-1 .navbar-nav > .nav-item .mob-menu:after {
    display: none; }
  .header-option-1 .navbar-nav > .nav-item .mob-menu:before {
    content: "\f282";
    font-family: "bootstrap-icons";
    font-weight: 900;
    font-size: 11px;
    color: currentColor; }
  .header-option-1 .navbar-nav > .nav-item .mob-menu.open:before {
    content: "\f286";
    color: var(--bs-primary); }

.header-option-1 .header-social .h-social-link {
  padding: 0 4px;
  font-size: 14px;
  vertical-align: middle; }

.header-option-1 .theme-switch {
  border: none;
  background: transparent;
  outline: none; }

.header-option-2 .header-top {
  border-bottom: 1px solid rgba(var(--bs-white-rgb), 0.2); }

.header-option-2 .navbar {
  padding-top: 10px;
  padding-bottom: 10px; }

.header-option-2 .theme-switch {
  border: none;
  background: transparent;
  outline: none; }

@media (min-width: 576px) {
  .header-option-2 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 768px) {
  .header-option-2 .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 992px) {
  .header-option-2 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 1200px) {
  .header-option-2 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 1400px) {
  .header-option-2 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    line-height: 60px;
    padding-top: 0;
    padding-bottom: 0; } }

.header-option-2 .navbar-expand .navbar-nav > .nav-item > .nav-link {
  line-height: 60px;
  padding-top: 0;
  padding-bottom: 0; }

.header-option-2 .header-right .nav-item + .nav-item {
  margin-left: 15px; }

.header-option-2 .header-right .nav-link {
  padding: 0;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(var(--bs-white-rgb), 0.4);
  border-radius: 50%;
  line-height: 1; }
  .header-option-2 .header-right .nav-link i {
    font-size: 19px; }

.header-option-2 .navbar-toggler {
  padding: 0;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(var(--bs-white-rgb), 0.4);
  border-radius: 50%;
  line-height: 1;
  margin-left: 10px; }
  .header-option-2 .navbar-toggler .navbar-toggler-icon {
    width: 18px;
    height: 18px; }

.header-option-2 .navbar-nav > .nav-item > .nav-link {
  font-weight: 500;
  text-transform: none;
  font-size: 0.95rem; }

.header-option-2 .navbar-nav > .nav-item .mob-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  color: var(--bs-text-mode); }
  .header-option-2 .navbar-nav > .nav-item .mob-menu:after {
    display: none; }
  .header-option-2 .navbar-nav > .nav-item .mob-menu:before {
    content: "\f282";
    font-family: "bootstrap-icons";
    font-weight: 900;
    font-size: 11px;
    color: currentColor; }
  .header-option-2 .navbar-nav > .nav-item .mob-menu.open:before {
    content: "\f286";
    color: var(--bs-primary); }

.header-option-2 .header-social .h-social-link {
  padding: 0 4px;
  font-size: 14px;
  vertical-align: middle; }

.header-option-2.fixed-header .header-right .nav-link {
  border: 1px solid var(--bs-gray-300); }

.header-option-2.fixed-header .navbar-toggler {
  border: 1px solid var(--bs-gray-300); }

.header-option-3 .header-top {
  border-bottom: 1px solid var(--bs-gray-300); }

@media (min-width: 576px) {
  .header-option-3 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    line-height: 50px;
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 768px) {
  .header-option-3 .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    line-height: 50px;
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 992px) {
  .header-option-3 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    line-height: 50px;
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 1200px) {
  .header-option-3 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    line-height: 50px;
    padding-top: 0;
    padding-bottom: 0; } }

@media (min-width: 1400px) {
  .header-option-3 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    line-height: 50px;
    padding-top: 0;
    padding-bottom: 0; } }

.header-option-3 .navbar-expand .navbar-nav > .nav-item > .nav-link {
  line-height: 50px;
  padding-top: 0;
  padding-bottom: 0; }

.header-option-3 .header-right .nav-item + .nav-item {
  margin-left: 15px; }

.header-option-3 .header-right .nav-link {
  padding: 0;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-text-mode);
  border: 1px solid var(--bs-gray-300);
  line-height: 1; }
  .header-option-3 .header-right .nav-link i {
    font-size: 1.1rem; }

.header-option-3 .navbar-toggler {
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0; }
  .header-option-3 .navbar-toggler .navbar-toggler-icon {
    width: 1.8em;
    height: 1.8em; }

.header-option-3 .navbar-nav > .nav-item > .nav-link {
  font-weight: 500;
  text-transform: none;
  font-size: 0.95rem; }

.header-option-3 .navbar-nav > .nav-item .mob-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  color: var(--bs-text-mode); }
  .header-option-3 .navbar-nav > .nav-item .mob-menu:after {
    display: none; }
  .header-option-3 .navbar-nav > .nav-item .mob-menu:before {
    content: "\f282";
    font-family: "bootstrap-icons";
    font-weight: 900;
    font-size: 11px;
    color: currentColor; }
  .header-option-3 .navbar-nav > .nav-item .mob-menu.open:before {
    content: "\f286";
    color: var(--bs-primary); }

.header-option-3 .header-social .h-social-link {
  padding: 0 4px;
  font-size: 14px;
  vertical-align: middle; }

.header-option-3.fixed-header .header-right .nav-link {
  border: 1px solid rgba(var(--bs-dark-rgb), 0.4); }

.header-option-4 {
  transition: ease top 0.35s; }
  .header-option-4 .navbar {
    padding-top: 15px;
    padding-bottom: 15px; }
  @media (min-width: 576px) {
    .header-option-4 .navbar-expand-sm .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-4 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0; } }
  @media (min-width: 768px) {
    .header-option-4 .navbar-expand-md .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-4 .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0; } }
  @media (min-width: 992px) {
    .header-option-4 .navbar-expand-lg .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-4 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0; } }
  @media (min-width: 1200px) {
    .header-option-4 .navbar-expand-xl .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-4 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0; } }
  @media (min-width: 1400px) {
    .header-option-4 .navbar-expand-xxl .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-4 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0; } }
  .header-option-4 .navbar-expand .navbar-nav > .nav-item + .nav-item {
    margin-left: 20px; }
  .header-option-4 .navbar-expand .navbar-nav > .nav-item > .nav-link {
    line-height: 50px;
    padding: 0; }
  .header-option-4 .header-right .nav-item + .nav-item {
    margin-left: 10px; }
  .header-option-4 .header-right .nav-link {
    padding: 0;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--bs-text-mode);
    line-height: 1; }
    .header-option-4 .header-right .nav-link i {
      font-size: 1.6rem; }
  .header-option-4 .navbar-toggler {
    border: none;
    outline: none;
    box-shadow: none;
    padding: 0; }
    .header-option-4 .navbar-toggler .navbar-toggler-icon {
      width: 1.8em;
      height: 1.8em; }
  .header-option-4 .navbar-nav > .nav-item > .nav-link {
    font-weight: 500;
    text-transform: none;
    font-size: 0.95rem; }
  .header-option-4 .navbar-nav > .nav-item .mob-menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 52px;
    text-align: center;
    line-height: 52px;
    color: var(--bs-text-mode); }
    .header-option-4 .navbar-nav > .nav-item .mob-menu:after {
      display: none; }
    .header-option-4 .navbar-nav > .nav-item .mob-menu:before {
      content: "\f282";
      font-family: "bootstrap-icons";
      font-weight: 900;
      font-size: 11px;
      color: currentColor; }
    .header-option-4 .navbar-nav > .nav-item .mob-menu.open:before {
      content: "\f286";
      color: var(--bs-primary); }

.header-option-5 {
  transition: ease top 0.35s; }
  .header-option-5 .header-middle {
    padding-top: 25px;
    padding-bottom: 25px; }
    @media (max-width: 767.98px) {
      .header-option-5 .header-middle {
        padding-top: 15px;
        padding-bottom: 15px; } }
  .header-option-5 .h-search {
    position: relative;
    max-width: 300px; }
    @media (max-width: 767.98px) {
      .header-option-5 .h-search {
        max-width: 100%;
        margin: 15px 0; } }
    .header-option-5 .h-search .form-control {
      padding: 0.45rem 0.65rem;
      font-size: 0.83rem;
      background: none;
      border: 1px solid transparent; }
      .header-option-5 .h-search .form-control:focus {
        border: 1px solid var(--bs-gray-300); }
      @media (max-width: 767.98px) {
        .header-option-5 .h-search .form-control {
          border: 1px solid var(--bs-gray-300); } }
    .header-option-5 .h-search .btn {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 35px;
      height: 35px;
      padding: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center; }
  .header-option-5 .header-right .nav-item + .nav-item {
    margin-left: 10px; }
  .header-option-5 .header-right .nav-link {
    padding: 0;
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--bs-text-mode);
    line-height: 1; }
    .header-option-5 .header-right .nav-link i {
      font-size: 1.6rem; }
  .header-option-5 .navbar-nav > .nav-item > .nav-link {
    font-weight: 500;
    text-transform: none;
    font-size: 0.95rem; }
  .header-option-5 .navbar-nav > .nav-item .mob-menu {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 52px;
    text-align: center;
    line-height: 52px;
    color: var(--bs-text-mode); }
    .header-option-5 .navbar-nav > .nav-item .mob-menu:after {
      display: none; }
    .header-option-5 .navbar-nav > .nav-item .mob-menu:before {
      content: "\f282";
      font-family: "bootstrap-icons";
      font-weight: 900;
      font-size: 11px;
      color: currentColor; }
    .header-option-5 .navbar-nav > .nav-item .mob-menu.open:before {
      content: "\f286";
      color: var(--bs-primary); }
  @media (min-width: 576px) {
    .header-option-5 .navbar-expand-sm {
      border-top: 1px solid var(--bs-gray-300);
      box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
      .header-option-5 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
        line-height: 50px;
        padding: 0 10px;
        position: relative;
        font-size: 14px;
        text-transform: uppercase; }
        .header-option-5 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:before {
          content: "";
          position: absolute;
          left: auto;
          right: 0;
          bottom: 0;
          height: 2px;
          width: 0px;
          background: var(--bs-primary);
          transition: ease width 0.35s; }
      .header-option-5 .navbar-expand-sm .navbar-nav > .nav-item:hover > .nav-link:before {
        left: 0;
        right: auto;
        width: 100%; } }
  @media (min-width: 768px) {
    .header-option-5 .navbar-expand-md {
      border-top: 1px solid var(--bs-gray-300);
      box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
      .header-option-5 .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
        line-height: 50px;
        padding: 0 10px;
        position: relative;
        font-size: 14px;
        text-transform: uppercase; }
        .header-option-5 .navbar-expand-md .navbar-nav > .nav-item > .nav-link:before {
          content: "";
          position: absolute;
          left: auto;
          right: 0;
          bottom: 0;
          height: 2px;
          width: 0px;
          background: var(--bs-primary);
          transition: ease width 0.35s; }
      .header-option-5 .navbar-expand-md .navbar-nav > .nav-item:hover > .nav-link:before {
        left: 0;
        right: auto;
        width: 100%; } }
  @media (min-width: 992px) {
    .header-option-5 .navbar-expand-lg {
      border-top: 1px solid var(--bs-gray-300);
      box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
      .header-option-5 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
        line-height: 50px;
        padding: 0 10px;
        position: relative;
        font-size: 14px;
        text-transform: uppercase; }
        .header-option-5 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:before {
          content: "";
          position: absolute;
          left: auto;
          right: 0;
          bottom: 0;
          height: 2px;
          width: 0px;
          background: var(--bs-primary);
          transition: ease width 0.35s; }
      .header-option-5 .navbar-expand-lg .navbar-nav > .nav-item:hover > .nav-link:before {
        left: 0;
        right: auto;
        width: 100%; } }
  @media (min-width: 1200px) {
    .header-option-5 .navbar-expand-xl {
      border-top: 1px solid var(--bs-gray-300);
      box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
      .header-option-5 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
        line-height: 50px;
        padding: 0 10px;
        position: relative;
        font-size: 14px;
        text-transform: uppercase; }
        .header-option-5 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:before {
          content: "";
          position: absolute;
          left: auto;
          right: 0;
          bottom: 0;
          height: 2px;
          width: 0px;
          background: var(--bs-primary);
          transition: ease width 0.35s; }
      .header-option-5 .navbar-expand-xl .navbar-nav > .nav-item:hover > .nav-link:before {
        left: 0;
        right: auto;
        width: 100%; } }
  @media (min-width: 1400px) {
    .header-option-5 .navbar-expand-xxl {
      border-top: 1px solid var(--bs-gray-300);
      box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
      .header-option-5 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
        line-height: 50px;
        padding: 0 10px;
        position: relative;
        font-size: 14px;
        text-transform: uppercase; }
        .header-option-5 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:before {
          content: "";
          position: absolute;
          left: auto;
          right: 0;
          bottom: 0;
          height: 2px;
          width: 0px;
          background: var(--bs-primary);
          transition: ease width 0.35s; }
      .header-option-5 .navbar-expand-xxl .navbar-nav > .nav-item:hover > .nav-link:before {
        left: 0;
        right: auto;
        width: 100%; } }
  .header-option-5 .navbar-expand {
    border-top: 1px solid var(--bs-gray-300);
    box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
    .header-option-5 .navbar-expand .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0 10px;
      position: relative;
      font-size: 14px;
      text-transform: uppercase; }
      .header-option-5 .navbar-expand .navbar-nav > .nav-item > .nav-link:before {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 0px;
        background: var(--bs-primary);
        transition: ease width 0.35s; }
    .header-option-5 .navbar-expand .navbar-nav > .nav-item:hover > .nav-link:before {
      left: 0;
      right: auto;
      width: 100%; }

.header-option-6 .header-middle {
  padding-top: 20px;
  padding-bottom: 20px;
  background: var(--bs-primary);
  position: relative; }
  @media (max-width: 767.98px) {
    .header-option-6 .header-middle {
      padding-bottom: 0; } }

.header-option-6 .h-search form {
  position: relative; }

.header-option-6 .h-search .form-control {
  background: var(--bs-white);
  padding: 0.55rem 1.25rem;
  font-size: 0.83rem;
  border-radius: 30px;
  border: none; }

.header-option-6 .h-search .btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  bottom: 0;
  margin: auto;
  width: 50px;
  height: 42px;
  border-radius: 30px;
  border: none;
  color: var(--bs-primary); }

@media (max-width: 991.98px) {
  .header-option-6 .h-search {
    position: absolute;
    top: 100%;
    background: var(--bs-white);
    box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06);
    z-index: 11; }
    .header-option-6 .h-search form {
      padding: 20px 0; }
    .header-option-6 .h-search .form-control {
      border: 1px solid var(--bs-gray-300); } }

@media (min-width: 992px) {
  .header-option-6 [data-cart-items]:before {
    top: -6px;
    right: 2px; } }

@media (max-width: 767.98px) {
  .header-option-6 .header-right {
    background: rgba(0, 0, 0, 0.2);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20px; } }

.header-option-6 .header-right .h-switch .theme-switch > span.bi {
  line-height: 1;
  height: 26px; }

.header-option-6 .header-right .nav-item + .nav-item {
  padding-left: 20px; }

.header-option-6 .header-right .nav-item > .nav-link {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--bs-white); }
  .header-option-6 .header-right .nav-item > .nav-link i {
    font-size: 26px;
    line-height: 1; }
  .header-option-6 .header-right .nav-item > .nav-link span {
    font-size: 12px;
    text-transform: uppercase; }

.header-option-6 .navbar-toggler {
  border: none;
  background: no-repeat;
  height: 30px;
  width: 40px;
  position: relative; }

.header-option-6 .h-phone a {
  background: #ff9736;
  padding: 8px 18px;
  border-radius: 20px;
  color: var(--bs-white);
  text-decoration: none;
  transition: ease all 0.35s; }
  .header-option-6 .h-phone a:hover {
    background: var(--bs-primary); }

.header-option-6 .navbar-nav > .nav-item > .nav-link {
  font-weight: 500;
  text-transform: none;
  font-size: 0.95rem; }

.header-option-6 .navbar-nav > .nav-item .mob-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  color: var(--bs-text-mode); }
  .header-option-6 .navbar-nav > .nav-item .mob-menu:after {
    display: none; }
  .header-option-6 .navbar-nav > .nav-item .mob-menu:before {
    content: "\f282";
    font-family: "bootstrap-icons";
    font-weight: 900;
    font-size: 11px;
    color: currentColor; }
  .header-option-6 .navbar-nav > .nav-item .mob-menu.open:before {
    content: "\f286";
    color: var(--bs-primary); }

@media (min-width: 576px) {
  .header-option-6 .navbar-expand-sm {
    box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
    .header-option-6 .navbar-expand-sm .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-6 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0;
      position: relative;
      font-size: 14px;
      text-transform: uppercase; }
      .header-option-6 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:before {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 0px;
        background: var(--bs-primary);
        transition: ease width 0.35s; }
    .header-option-6 .navbar-expand-sm .navbar-nav > .nav-item:hover > .nav-link:before {
      left: 0;
      right: auto;
      width: 100%; } }

@media (min-width: 768px) {
  .header-option-6 .navbar-expand-md {
    box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
    .header-option-6 .navbar-expand-md .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-6 .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0;
      position: relative;
      font-size: 14px;
      text-transform: uppercase; }
      .header-option-6 .navbar-expand-md .navbar-nav > .nav-item > .nav-link:before {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 0px;
        background: var(--bs-primary);
        transition: ease width 0.35s; }
    .header-option-6 .navbar-expand-md .navbar-nav > .nav-item:hover > .nav-link:before {
      left: 0;
      right: auto;
      width: 100%; } }

@media (min-width: 992px) {
  .header-option-6 .navbar-expand-lg {
    box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
    .header-option-6 .navbar-expand-lg .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-6 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0;
      position: relative;
      font-size: 14px;
      text-transform: uppercase; }
      .header-option-6 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:before {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 0px;
        background: var(--bs-primary);
        transition: ease width 0.35s; }
    .header-option-6 .navbar-expand-lg .navbar-nav > .nav-item:hover > .nav-link:before {
      left: 0;
      right: auto;
      width: 100%; } }

@media (min-width: 1200px) {
  .header-option-6 .navbar-expand-xl {
    box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
    .header-option-6 .navbar-expand-xl .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-6 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0;
      position: relative;
      font-size: 14px;
      text-transform: uppercase; }
      .header-option-6 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:before {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 0px;
        background: var(--bs-primary);
        transition: ease width 0.35s; }
    .header-option-6 .navbar-expand-xl .navbar-nav > .nav-item:hover > .nav-link:before {
      left: 0;
      right: auto;
      width: 100%; } }

@media (min-width: 1400px) {
  .header-option-6 .navbar-expand-xxl {
    box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
    .header-option-6 .navbar-expand-xxl .navbar-nav > .nav-item + .nav-item {
      margin-left: 20px; }
    .header-option-6 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0;
      position: relative;
      font-size: 14px;
      text-transform: uppercase; }
      .header-option-6 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:before {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        bottom: 0;
        height: 2px;
        width: 0px;
        background: var(--bs-primary);
        transition: ease width 0.35s; }
    .header-option-6 .navbar-expand-xxl .navbar-nav > .nav-item:hover > .nav-link:before {
      left: 0;
      right: auto;
      width: 100%; } }

.header-option-6 .navbar-expand {
  box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
  .header-option-6 .navbar-expand .navbar-nav > .nav-item + .nav-item {
    margin-left: 20px; }
  .header-option-6 .navbar-expand .navbar-nav > .nav-item > .nav-link {
    line-height: 50px;
    padding: 0;
    position: relative;
    font-size: 14px;
    text-transform: uppercase; }
    .header-option-6 .navbar-expand .navbar-nav > .nav-item > .nav-link:before {
      content: "";
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      height: 2px;
      width: 0px;
      background: var(--bs-primary);
      transition: ease width 0.35s; }
  .header-option-6 .navbar-expand .navbar-nav > .nav-item:hover > .nav-link:before {
    left: 0;
    right: auto;
    width: 100%; }

@media (min-width: 1200px) {
  .header-option-9 .container-fluid, .header-option-9 .container-sm, .header-option-9 .container-md, .header-option-9 .container-lg, .header-option-9 .container-xl, .header-option-9 .container-xxl {
    padding-left: 40px;
    padding-right: 40px; } }

.header-option-9 .header-top {
  line-height: normal;
  border-bottom: 1px solid var(--bs-gray-300); }
  .header-option-9 .header-top .dropdown-toggle {
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 11px;
    background: none;
    border: none; }
  .header-option-9 .header-top p {
    font-size: 11px;
    margin: 0;
    line-height: normal;
    padding: 3px 0 0;
    opacity: 0.9; }

.header-option-9 .header-middle {
  border-bottom: 1px solid var(--bs-gray-300); }

.header-option-9 .hm-nav-left .nav-item .nav-link {
  line-height: 60px;
  padding: 0 10px;
  color: var(--bs-text-mode);
  position: relative; }
  .header-option-9 .hm-nav-left .nav-item .nav-link:after {
    content: "";
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    height: 1px;
    background: currentColor;
    width: 0;
    transition: ease all 0.35s; }
  .header-option-9 .hm-nav-left .nav-item .nav-link.active, .header-option-9 .hm-nav-left .nav-item .nav-link:hover {
    color: var(--bs-text-mode); }
    .header-option-9 .hm-nav-left .nav-item .nav-link.active:after, .header-option-9 .hm-nav-left .nav-item .nav-link:hover:after {
      width: 100%;
      left: 0;
      right: auto; }

.header-option-9 .header-right {
  justify-content: flex-end; }
  .header-option-9 .header-right .nav-link {
    padding: 0;
    height: 40px;
    width: 40px;
    font-size: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--bs-text-mode); }
    .header-option-9 .header-right .nav-link sub {
      position: absolute;
      top: 1px;
      right: 1px;
      font-size: 9px;
      min-width: 18px;
      height: 18px;
      background: var(--bs-primary);
      line-height: 1;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: var(--bs-white); }

.header-option-9 .navbar {
  border-bottom: 1px solid var(--bs-gray-300); }
  .header-option-9 .navbar .navbar-nav {
    flex-direction: row;
    justify-content: center; }
    .header-option-9 .navbar .navbar-nav > .nav-item > .nav-link {
      line-height: 50px;
      padding: 0 15px;
      color: var(--bs-text-mode);
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 600;
      letter-spacing: 1px;
      position: relative; }
      .header-option-9 .navbar .navbar-nav > .nav-item > .nav-link:before {
        content: "";
        position: absolute;
        left: auto;
        right: 0;
        bottom: -1px;
        height: 1px;
        background: currentColor;
        width: 0;
        transition: ease all 0.35s; }
    .header-option-9 .navbar .navbar-nav > .nav-item.active > .nav-link, .header-option-9 .navbar .navbar-nav > .nav-item:hover > .nav-link {
      color: var(--bs-primary); }
      .header-option-9 .navbar .navbar-nav > .nav-item.active > .nav-link:before, .header-option-9 .navbar .navbar-nav > .nav-item:hover > .nav-link:before {
        left: 0;
        right: auto;
        width: 100%; }
    .header-option-9 .navbar .navbar-nav > .nav-item.dropdown-full {
      position: static; }
    .header-option-9 .navbar .navbar-nav > .nav-item.dropdown > .nav-link:after {
      content: "\f282";
      font-family: bootstrap-icons;
      font-size: 0.5rem;
      margin-left: 0.35rem;
      font-weight: 900;
      vertical-align: middle;
      border: none;
      position: relative;
      top: -2px; }
    .header-option-9 .navbar .navbar-nav > .nav-item.dropdown:hover .dropdown-menu {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: calc(100% + 1px);
      border: none; }

.header-option-9 .hm-toggle-mob {
  width: 40px;
  height: 40px;
  border: none;
  background: none;
  outline: none;
  position: relative; }
  .header-option-9 .hm-toggle-mob span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--bs-text-mode);
    box-shadow: 0 -7px 0 var(--bs-text-mode), 0 7px 0 var(--bs-text-mode);
    width: 24px;
    height: 2px;
    margin: auto; }

@media (max-width: 991.98px) {
  .header-option-9 .header-middle {
    padding-top: 15px;
    padding-bottom: 15px; }
    .header-option-9 .header-middle .hm-left-col {
      flex: 0 0 110px;
      max-width: 110px; }
    .header-option-9 .header-middle .hm-right-col {
      flex: 0 0 110px;
      max-width: 110px; }
    .header-option-9 .header-middle .hm-logo {
      display: flex; } }

.header-option-10.fixed-header {
  box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
  .header-option-10.fixed-header .header-middle {
    padding-top: 8px;
    padding-bottom: 8px; }

.header-option-10 .header-middle {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
  border-bottom: 1px solid var(--bs-gray-300);
  transition: ease padding 0.35s; }
  @media (max-width: 767.98px) {
    .header-option-10 .header-middle {
      padding-bottom: 15px;
      border-bottom: 1px none; } }

.header-option-10 .h-search form {
  position: relative; }

.header-option-10 .h-search .form-control {
  padding: 0.5rem 1.1rem;
  font-size: 0.9rem;
  border: 1px solid var(--bs-gray-300);
  border-radius: 3px; }

.header-option-10 .h-search .btn {
  position: absolute;
  top: 4px;
  right: 4px;
  padding: 0px 10px;
  bottom: 4px;
  margin: auto;
  border: none;
  color: var(--bs-text-mode);
  font-size: 18px;
  background: var(--bs-gray-200);
  border-radius: 3px; }

@media (max-width: 991.98px) {
  .header-option-10 .h-search {
    position: absolute;
    top: 100%;
    background: var(--bs-bg-mode-re);
    border-top: 1px solid var(--bs-gray-300);
    z-index: 11; }
    .header-option-10 .h-search form {
      margin: 10px 0; }
    .header-option-10 .h-search .form-control {
      border: 1px solid var(--bs-gray-300); } }

@media (min-width: 992px) {
  .header-option-10 [data-cart-items]:before {
    top: -6px;
    right: 2px; } }

@media (max-width: 767.98px) {
  .header-option-10 .header-right {
    padding-top: 15px; } }

.header-option-10 .header-right .nav-item + .nav-item {
  padding-left: 12px; }

.header-option-10 .header-right .nav-item > .nav-link {
  padding: 0;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bs-gray-300);
  color: var(--bs-text-mode);
  border-radius: 3px; }
  .header-option-10 .header-right .nav-item > .nav-link i {
    font-size: 18px;
    line-height: 1; }
  .header-option-10 .header-right .nav-item > .nav-link span {
    font-size: 12px;
    text-transform: uppercase; }

.header-option-10 .navbar-toggler {
  border: none;
  background: no-repeat;
  height: 40px;
  width: 40px;
  position: relative;
  border: 1px solid var(--bs-gray-300);
  border-radius: 3px; }
  .header-option-10 .navbar-toggler span {
    background: var(--bs-dark);
    width: 60%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    box-shadow: 0 8px 0 0 var(--bs-dark), 0 -8px 0 0 var(--bs-dark); }

.header-option-10 .navbar-nav > .nav-item > .nav-link {
  font-weight: 500;
  text-transform: none;
  font-size: 0.95rem; }

.header-option-10 .navbar-nav > .nav-item .mob-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 52px;
  text-align: center;
  line-height: 52px;
  color: var(--bs-text-mode); }
  .header-option-10 .navbar-nav > .nav-item .mob-menu:after {
    display: none; }
  .header-option-10 .navbar-nav > .nav-item .mob-menu:before {
    content: "\f282";
    font-family: "bootstrap-icons";
    font-weight: 900;
    font-size: 11px;
    color: currentColor; }
  .header-option-10 .navbar-nav > .nav-item .mob-menu.open:before {
    content: "\f286";
    color: var(--bs-primary); }

.header-option-10 .navbar {
  box-shadow: none !important; }

@media (min-width: 576px) {
  .header-option-10 .navbar-expand-sm .dropdown-menu {
    border-top: 1px solid var(--bs-gray-300); }
  .header-option-10 .navbar-expand-sm .navbar-collapse {
    position: relative; }
  .header-option-10 .navbar-expand-sm .navbar-nav > .nav-item + .nav-item {
    margin-left: 20px; }
  .header-option-10 .navbar-expand-sm .navbar-nav > .nav-item.vertical-dropdown {
    background: var(--bs-primary); }
    .header-option-10 .navbar-expand-sm .navbar-nav > .nav-item.vertical-dropdown > .nav-link {
      color: var(--bs-white);
      padding: 0 18px; }
  .header-option-10 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    line-height: 45px;
    padding: 0;
    position: relative;
    font-size: 14px;
    font-weight: 500; }
    .header-option-10 .navbar-expand-sm .navbar-nav > .nav-item > .nav-link:before {
      content: "";
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      height: 2px;
      width: 0px;
      background: var(--bs-primary);
      transition: ease width 0.35s; }
  .header-option-10 .navbar-expand-sm .navbar-nav > .nav-item:hover > .nav-link:before {
    left: 0;
    right: auto;
    width: 100%; } }

@media (min-width: 768px) {
  .header-option-10 .navbar-expand-md .dropdown-menu {
    border-top: 1px solid var(--bs-gray-300); }
  .header-option-10 .navbar-expand-md .navbar-collapse {
    position: relative; }
  .header-option-10 .navbar-expand-md .navbar-nav > .nav-item + .nav-item {
    margin-left: 20px; }
  .header-option-10 .navbar-expand-md .navbar-nav > .nav-item.vertical-dropdown {
    background: var(--bs-primary); }
    .header-option-10 .navbar-expand-md .navbar-nav > .nav-item.vertical-dropdown > .nav-link {
      color: var(--bs-white);
      padding: 0 18px; }
  .header-option-10 .navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    line-height: 45px;
    padding: 0;
    position: relative;
    font-size: 14px;
    font-weight: 500; }
    .header-option-10 .navbar-expand-md .navbar-nav > .nav-item > .nav-link:before {
      content: "";
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      height: 2px;
      width: 0px;
      background: var(--bs-primary);
      transition: ease width 0.35s; }
  .header-option-10 .navbar-expand-md .navbar-nav > .nav-item:hover > .nav-link:before {
    left: 0;
    right: auto;
    width: 100%; } }

@media (min-width: 992px) {
  .header-option-10 .navbar-expand-lg .dropdown-menu {
    border-top: 1px solid var(--bs-gray-300); }
  .header-option-10 .navbar-expand-lg .navbar-collapse {
    position: relative; }
  .header-option-10 .navbar-expand-lg .navbar-nav > .nav-item + .nav-item {
    margin-left: 20px; }
  .header-option-10 .navbar-expand-lg .navbar-nav > .nav-item.vertical-dropdown {
    background: var(--bs-primary); }
    .header-option-10 .navbar-expand-lg .navbar-nav > .nav-item.vertical-dropdown > .nav-link {
      color: var(--bs-white);
      padding: 0 18px; }
  .header-option-10 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    line-height: 45px;
    padding: 0;
    position: relative;
    font-size: 14px;
    font-weight: 500; }
    .header-option-10 .navbar-expand-lg .navbar-nav > .nav-item > .nav-link:before {
      content: "";
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      height: 2px;
      width: 0px;
      background: var(--bs-primary);
      transition: ease width 0.35s; }
  .header-option-10 .navbar-expand-lg .navbar-nav > .nav-item:hover > .nav-link:before {
    left: 0;
    right: auto;
    width: 100%; } }

@media (min-width: 1200px) {
  .header-option-10 .navbar-expand-xl .dropdown-menu {
    border-top: 1px solid var(--bs-gray-300); }
  .header-option-10 .navbar-expand-xl .navbar-collapse {
    position: relative; }
  .header-option-10 .navbar-expand-xl .navbar-nav > .nav-item + .nav-item {
    margin-left: 20px; }
  .header-option-10 .navbar-expand-xl .navbar-nav > .nav-item.vertical-dropdown {
    background: var(--bs-primary); }
    .header-option-10 .navbar-expand-xl .navbar-nav > .nav-item.vertical-dropdown > .nav-link {
      color: var(--bs-white);
      padding: 0 18px; }
  .header-option-10 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    line-height: 45px;
    padding: 0;
    position: relative;
    font-size: 14px;
    font-weight: 500; }
    .header-option-10 .navbar-expand-xl .navbar-nav > .nav-item > .nav-link:before {
      content: "";
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      height: 2px;
      width: 0px;
      background: var(--bs-primary);
      transition: ease width 0.35s; }
  .header-option-10 .navbar-expand-xl .navbar-nav > .nav-item:hover > .nav-link:before {
    left: 0;
    right: auto;
    width: 100%; } }

@media (min-width: 1400px) {
  .header-option-10 .navbar-expand-xxl .dropdown-menu {
    border-top: 1px solid var(--bs-gray-300); }
  .header-option-10 .navbar-expand-xxl .navbar-collapse {
    position: relative; }
  .header-option-10 .navbar-expand-xxl .navbar-nav > .nav-item + .nav-item {
    margin-left: 20px; }
  .header-option-10 .navbar-expand-xxl .navbar-nav > .nav-item.vertical-dropdown {
    background: var(--bs-primary); }
    .header-option-10 .navbar-expand-xxl .navbar-nav > .nav-item.vertical-dropdown > .nav-link {
      color: var(--bs-white);
      padding: 0 18px; }
  .header-option-10 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    line-height: 45px;
    padding: 0;
    position: relative;
    font-size: 14px;
    font-weight: 500; }
    .header-option-10 .navbar-expand-xxl .navbar-nav > .nav-item > .nav-link:before {
      content: "";
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      height: 2px;
      width: 0px;
      background: var(--bs-primary);
      transition: ease width 0.35s; }
  .header-option-10 .navbar-expand-xxl .navbar-nav > .nav-item:hover > .nav-link:before {
    left: 0;
    right: auto;
    width: 100%; } }

.header-option-10 .navbar-expand .dropdown-menu {
  border-top: 1px solid var(--bs-gray-300); }

.header-option-10 .navbar-expand .navbar-collapse {
  position: relative; }

.header-option-10 .navbar-expand .navbar-nav > .nav-item + .nav-item {
  margin-left: 20px; }

.header-option-10 .navbar-expand .navbar-nav > .nav-item.vertical-dropdown {
  background: var(--bs-primary); }
  .header-option-10 .navbar-expand .navbar-nav > .nav-item.vertical-dropdown > .nav-link {
    color: var(--bs-white);
    padding: 0 18px; }

.header-option-10 .navbar-expand .navbar-nav > .nav-item > .nav-link {
  line-height: 45px;
  padding: 0;
  position: relative;
  font-size: 14px;
  font-weight: 500; }
  .header-option-10 .navbar-expand .navbar-nav > .nav-item > .nav-link:before {
    content: "";
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    height: 2px;
    width: 0px;
    background: var(--bs-primary);
    transition: ease width 0.35s; }

.header-option-10 .navbar-expand .navbar-nav > .nav-item:hover > .nav-link:before {
  left: 0;
  right: auto;
  width: 100%; }

.tabs-main-mobile-menu {
  max-width: 300px; }
  .tabs-main-mobile-menu .btn-close {
    position: fixed;
    top: 0;
    left: 300px;
    opacity: 1;
    z-index: 1;
    background: var(--bs-body-bg);
    color: var(--bs-text-mode);
    padding: 0;
    line-height: 1;
    font-size: 26px;
    width: 35px;
    height: 35px; }
  .tabs-main-mobile-menu .modal-body {
    padding: 0; }
  .tabs-main-mobile-menu .header-search {
    border-bottom: 1px solid var(--bs-gray-300);
    padding: 12px; }
  .tabs-main-mobile-menu .nav-tabs {
    border: none;
    background: var(--bs-gray-200); }
    .tabs-main-mobile-menu .nav-tabs .nav-item {
      flex: 1; }
      .tabs-main-mobile-menu .nav-tabs .nav-item .nav-link {
        width: 100%;
        border: none;
        margin: 0;
        font-weight: 600;
        color: var(--bs-text-mode);
        padding: 10px;
        text-transform: uppercase;
        font-size: 14px; }
  .tabs-main-mobile-menu .tab-content .tab-pane {
    padding: 15px; }
  .tabs-main-mobile-menu .mob-menu-link-top {
    margin-bottom: 15px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--bs-gray-300); }
    .tabs-main-mobile-menu .mob-menu-link-top ul {
      margin: 0; }
      .tabs-main-mobile-menu .mob-menu-link-top ul li + li {
        padding-top: 10px; }
      .tabs-main-mobile-menu .mob-menu-link-top ul a {
        color: var(--bs-text-mode);
        font-size: 16px;
        font-weight: 600; }
  .tabs-main-mobile-menu .mob-menu-link-main li {
    position: relative; }
    .tabs-main-mobile-menu .mob-menu-link-main li a {
      color: var(--bs-text-mode);
      display: inline-flex;
      font-weight: 400;
      font-size: 14px; }
    .tabs-main-mobile-menu .mob-menu-link-main li .mob-menu {
      position: absolute;
      top: 8px;
      right: 0; }
      .tabs-main-mobile-menu .mob-menu-link-main li .mob-menu.open i:before {
        content: "\f286"; }
  .tabs-main-mobile-menu .mob-menu-link-main .mob-menu-level-1 > li + li {
    border-top: 1px solid var(--bs-gray-300); }
  .tabs-main-mobile-menu .mob-menu-link-main .mob-menu-level-1 > li > a {
    padding: 8px 0; }
  .tabs-main-mobile-menu .mob-menu-link-main .mob-menu-level-1 > li.open-menu-parent > a {
    color: var(--bs-primary); }
  .tabs-main-mobile-menu .mob-menu-link-main .mob-menu-level-2 {
    position: relative;
    margin: 0;
    padding: 0 0 10px 15px;
    width: 100%; }
    .tabs-main-mobile-menu .mob-menu-link-main .mob-menu-level-2 a {
      padding: 5px 0; }

.footer-border-top {
  border-top: 1px solid rgba(var(--bs-black-rgb), 0.1); }
  .footer-border-top.light {
    border-top: 1px solid rgba(var(--bs-white-rgb), 0.1); }

.footer-link-3 li a {
  position: relative; }
  .footer-link-3 li a:after {
    content: "";
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    height: 1px;
    background: currentColor;
    width: 0;
    transition: ease all 0.35s; }
  .footer-link-3 li a:hover {
    color: var(--bs-primary); }
    .footer-link-3 li a:hover:after {
      left: 0;
      right: auto;
      width: 100%; }

.footer-link-2 li {
  display: inline-block;
  margin-bottom: 5px;
  position: relative;
  vertical-align: top;
  padding: 0 10px; }
  .footer-link-2 li + li:after {
    background: var(--bs-gray-600);
    content: "";
    height: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    margin: auto;
    top: 2px;
    width: 1px; }

body,
html {
  width: 100%;
  height: 100%; }

.wrapper {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  min-height: 100%;
  position: relative; }
  .wrapper > * {
    flex: 0 0 auto; }
  .wrapper > main {
    flex: 1 0 auto; }

.btn-mode {
  --bs-btn-bg: var(--bs-emphasis-color);
  --bs-btn-color: var(--bs-tertiary-bg); }
  .btn-mode.active, .btn-mode:focus, .btn-mode:active, .btn-mode:hover {
    background: var(--bs-primary);
    color: var(--bs-white); }

.btn-outline-mode {
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: var(--bs-emphasis-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-bg: var(--bs-body-bg);
  --bs-btn-active-color:var(--bs-white);
  --bs-btn-active-border-color:transparent;
  color: var(--bs-mode); }
  .btn-outline-mode.active, .btn-outline-mode:focus, .btn-outline-mode:active, .btn-outline-mode:hover {
    background: var(--bs-primary) !important;
    border-color: transparent !important;
    color: var(--bs-white) !important; }

.btn .btn-icon:first-child {
  padding-right: 0.5rem; }

.btn .btn-icon:last-child {
  padding-left: 0.5rem; }

.btn-shadow:hover, .btn-shadow:focus, .btn-shadow:active, .btn-shadow.active, .btn-shadow.show {
  box-shadow: 0 0.25rem 0.25rem 0.125rem rgba(var(--bs-btn-focus-shadow-rgb), 0.1), 0 0.375rem 0.75rem -0.125rem rgba(var(--bs-btn-focus-shadow-rgb), 0.4); }

.btn-primary-soft {
  --bs-btn-color: var(--bs-primary);
  --bs-btn-bg: rgba(var(--bs-primary-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-primary);
  --bs-btn-hover-border-color: var(--bs-primary);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-primary-rgb);
  --bs-btn-active-border-color: var(--bs-primary);
  --bs-btn-disabled-color: var(--bs-primary);
  --bs-btn-disabled-bg: rgba(var(--bs-primary-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-primary-rgb), 0.1); }

.btn-secondary-soft {
  --bs-btn-color: var(--bs-secondary);
  --bs-btn-bg: rgba(var(--bs-secondary-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-secondary);
  --bs-btn-hover-border-color: var(--bs-secondary);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-secondary);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-secondary-rgb);
  --bs-btn-active-border-color: var(--bs-secondary);
  --bs-btn-disabled-color: var(--bs-secondary);
  --bs-btn-disabled-bg: rgba(var(--bs-secondary-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-secondary-rgb), 0.1); }

.btn-success-soft {
  --bs-btn-color: var(--bs-success);
  --bs-btn-bg: rgba(var(--bs-success-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-success);
  --bs-btn-hover-border-color: var(--bs-success);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-success);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-success-rgb);
  --bs-btn-active-border-color: var(--bs-success);
  --bs-btn-disabled-color: var(--bs-success);
  --bs-btn-disabled-bg: rgba(var(--bs-success-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-success-rgb), 0.1); }

.btn-info-soft {
  --bs-btn-color: var(--bs-info);
  --bs-btn-bg: rgba(var(--bs-info-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-info);
  --bs-btn-hover-border-color: var(--bs-info);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-info);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-info-rgb);
  --bs-btn-active-border-color: var(--bs-info);
  --bs-btn-disabled-color: var(--bs-info);
  --bs-btn-disabled-bg: rgba(var(--bs-info-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-info-rgb), 0.1); }

.btn-warning-soft {
  --bs-btn-color: var(--bs-warning);
  --bs-btn-bg: rgba(var(--bs-warning-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-warning);
  --bs-btn-hover-border-color: var(--bs-warning);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-warning);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-warning-rgb);
  --bs-btn-active-border-color: var(--bs-warning);
  --bs-btn-disabled-color: var(--bs-warning);
  --bs-btn-disabled-bg: rgba(var(--bs-warning-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-warning-rgb), 0.1); }

.btn-danger-soft {
  --bs-btn-color: var(--bs-danger);
  --bs-btn-bg: rgba(var(--bs-danger-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-danger);
  --bs-btn-hover-border-color: var(--bs-danger);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-danger);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-danger-rgb);
  --bs-btn-active-border-color: var(--bs-danger);
  --bs-btn-disabled-color: var(--bs-danger);
  --bs-btn-disabled-bg: rgba(var(--bs-danger-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-danger-rgb), 0.1); }

.btn-light-soft {
  --bs-btn-color: var(--bs-light);
  --bs-btn-bg: rgba(var(--bs-light-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-light);
  --bs-btn-hover-border-color: var(--bs-light);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-light);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-light-rgb);
  --bs-btn-active-border-color: var(--bs-light);
  --bs-btn-disabled-color: var(--bs-light);
  --bs-btn-disabled-bg: rgba(var(--bs-light-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-light-rgb), 0.1); }

.btn-white-soft {
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: rgba(var(--bs-white-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-white);
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-white);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-white-rgb);
  --bs-btn-active-border-color: var(--bs-white);
  --bs-btn-disabled-color: var(--bs-white);
  --bs-btn-disabled-bg: rgba(var(--bs-white-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-white-rgb), 0.1); }

.btn-black-soft {
  --bs-btn-color: var(--bs-black);
  --bs-btn-bg: rgba(var(--bs-black-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-black);
  --bs-btn-hover-border-color: var(--bs-black);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-black);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-black-rgb);
  --bs-btn-active-border-color: var(--bs-black);
  --bs-btn-disabled-color: var(--bs-black);
  --bs-btn-disabled-bg: rgba(var(--bs-black-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-black-rgb), 0.1); }

.btn-dark-soft {
  --bs-btn-color: var(--bs-dark);
  --bs-btn-bg: rgba(var(--bs-dark-rgb), 0.1);
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: var(--bs-dark);
  --bs-btn-hover-border-color: var(--bs-dark);
  --bs-btn-active-color: var(--bs-white);
  --bs-btn-active-bg: var(--bs-dark);
  --bs-btn-active-shadow: unset;
  --bs-btn-focus-shadow-rgb: var(--bs-dark-rgb);
  --bs-btn-active-border-color: var(--bs-dark);
  --bs-btn-disabled-color: var(--bs-dark);
  --bs-btn-disabled-bg: rgba(var(--bs-dark-rgb), 0.1);
  --bs-btn-disabled-border-color: rgba(var(--bs-dark-rgb), 0.1); }

.btn-slide {
  position: relative;
  background-color: transparent !important;
  overflow: hidden; }
  .btn-slide .btn-slide-bg {
    position: absolute;
    transition: ease all 0.35s; }
  .btn-slide .btn-slide-text {
    position: relative; }

.btn-slide-bottom .btn-slide-bg {
  top: 0;
  bottom: auto;
  height: 0px;
  width: 100%;
  left: 0;
  right: 0; }

.btn-slide-bottom:hover .btn-slide-bg {
  top: auto;
  bottom: 0;
  height: 100%; }

.btn-slide-top .btn-slide-bg {
  top: auto;
  bottom: 0;
  height: 0px;
  width: 100%;
  left: 0;
  right: 0; }

.btn-slide-top:hover .btn-slide-bg {
  top: 0;
  bottom: auto;
  height: 100%; }

.btn-slide-left .btn-slide-bg {
  height: 100%;
  width: 0;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0; }

.btn-slide-left:hover .btn-slide-bg {
  left: 0;
  right: auto;
  width: 100%; }

.btn-slide-right .btn-slide-bg {
  height: 100%;
  width: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: auto; }

.btn-slide-right:hover .btn-slide-bg {
  left: auto;
  right: 0;
  width: 100%; }

.btn-slide-center .btn-slide-bg {
  height: 100%;
  width: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.btn-slide-center:hover .btn-slide-bg {
  width: 100%; }

.btn-slide-middle .btn-slide-bg {
  height: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto; }

.btn-slide-middle:hover .btn-slide-bg {
  height: 100%; }

.btn-hover-icon {
  position: relative;
  overflow: hidden; }
  .btn-hover-icon .btn-text {
    padding: 0 7px; }
  .btn-hover-icon .btn-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: ease all 0.35s;
    padding: 0 !important; }
    .btn-hover-icon .btn-icon:first-child {
      left: 0;
      transform: translateX(-10px); }
    .btn-hover-icon .btn-icon:last-child {
      right: 0;
      transform: translateX(10px); }
  .btn-hover-icon .btn-text {
    transition: ease all 0.35s;
    display: inline-flex;
    transform: translateX(0); }
  .btn-hover-icon:hover .btn-icon {
    opacity: 1; }
    .btn-hover-icon:hover .btn-icon:first-child {
      transform: translateX(12px); }
    .btn-hover-icon:hover .btn-icon:last-child {
      transform: translateX(-12px); }
  .btn-hover-icon:hover .btn-text:first-child {
    transform: translateX(-10px); }
  .btn-hover-icon:hover .btn-text:last-child {
    transform: translateX(10px); }

.btn-hover-text {
  position: relative;
  overflow: hidden; }
  .btn-hover-text .btn-text {
    transform: translateY(0);
    opacity: 1;
    display: inline-flex;
    transition: cubic-bezier(0.22, 0.61, 0.36, 1) all 0.35s; }
  .btn-hover-text .btn-text-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: translateY(20px);
    opacity: 0;
    transition: cubic-bezier(0.22, 0.61, 0.36, 1) all 0.35s; }
  .btn-hover-text:hover .btn-text {
    opacity: 0;
    transform: translateY(-20px); }
  .btn-hover-text:hover .btn-text-hover {
    opacity: 1;
    transform: translateY(0px); }

.loading-preloader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--bs-white);
  z-index: 99999; }
  .loading-preloader .spinner-border {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto; }

.icon-sm {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 75%;
  line-height: normal; }

.icon-md {
  width: 2.8rem;
  height: 2.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1; }

.icon-lg {
  width: 3.5rem;
  height: 3.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.5rem;
  line-height: 1; }

.icon-xl {
  width: 5rem;
  height: 5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 2.2rem;
  line-height: 1; }

.only-icon-lg {
  font-size: 3.75rem;
  line-height: 1; }

.only-icon-md {
  font-size: 2.85rem;
  line-height: 1; }

@keyframes pulse-border {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 1; }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0; } }

.after-shadow {
  z-index: 1;
  position: relative; }
  .after-shadow:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: 50%;
    animation: pulse-border 1500ms ease-out infinite;
    pointer-events: none;
    background: currentColor;
    z-index: -1; }

.section {
  padding-top: 2rem;
  padding-bottom: 2rem; }
  @media (min-width: 768px) {
    .section {
      padding-top: 3rem;
      padding-bottom: 3rem; } }
  @media (min-width: 992px) {
    .section {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem; } }

.section-heading {
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .section-heading {
      margin-bottom: 1.75rem; } }
  @media (min-width: 992px) {
    .section-heading {
      margin-bottom: 2rem; } }

.section-heading-01 {
  border-bottom: 1px solid var(--bs-gray-300);
  position: relative;
  padding-bottom: 0.8rem; }
  .section-heading-01:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100px;
    height: 3px;
    background: var(--bs-primary); }

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020; }
  .sticky-lg-top-header {
    top: 100px; } }

article h4, article .h4,
article h5,
article .h5 {
  margin-bottom: 1.5rem; }
  article h4:not(:first-child), article .h4:not(:first-child),
  article h5:not(:first-child),
  article .h5:not(:first-child) {
    margin-top: 2rem; }

article p {
  line-height: 1.9; }

article figure {
  margin: 3rem 0; }
  article figure + h4, article figure + .h4,
  article figure + h5,
  article figure + .h5 {
    margin-top: 0 !important; }

article blockquote {
  border-left: 5px solid var(--bs-gray-300);
  padding-left: 2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: inline-block;
  vertical-align: top;
  width: 100%; }
  article blockquote + h4, article blockquote + .h4,
  article blockquote + h5,
  article blockquote + .h5 {
    margin-top: 0 !important; }

.avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-weight: 600;
  height: 3.125rem;
  width: 3.125rem;
  overflow: hidden; }
  .avatar-sm {
    height: 2.4375rem;
    width: 2.4375rem; }
  .avatar-lg {
    height: 4rem;
    width: 4rem; }
  .avatar-xl {
    height: 6rem;
    width: 6rem; }
  .avatar-xxl {
    height: 9rem;
    width: 9rem; }
  .avatar-group .avatar {
    margin-left: -1.25rem; }
    .avatar-group .avatar:hover {
      z-index: 1; }
  .avatar-parent {
    display: inline-block;
    position: relative; }
  .avatar-child {
    position: absolute;
    bottom: -0.45rem;
    border: 2px solid #fff;
    left: 0;
    right: 0;
    margin: auto;
    width: 0.9rem;
    height: 0.9rem;
    border-radius: 50%; }

.dropdown-header {
  font-weight: 700;
  font-size: 0.95rem; }

.dropdown-toggle:after {
  content: "\f282";
  font-family: bootstrap-icons;
  font-size: 0.5rem;
  margin-left: 0.35rem;
  font-weight: 900;
  vertical-align: middle;
  border: none; }

@media (min-width: 992px) {
  .dropdown > .dropdown-menu-hover {
    display: none !important;
    margin: 0; }
    .dropdown > .dropdown-menu-hover.left {
      left: 0;
      right: auto; }
    .dropdown > .dropdown-menu-hover.right {
      right: 0;
      left: auto; }
  .dropdown:hover > .dropdown-menu-hover {
    display: block !important; } }

* {
  outline: none; }

.link-effect {
  position: relative;
  padding: 0;
  border: none;
  background: none; }
  .link-effect:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: auto;
    width: 100%;
    height: 1px;
    background: currentColor;
    transition: ease all 0.35s; }
  .link-effect:hover:after {
    width: 0;
    right: 0;
    left: auto; }

.link-effect-02 {
  position: relative;
  padding: 0;
  border: none;
  background: none; }
  .link-effect-02:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: auto;
    width: 0;
    height: 1px;
    background: currentColor;
    transition: ease all 0.35s; }
  .link-effect-02:hover:after {
    width: 100%;
    right: 0;
    left: auto; }

.white-link a {
  --bs-text-opacity: 0.75;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)); }
  .white-link a:focus, .white-link a:hover {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)); }

.dark-link a {
  --bs-text-opacity: 0.72;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)); }
  .dark-link a:focus, .dark-link a:hover {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)); }
  [data-bs-theme="dark"] .dark-link a {
    --bs-text-opacity: 0.72;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)); }
    [data-bs-theme="dark"] .dark-link a:focus, [data-bs-theme="dark"] .dark-link a:hover {
      --bs-text-opacity: 1;
      color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)); }

.theme-link a {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)); }
  .theme-link a:focus, .theme-link a:hover {
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)); }

.link-list-style-01 li + li {
  padding-top: 18px; }

.link-list-style-01 li a {
  font-size: 18px;
  color: var(--bs-body-color);
  font-weight: 600;
  position: relative; }
  .link-list-style-01 li a:after {
    content: "";
    position: absolute;
    left: auto;
    right: 0;
    bottom: 0;
    height: 1px;
    background: currentColor;
    width: 0;
    transition: ease all 0.35s; }
  .link-list-style-01 li a:hover {
    color: var(--bs-primary); }
    .link-list-style-01 li a:hover:after {
      left: 0;
      right: auto;
      width: 100%; }

.link-list-style-02 li + li {
  padding-top: 3px; }

.link-list-style-02 li a {
  font-size: 14px;
  color: var(--bs-body-color);
  font-weight: 400;
  position: relative;
  background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
  background-size: 0px 1px;
  background-position: 0px 95%;
  background-repeat: no-repeat;
  transition: ease all 0.35s; }
  .link-list-style-02 li a:hover {
    color: var(--bs-primary);
    background-size: 100% 1px; }

.link-list-style-03 a {
  color: var(--bs-body-color);
  font-size: 0.90rem;
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  padding-left: 1rem;
  position: relative;
  display: block; }
  .link-list-style-03 a:after {
    content: "";
    position: absolute;
    top: 11px;
    left: 0;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: currentColor;
    opacity: .6; }

.link-list-style-03 li:hover a {
  color: var(--bs-primary); }
  .link-list-style-03 li:hover a:after {
    opacity: 1; }

.link-list-style-04 li + li {
  padding-top: 10px; }

.link-list-style-04 a {
  font-size: 15px;
  color: var(--bs-body-color);
  font-weight: 500;
  position: relative;
  letter-spacing: 1px; }

.link-list-style-05 li + li {
  padding-top: 4px; }

.link-list-style-05 li a {
  font-size: 14px;
  color: var(--bs-body-color);
  font-weight: 400;
  padding-left: 16px;
  position: relative;
  display: inline-flex; }
  .link-list-style-05 li a:after {
    content: "";
    position: absolute;
    left: 0;
    width: 6px;
    height: 2px;
    background: currentColor;
    top: 11px; }
  .link-list-style-05 li a:hover {
    color: var(--bs-primary); }

.border-white-light {
  border-color: rgba(var(--bs-white-rgb), 0.1) !important; }

.text-mode {
  color: var(--bs-text-mode) !important; }

.bg-gray-25 {
  background-color: var(--bs-gray-25) !important; }

.border-gray-25 {
  border-color: var(--bs-gray-25) !important; }

.bg-gray-50 {
  background-color: var(--bs-gray-50) !important; }

.border-gray-50 {
  border-color: var(--bs-gray-50) !important; }

.bg-gray-100 {
  background-color: var(--bs-gray-100) !important; }

.border-gray-100 {
  border-color: var(--bs-gray-100) !important; }

.bg-gray-200 {
  background-color: var(--bs-gray-200) !important; }

.border-gray-200 {
  border-color: var(--bs-gray-200) !important; }

.bg-gray-300 {
  background-color: var(--bs-gray-300) !important; }

.border-gray-300 {
  border-color: var(--bs-gray-300) !important; }

.bg-gray-400 {
  background-color: var(--bs-gray-400) !important; }

.border-gray-400 {
  border-color: var(--bs-gray-400) !important; }

.bg-gray-500 {
  background-color: var(--bs-gray-500) !important; }

.border-gray-500 {
  border-color: var(--bs-gray-500) !important; }

.bg-gray-600 {
  background-color: var(--bs-gray-600) !important; }

.border-gray-600 {
  border-color: var(--bs-gray-600) !important; }

.bg-gray-700 {
  background-color: var(--bs-gray-700) !important; }

.border-gray-700 {
  border-color: var(--bs-gray-700) !important; }

.bg-gray-800 {
  background-color: var(--bs-gray-800) !important; }

.border-gray-800 {
  border-color: var(--bs-gray-800) !important; }

.bg-gray-900 {
  background-color: var(--bs-gray-900) !important; }

.border-gray-900 {
  border-color: var(--bs-gray-900) !important; }

.bg-mode {
  background-color: var(--bs-bg-mode); }

.bg-mode-re {
  background-color: var(--bs-bg-mode-re); }

.bg-cover {
  background-size: cover; }

.bg-right-center {
  background-position: center right; }

.bg-50 {
  background-position: 50%; }

.bg-no-repeat {
  background-repeat: no-repeat; }

.bg-center {
  background-position: center; }

.bg-black {
  background: var(--bs-black); }

@media (min-width: 992px) {
  .bg-fixed {
    background-attachment: fixed; } }

:root {
  --swiper-theme-color: var(--bs-primary); }

.swiper-arrow-style-01 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  color: var(--bs-text-mode-re);
  z-index: 1;
  text-align: center;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 10px;
  transition: ease all 0.35s;
  font-size: 20px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: var(--bs-bg-mode); }
  .swiper-arrow-style-01.swiper-button-disabled {
    opacity: 0.5 !important;
    pointer-events: none; }
  .swiper-arrow-style-01:hover {
    background: var(--bs-primary);
    color: var(--bs-white); }
  .swiper-hover-arrow .swiper-arrow-style-01 {
    opacity: 0; }
  .swiper-hover-arrow:hover .swiper-arrow-style-01 {
    opacity: 1; }
  .swiper-arrow-style-01.swiper-prev {
    left: 10px;
    right: auto; }

.swiper-arrow-style-02 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: var(--bs-bg-mode);
  color: var(--bs-text-mode-re);
  z-index: 1;
  text-align: center;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: ease all 0.35s;
  font-size: 20px;
  width: 25px;
  height: 40px; }
  .swiper-hover-arrow:hover .swiper-arrow-style-02.swiper-button-disabled {
    opacity: 0.5;
    pointer-events: none; }
  .swiper-arrow-style-02:hover {
    background: var(--bs-primary);
    color: var(--bs-white); }
  .swiper-hover-arrow .swiper-arrow-style-02 {
    opacity: 0; }
  .swiper-hover-arrow:hover .swiper-arrow-style-02 {
    opacity: 1; }
  .swiper-arrow-style-02.swiper-next {
    right: 0; }
  .swiper-arrow-style-02.swiper-prev {
    left: 0; }

.swiper-arrow-style-03 {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  color: var(--bs-dark);
  background: var(--bs-white);
  z-index: 1;
  text-align: center;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  right: 0;
  transition: ease all 0.35s;
  font-size: 18px;
  width: 25px;
  height: 33px; }
  .swiper-arrow-style-03.swiper-button-disabled {
    opacity: 0.5 !important;
    pointer-events: none; }
  .swiper-arrow-style-03:hover {
    background: var(--bs-primary);
    color: var(--bs-white); }
  .swiper-hover-arrow .swiper-arrow-style-03 {
    opacity: 0; }
  .swiper-hover-arrow:hover .swiper-arrow-style-03 {
    opacity: 1; }
  .swiper-arrow-style-03.swiper-prev {
    left: 0;
    right: auto; }
  .swiper-vertical .swiper-arrow-style-03 {
    margin: auto;
    width: 40px;
    height: 20px; }
    .swiper-vertical .swiper-arrow-style-03.swiper-next {
      bottom: auto;
      top: 0;
      left: 0;
      right: 0; }
    .swiper-vertical .swiper-arrow-style-03.swiper-prev {
      bottom: 0;
      top: auto;
      left: 0;
      right: 0; }

.swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid var(--bs-primary);
  background: transparent;
  opacity: 1; }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination .swiper-pagination-bullet:hover {
    background: var(--bs-primary); }

.swiper-pagination.swiper-pagination-white .swiper-pagination-bullet {
  border: 1px solid var(--bs-white);
  background: transparent; }
  .swiper-pagination.swiper-pagination-white .swiper-pagination-bullet.swiper-pagination-bullet-active, .swiper-pagination.swiper-pagination-white .swiper-pagination-bullet:hover {
    background: var(--bs-white); }

.swiper.swiper-no-scroll {
  overflow: inherit; }

.radio-text {
  position: relative;
  padding: 0; }
  .radio-text .radio-text-label {
    min-width: 2rem;
    height: 2rem;
    font-size: .75rem;
    border: 1px solid var(--bs-gray-300);
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .radio-text .form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    margin: 0; }
    .radio-text .form-check-input:checked ~ .radio-text-label {
      border-color: var(--bs-primary);
      color: var(--bs-primary); }

.radio-color {
  position: relative;
  padding: 0;
  vertical-align: top;
  font-size: 0; }
  .radio-color .radio-color-label {
    width: 1.25rem;
    height: 1.25rem;
    font-size: .75rem;
    border: 1px solid var(--bs-gray-300);
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    position: relative; }
    .radio-color .radio-color-label span {
      position: absolute;
      top: 1px;
      left: 1px;
      right: 1px;
      bottom: 1px;
      border-radius: 50%; }
  .radio-color .form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    margin: 0; }
    .radio-color .form-check-input:checked ~ .radio-color-label {
      border-color: var(--bs-primary);
      color: var(--bs-primary); }
  .radio-color.large .radio-color-label {
    width: 1.85rem;
    height: 1.85rem; }

.custom-checkbox {
  position: relative;
  /*color*/
  /*Color*/ }
  .custom-checkbox .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 2rem; }
    .custom-checkbox .custom-control-label:before {
      content: "";
      position: absolute;
      top: .1875rem;
      left: 0;
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      pointer-events: none;
      background-color: var(--bs-gray-300);
      border: 1px solid var(--bs-gray-200); }
    .custom-checkbox .custom-control-label:after {
      content: "";
      position: absolute;
      border-top: 2px solid var(--bs-text-mode-re);
      border-right: 2px solid var(--bs-text-mode-re);
      width: 9px;
      height: 5px;
      top: -4px;
      left: 4px;
      z-index: 1;
      bottom: 0;
      margin: auto;
      transform: rotate(136deg);
      opacity: 0; }
  .custom-checkbox .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.3125rem;
    opacity: 0; }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
      background-color: var(--bs-text-mode); }
    .custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
      opacity: 1; }
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label, .custom-checkbox .custom-control-input[disabled] ~ .custom-control-label {
      text-decoration: line-through; }
      .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before, .custom-checkbox .custom-control-input[disabled] ~ .custom-control-label:before {
        border-color: var(--bs-gray-200);
        background-color: var(--bs-gray-200); }
  .custom-checkbox.checkbox-color .custom-control-label:before {
    background-color: currentColor; }
  .custom-checkbox.checkbox-color .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.3125rem;
    opacity: 0; }
    .custom-checkbox.checkbox-color .custom-control-input:checked ~ .custom-control-label:before {
      background-color: currentColor; }
    .custom-checkbox.checkbox-color .custom-control-input:checked ~ .custom-control-label:after {
      opacity: 1; }
    .custom-checkbox.checkbox-color .custom-control-input:disabled ~ .custom-control-label, .custom-checkbox.checkbox-color .custom-control-input[disabled] ~ .custom-control-label {
      text-decoration: none; }
      .custom-checkbox.checkbox-color .custom-control-input:disabled ~ .custom-control-label span, .custom-checkbox.checkbox-color .custom-control-input[disabled] ~ .custom-control-label span {
        text-decoration: line-through; }

.custom-radio {
  position: relative; }
  .custom-radio .custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    padding-left: 2rem; }
    .custom-radio .custom-control-label:before {
      content: "";
      position: absolute;
      top: .1875rem;
      left: 0;
      display: block;
      width: 1.125rem;
      height: 1.125rem;
      pointer-events: none;
      background-color: var(--bs-gray-300);
      border: none;
      border-radius: 50%; }
    .custom-radio .custom-control-label:after {
      content: "";
      position: absolute;
      border-top: 2px solid var(--bs-text-mode-re);
      border-right: 2px solid var(--bs-text-mode-re);
      width: 9px;
      height: 5px;
      top: -4px;
      left: 4px;
      z-index: 1;
      bottom: 0;
      margin: auto;
      transform: rotate(136deg);
      opacity: 0; }
  .custom-radio .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1.125rem;
    height: 1.3125rem;
    opacity: 0; }
    .custom-radio .custom-control-input:checked ~ .custom-control-label:before {
      background-color: var(--bs-text-mode); }
    .custom-radio .custom-control-input:checked ~ .custom-control-label:after {
      opacity: 1; }
    .custom-radio .custom-control-input:disabled ~ .custom-control-label:before, .custom-radio .custom-control-input[disabled] ~ .custom-control-label:before {
      border-color: var(--bs-gray-300);
      background-color: var(--bs-gray-300); }

.shop-list-tags {
  display: flex;
  flex-wrap: wrap; }
  .shop-list-tags a {
    margin-right: 3px;
    margin-bottom: 3px;
    border-radius: 3px;
    padding: 6px 15px;
    background: var(--bs-gray-100);
    color: var(--bs-body-color);
    font-size: 14px;
    border: 1px solid var(--bs-gray-100);
    position: relative; }
    .shop-list-tags a.active, .shop-list-tags a:hover {
      border: 1px solid var(--bs-primary);
      background: var(--bs-white);
      color: var(--bs-primary); }

.shop-list-colors {
  display: flex;
  flex-wrap: wrap; }
  .shop-list-colors a {
    font-size: 0px;
    flex: 0 0 30px;
    max-width: 30px;
    height: 30px;
    box-shadow: 0 0 0 1px var(--bs-white), 0 0 0 3px var(--bs-gray-300);
    border-radius: 50%;
    margin: 0 10px 10px 0; }
    .shop-list-colors a span {
      display: none; }
    .shop-list-colors a:hover, .shop-list-colors a.active {
      box-shadow: 0 0 0 1px var(--bs-white), 0 0 0 3px var(--bs-dark); }

.shop-list-tags-02 {
  display: flex;
  flex-wrap: wrap; }
  .shop-list-tags-02 a {
    margin-right: 3px;
    margin-bottom: 3px;
    padding: 6px 15px;
    background: var(--bs-body-bg);
    color: var(--bs-text-mode);
    font-size: 14px;
    border: 1px solid var(--bs-gray-300);
    position: relative; }
    .shop-list-tags-02 a.active, .shop-list-tags-02 a:hover {
      border: 1px solid var(--bs-text-mode);
      background: var(--bs-text-mode);
      color: var(--bs-text-mode-re); }

.count-down-01 {
  display: flex;
  flex-wrap: wrap; }
  .count-down-01 > div {
    display: flex;
    margin-right: 15px;
    flex-direction: column; }
  .count-down-01 .count {
    font-size: 24px;
    color: var(--bs-primary);
    font-weight: 600; }
  .count-down-01 .title {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px; }

.count-down-02 {
  display: flex;
  flex-wrap: wrap; }
  .count-down-02 > div {
    text-align: center;
    background: var(--bs-gray-100);
    line-height: 1;
    padding: 10px 0;
    margin: 3px 7px 3px 0px; }
  .count-down-02 .count {
    font-size: 18px;
    color: var(--bs-text-mode);
    display: inline-block;
    vertical-align: top;
    font-weight: 600;
    width: 100%; }
  .count-down-02 .title {
    font-size: 12px; }

.my-account .nav-pills {
  overflow: hidden; }
  .my-account .nav-pills a {
    position: relative;
    display: flex;
    padding: 1.875rem 0.9375rem;
    color: var(--bs-text-mode);
    border-bottom: 1px solid var(--bs-gray-200); }
    .my-account .nav-pills a:hover {
      background-color: var(--bs-primary);
      color: var(--bs-white); }
    .my-account .nav-pills a:hover::after {
      transform: translate(10px, 0);
      color: var(--bs-white); }
    .my-account .nav-pills a.active::after {
      color: var(--bs-white); }
    .my-account .nav-pills a:after {
      transition: all 0.2s;
      transition-delay: 0s;
      position: absolute;
      content: "\f27b";
      width: 1em;
      line-height: 1em;
      right: 1.25rem;
      font-family: bootstrap-icons !important;
      top: 50%;
      font-size: 1.125rem;
      margin-top: -0.5em;
      color: #d7dde2; }

.my-account .nav .nav-links:hover {
  background-color: var(--bs-primary); }

.list-type-01 {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list-type-01 li {
    position: relative;
    padding: 2px 0 2px 25px; }
    .list-type-01 li:after {
      content: "";
      width: 6px;
      height: 12px;
      position: absolute;
      top: 5px;
      left: 6px;
      color: var(--bs-white);
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      transform: rotate(45deg); }
    .list-type-01 li:before {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      top: 6px;
      left: 0;
      background: var(--bs-primary);
      border-radius: 50%; }
  .list-type-01.white li:after {
    color: var(--bs-primary); }
  .list-type-01.white li:before {
    background: var(--bs-white); }

.list-type-02 {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list-type-02 li {
    position: relative;
    padding: 3px 0 3px 20px; }
    .list-type-02 li:after {
      content: "";
      width: 5px;
      height: 9px;
      position: absolute;
      top: 10px;
      left: 3px;
      color: var(--bs-primary);
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      transform: rotate(45deg); }
  .list-type-02.white li:after {
    color: var(--bs-white); }

.list-type-03 {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list-type-03 li {
    position: relative;
    padding: 7px 0 8px 25px; }
    .list-type-03 li:after {
      content: "";
      width: 6px;
      height: 12px;
      position: absolute;
      top: 10px;
      left: 6px;
      color: var(--bs-white);
      border-right: 2px solid currentColor;
      border-bottom: 2px solid currentColor;
      transform: rotate(45deg); }
    .list-type-03 li:before {
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      top: 11px;
      left: 0;
      background: var(--bs-primary);
      border-radius: 50%; }
  .list-type-03.white li:after {
    color: var(--bs-dark); }
  .list-type-03.white li:before {
    background: var(--bs-white); }

.list-type-04 {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list-type-04 li {
    position: relative;
    padding: 9px 0 9px 25px; }
    .list-type-04 li + li {
      border-top: 1px solid var(--bs-gray-300); }
    .list-type-04 li i {
      position: absolute;
      top: 10;
      left: 0; }

.list-type-05 {
  margin: 0;
  padding: 0;
  list-style: none; }
  .list-type-05 li {
    position: relative;
    padding: 9px 0; }
    .list-type-05 li + li {
      border-top: 1px solid rgba(var(--bs-black-rgb), 0.2); }

.hover-scale .hover-scale-in {
  overflow: hidden; }
  .hover-scale .hover-scale-in img {
    transition: ease all 0.35s;
    transform: scale(1); }

.hover-scale:hover .hover-scale-in img {
  transform: scale(1.05); }

.sm-title-01 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-left: 40px;
  padding-right: 40px; }
  .sm-title-01:before, .sm-title-01:after {
    content: "";
    position: absolute;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 2px;
    width: 25px;
    background: var(--bs-primary); }
  .sm-title-01:before {
    left: 0; }
  .sm-title-01:after {
    right: 0; }

.sm-title-02 {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bs-gray-300); }
  .sm-title-02:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 3px;
    width: 30px;
    background: var(--bs-primary); }

.sm-title-03 {
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.7;
  letter-spacing: 1px; }

.sm-title-04 {
  text-transform: uppercase;
  margin: 0px 0 15px;
  position: relative;
  padding: 0 0 8px;
  font-weight: 500; }
  .sm-title-04:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25px;
    height: 2px;
    background: var(--bs-primary); }

.sm-title-05 {
  font-size: 12px;
  text-transform: uppercase;
  opacity: 0.7;
  letter-spacing: 3px;
  font-weight: 400; }

.nav-tabs-01 {
  border-bottom: none;
  margin-bottom: 25px; }
  .nav-tabs-01 .nav-item {
    margin: 0 15px; }
    .nav-tabs-01 .nav-item .nav-link {
      margin: 0;
      font-size: 1.2rem;
      border: none;
      font-weight: 600;
      position: relative;
      padding: 5px 0;
      color: var(--bs-text-mode); }
      .nav-tabs-01 .nav-item .nav-link:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: auto;
        right: 0;
        height: 2px;
        width: 0px;
        background: var(--bs-primary);
        transition: ease all 0.35s; }
      .nav-tabs-01 .nav-item .nav-link:hover, .nav-tabs-01 .nav-item .nav-link.active {
        color: var(--bs-primary); }
        .nav-tabs-01 .nav-item .nav-link:hover:after, .nav-tabs-01 .nav-item .nav-link.active:after {
          width: 100%;
          left: 0;
          right: auto; }

.nav-tabs-02 {
  border-bottom: none;
  margin-bottom: 25px; }
  .nav-tabs-02 .nav-item {
    margin: 5px; }
    .nav-tabs-02 .nav-item .nav-link {
      margin: 0;
      font-size: .85rem;
      border: none;
      font-weight: 500;
      position: relative;
      padding: 5px 15px;
      color: var(--bs-text-mode);
      border: 1px solid var(--bs-gray-300);
      border-radius: 5px; }
      .nav-tabs-02 .nav-item .nav-link:hover, .nav-tabs-02 .nav-item .nav-link.active {
        color: var(--bs-white);
        background: var(--bs-primary);
        border-color: var(--bs-primary); }

img {
  max-width: 100%; }

.table > :not(:first-child) {
  border-top: 1px solid currentColor; }

.browser-device {
  position: relative;
  z-index: 1;
  display: block;
  max-width: 100%; }
  .browser-device .browser-device-header {
    position: relative;
    display: flex;
    background: var(--bs-white);
    border-bottom: 1px solid var(--bs-gray-300);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    padding: .5rem 6.5rem; }
    .browser-device .browser-device-header .browser-device-action {
      display: flex;
      grid-gap: .25rem;
      position: absolute;
      top: calc(50% - .25rem);
      left: 1rem; }
      .browser-device .browser-device-header .browser-device-action span {
        width: .5rem;
        height: .5rem;
        background-color: var(--bs-gray-300);
        border-radius: 50%; }
    .browser-device .browser-device-header .browser-device-bar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      color: var(--bs-gray-800);
      background-color: var(--bs-gray-100);
      font-size: .5rem;
      border-radius: .125rem; }
  .browser-device .browser-device-frame {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem;
    background-color: var(--bs-white);
    box-shadow: 0 0.3rem 1.525rem -0.375rem rgba(0, 0, 0, 0.1); }
    .browser-device .browser-device-frame img {
      border-bottom-right-radius: .5rem;
      border-bottom-left-radius: .5rem; }
  .browser-device .browser-actions {
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 0 0 10px 10px;
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    opacity: 0;
    transition: ease all 0.35s; }
  .browser-device:hover .browser-actions {
    opacity: 1; }

.breadcrumb-light .breadcrumb-item + .breadcrumb-item:before {
  color: var(--bs-white);
  opacity: 0.8; }

.breadcrumb-light .breadcrumb-item a {
  color: var(--bs-white); }

.breadcrumb-light .breadcrumb-item.active {
  color: var(--bs-white); }

@media (min-width: 1400px) {
  .px-container {
    padding-left: 35px;
    padding-right: 35px; } }

.rating-star .active {
  color: #fea569; }

.horizontal-scroll-box {
  overflow-x: auto;
  max-width: 100%; }

/* Product Card 1
-------------------------------------------*/
.product-card-1 {
  border: 1px solid var(--bs-gray-300);
  position: relative;
  background: var(--bs-body-bg); }
  .product-card-1 .badge-ribbon {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1; }
    .product-card-1 .badge-ribbon .badge {
      border-radius: 0; }
  .product-card-1 .product-card-image {
    border-bottom: 1px solid var(--bs-gray-300); }
  .product-card-1 .product-media {
    overflow: hidden;
    position: relative; }
    .product-card-1 .product-media img {
      transform: scale(1);
      transition: ease all 0.35s;
      width: 100%; }
  .product-card-1 .product-action {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    flex-direction: column;
    transition: ease all 0.35s;
    z-index: 1; }
    @media (min-width: 576px) {
      .product-card-1 .product-action {
        opacity: 0;
        right: 25px;
        top: 15px; } }
    .product-card-1 .product-action .btn {
      padding: 0;
      width: 35px;
      height: 35px;
      margin-bottom: 10px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1; }
      @media (max-width: 575.98px) {
        .product-card-1 .product-action .btn {
          width: 30px;
          height: 30px;
          margin-bottom: 5px; } }
  .product-card-1 .product-cart-btn {
    position: relative; }
    @media (min-width: 576px) {
      .product-card-1 .product-cart-btn {
        position: absolute;
        bottom: -25%;
        left: 0;
        right: 0;
        transition: ease all 0.35s; } }
    .product-card-1 .product-cart-btn .btn {
      border-radius: 0; }
  .product-card-1 .product-card-info {
    position: relative;
    padding: 8px;
    text-align: center; }
    @media (min-width: 576px) {
      .product-card-1 .product-card-info {
        padding: 10px 15px 15px; } }
  .product-card-1 .product-meta {
    line-height: normal;
    margin-bottom: 2px; }
    .product-card-1 .product-meta a {
      color: var(--bs-body-color);
      font-size: 11px; }
      .product-card-1 .product-meta a:hover {
        color: var(--bs-primary); }
  .product-card-1 .product-title {
    font-weight: 600;
    margin: 0 0 6px; }
    .product-card-1 .product-title a {
      color: var(--bs-text-mode); }
      .product-card-1 .product-title a:hover {
        color: var(--bs-primary); }
  .product-card-1 .product-price span {
    font-size: 16px;
    font-weight: 600; }
  .product-card-1 .product-price del {
    font-size: 14px; }
  .product-card-1 .rating-star {
    font-size: 12px;
    margin-bottom: 5px; }
  .product-card-1 .form-check {
    margin: 0 2px; }
  .product-card-1 .nav-thumbs {
    padding-top: 10px; }
    @media (max-width: 575.98px) {
      .product-card-1 .nav-thumbs {
        display: flex;
        flex-wrap: nowrap;
        max-width: 100%;
        overflow-x: auto; } }
  .product-card-1:hover {
    box-shadow: 0 0.25rem 0.5625rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 1.25rem -0.0625rem rgba(0, 0, 0, 0.05); }
    .product-card-1:hover .product-media img {
      transform: scale(1.1); }
    @media (min-width: 576px) {
      .product-card-1:hover .product-action {
        opacity: 1;
        right: 15px; } }
    .product-card-1:hover .product-cart-btn {
      bottom: 0; }

/* Product Card 2
-------------------------------------------*/
.product-card-2 {
  border: 1px solid var(--bs-gray-300);
  position: relative;
  background: var(--bs-body-bg); }
  .product-card-2 .badge-ribbon {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1; }
  .product-card-2 .product-media {
    overflow: hidden;
    position: relative; }
    .product-card-2 .product-media img {
      transform: scale(1);
      transition: ease all 0.35s;
      width: 100%; }
  .product-card-2 .btn {
    padding: 0;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
  .product-card-2 .product-wishlist {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1; }
    .product-card-2 .product-wishlist .btn:not(:hover) {
      background: var(--bs-white); }
  .product-card-2 .product-action {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    transition: ease all 0.35s;
    padding: 10px; }
    .product-card-2 .product-action .btn {
      margin: 0 3px;
      position: relative;
      transition: ease all 0.35s;
      opacity: 0;
      top: -15px; }
  .product-card-2 .product-card-info {
    position: relative;
    padding: 10px 15px 15px;
    text-align: center; }
  .product-card-2 .product-meta {
    line-height: normal;
    margin-bottom: 2px; }
    .product-card-2 .product-meta a {
      color: var(--bs-body-color);
      font-size: 11px; }
      .product-card-2 .product-meta a:hover {
        color: var(--bs-primary); }
  .product-card-2 .product-title {
    font-weight: 600;
    margin: 0 0 6px; }
    .product-card-2 .product-title a {
      color: var(--bs-text-mode); }
      .product-card-2 .product-title a:hover {
        color: var(--bs-primary); }
  .product-card-2 .product-price span {
    font-size: 16px;
    font-weight: 600; }
  .product-card-2 .product-price del {
    font-size: 14px; }
  .product-card-2 .rating-star {
    font-size: 12px;
    margin-bottom: 5px; }
  .product-card-2 .form-check {
    margin: 0 2px; }
  .product-card-2 .nav-thumbs {
    padding-top: 10px; }
  .product-card-2:hover {
    box-shadow: 0 0.25rem 0.5625rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 1.25rem -0.0625rem rgba(0, 0, 0, 0.05); }
    .product-card-2:hover .product-media img {
      transform: scale(1.1); }
    .product-card-2:hover .product-action {
      background: rgba(var(--bs-white-rgb), 0.6); }
      .product-card-2:hover .product-action .btn {
        opacity: 1;
        top: 0; }

/* Product Card 3
-------------------------------------------*/
.product-card-3 {
  position: relative;
  background: var(--bs-body-bg); }
  .product-card-3 .badge-ribbon {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1; }
  .product-card-3 .product-media {
    overflow: hidden;
    position: relative; }
    .product-card-3 .product-media img {
      transform: scale(1);
      transition: ease all 0.35s;
      width: 100%; }
  .product-card-3 .product-action {
    position: absolute;
    top: 15px;
    right: 15px;
    display: flex;
    flex-direction: column;
    z-index: 1; }
    .product-card-3 .product-action .btn {
      padding: 0;
      width: 35px;
      height: 35px;
      border-radius: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      margin-bottom: 10px;
      transition: ease-in opacity 0.30s, ease-in left 0.30s;
      position: relative;
      left: -20px;
      opacity: 0; }
      @media (max-width: 575.98px) {
        .product-card-3 .product-action .btn {
          width: 30px;
          height: 30px;
          margin-bottom: 5px; } }
      .product-card-3 .product-action .btn + .btn {
        transition-delay: 0.15s; }
        .product-card-3 .product-action .btn + .btn + .btn {
          transition-delay: 0.20s; }
          .product-card-3 .product-action .btn + .btn + .btn + .btn {
            transition-delay: 0.25s; }
            .product-card-3 .product-action .btn + .btn + .btn + .btn + .btn {
              transition-delay: 0.30s; }
      .product-card-3 .product-action .btn:hover {
        background: var(--bs-primary);
        border-color: var(--bs-primary); }
  .product-card-3 .product-card-info {
    position: relative;
    padding: 10px 15px;
    text-align: center; }
  .product-card-3 .product-title {
    font-weight: 600;
    margin: 0 0 6px; }
    .product-card-3 .product-title a {
      color: var(--bs-text-mode); }
      .product-card-3 .product-title a:hover {
        color: var(--bs-primary); }
  .product-card-3 .product-price span {
    font-size: 16px;
    font-weight: 600; }
  .product-card-3 .product-price del {
    font-size: 14px; }
  .product-card-3:hover .product-media img {
    transform: scale(1.1); }
  .product-card-3:hover .product-action .btn {
    left: 0;
    opacity: 1; }

/* Product Card 4
-------------------------------------------*/
.product-card-4 {
  display: flex;
  border: 1px solid var(--bs-gray-300);
  background: var(--bs-body-bg); }
  .product-card-4 + .product-card-4 {
    margin-top: 20px; }
  .product-card-4 .product-card-image {
    flex: 0 0 120px;
    max-width: 120px; }
  .product-card-4 .product-card-info {
    position: relative;
    padding: 15px;
    flex: 0 0 calc(100% - 120px);
    max-width: calc(100% - 120px); }
  .product-card-4 .product-title {
    font-weight: 600;
    margin: 0 0 6px; }
    .product-card-4 .product-title a {
      color: var(--bs-text-mode); }
      .product-card-4 .product-title a:hover {
        color: var(--bs-primary); }
  .product-card-4 .product-price span {
    font-size: 16px;
    font-weight: 600; }
  .product-card-4 .product-price del {
    font-size: 14px; }
  .product-card-4 .produc-card-cart {
    margin-top: 5px; }
    .product-card-4 .produc-card-cart a {
      text-transform: uppercase;
      color: var(--bs-text-mode);
      font-weight: 600;
      font-size: 12px; }
      .product-card-4 .produc-card-cart a:hover {
        color: var(--bs-primary); }

/* Product Card 5
-------------------------------------------*/
.product-card-5 {
  position: relative;
  background: var(--bs-body-bg); }
  .product-card-5 .badge-ribbon {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1; }
  .product-card-5 .product-card-image {
    position: relative; }
  .product-card-5 .product-media {
    overflow: hidden;
    position: relative; }
    .product-card-5 .product-media img {
      width: 100%; }
    .product-card-5 .product-media .product-hover-img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: ease-in opacity 0.30s; }
  .product-card-5 .product-wishlist-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1; }
    .product-card-5 .product-wishlist-btn .btn {
      padding: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      margin-bottom: 10px;
      transition: ease-in opacity 0.30s, ease-in left 0.30s;
      position: relative; }
      .product-card-5 .product-wishlist-btn .btn:hover {
        background: var(--bs-dark);
        border-color: var(--bs-dark); }
  .product-card-5 .product-action {
    position: absolute;
    bottom: 15px;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 1; }
    .product-card-5 .product-action .btn {
      padding: 0;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      margin-bottom: 10px;
      transition: ease-in opacity 0.30s, ease-in top 0.30s;
      position: relative;
      top: -20px;
      opacity: 0;
      margin: 2px; }
      .product-card-5 .product-action .btn:hover {
        background: var(--bs-primary);
        border-color: var(--bs-primary); }
  .product-card-5 .product-card-info {
    position: relative;
    padding: 10px 15px 15px;
    text-align: center; }
  .product-card-5 .product-title {
    font-weight: 600;
    margin: 0 0 6px; }
    .product-card-5 .product-title a {
      color: var(--bs-text-mode); }
      .product-card-5 .product-title a:hover {
        color: var(--bs-primary); }
  .product-card-5 .product-price span {
    font-size: 16px;
    font-weight: 600; }
  .product-card-5 .product-price del {
    font-size: 14px; }
  .product-card-5 .product-cart-btn {
    padding-top: 10px; }
    .product-card-5 .product-cart-btn .btn {
      border-radius: 35px; }
  .product-card-5:hover .product-media .product-hover-img {
    opacity: 1; }
  .product-card-5:hover .product-action .btn {
    top: 0;
    opacity: 1; }

/* Product Card 6
-------------------------------------------*/
.product-card-6 .product-card-image {
  position: relative; }

.product-card-6 .badge-ribbon {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 1; }

.product-card-6 .product-action {
  position: absolute;
  display: flex;
  left: 20px;
  right: 20px;
  bottom: 20px;
  z-index: 2; }
  .product-card-6 .product-action .btn {
    padding: 0;
    flex: 1;
    border: none;
    line-height: 45px;
    font-size: 18px;
    color: var(--bs-black);
    background: var(--bs-white);
    border-radius: 0; }
    @media (max-width: 575.98px) {
      .product-card-6 .product-action .btn {
        line-height: 35px;
        font-size: 14px; } }
    .product-card-6 .product-action .btn:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px; }
    .product-card-6 .product-action .btn:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px; }
    .product-card-6 .product-action .btn + .btn {
      border-left: 1px solid var(--bs-gray-300); }
    .product-card-6 .product-action .btn:hover {
      background: var(--bs-black);
      color: var(--bs-white); }

.product-card-6 .product-media {
  overflow: hidden;
  border-radius: 5px; }
  .product-card-6 .product-media img {
    transition: transform 1.1s cubic-bezier(0.15, 0.75, 0.5, 1) 0s;
    transform: translateZ(0); }

.product-card-6 .product-card-info {
  padding-top: 15px; }

.product-card-6 .product-meta {
  line-height: normal;
  margin-bottom: 2px; }
  .product-card-6 .product-meta a {
    color: var(--bs-body-color);
    font-size: 11px; }
    .product-card-6 .product-meta a:hover {
      color: var(--bs-primary); }

.product-card-6 .product-title {
  font-weight: 600;
  margin: 0 0 6px; }
  .product-card-6 .product-title a {
    color: var(--bs-text-mode); }
    .product-card-6 .product-title a:hover {
      color: var(--bs-primary); }

.product-card-6 .product-price span {
  font-size: 16px;
  font-weight: 600; }

.product-card-6 .product-price del {
  font-size: 14px; }

.product-card-6 .rating-star {
  font-size: 12px;
  margin-bottom: 5px; }

.product-card-6 .nav-thumbs {
  padding-top: 10px;
  font-size: 0px; }
  .product-card-6 .nav-thumbs .form-check {
    margin: 0 6px 0 0;
    min-height: inherit; }

.product-card-6:hover .product-media img {
  transform: scale3d(1.1, 1.1, 1.1) translateZ(0); }

/* Product Card 7
-------------------------------------------*/
.product-card-7 .product-card-image {
  position: relative; }

.product-card-7 .badge-ribbon {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 1; }

.product-card-7 .product-action {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 10px;
  top: 10px;
  z-index: 2;
  transition: ease opacity 0.35s; }
  .product-card-7 .product-action .btn {
    padding: 0;
    border: 1px solid var(--bs-gray-300);
    width: 30px;
    height: 30px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--bs-text-mode);
    background: var(--bs-body-bg);
    font-size: 14px;
    border-radius: 50%; }
    .product-card-7 .product-action .btn + .btn {
      margin-top: 8px; }
    .product-card-7 .product-action .btn:hover {
      background: var(--bs-primary);
      border-color: var(--bs-primary);
      color: var(--bs-white); }

.product-card-7 .product-media {
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid var(--bs-gray-300); }
  .product-card-7 .product-media img {
    transition: transform 1.1s cubic-bezier(0.15, 0.75, 0.5, 1) 0s;
    transform: translateZ(0); }

.product-card-7 .product-cart-btn {
  padding-top: 0.7rem; }
  .product-card-7 .product-cart-btn .btn {
    width: 100%;
    border-radius: 3px;
    font-size: 14px; }

.product-card-7 .product-card-info {
  padding-top: 15px;
  text-align: center; }

.product-card-7 .product-title {
  font-weight: 600;
  margin: 0 0 6px; }
  .product-card-7 .product-title a {
    color: var(--bs-text-mode); }
    .product-card-7 .product-title a:hover {
      color: var(--bs-primary); }

.product-card-7 .product-price span {
  font-size: 16px;
  font-weight: 600; }

.product-card-7 .product-price del {
  font-size: 14px; }

.product-card-7 .rating-star {
  font-size: 12px;
  margin-bottom: 5px; }

.product-card-7:hover .product-media img {
  transform: scale3d(1.1, 1.1, 1.1) translateZ(0); }

@media (min-width: 992px) {
  .product-card-7 .product-action {
    opacity: 0; }
    .product-card-7 .product-action .btn {
      width: 40px;
      height: 40px;
      font-size: 18px; }
      .product-card-7 .product-action .btn i {
        transform: scale(0);
        transition: ease transform 0.35s; }
  .product-card-7 .product-cart-btn {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 1rem;
    opacity: 0;
    bottom: -15px;
    transition: ease all 0.35s; }
  .product-card-7:hover .product-cart-btn {
    opacity: 1;
    bottom: 0px; }
  .product-card-7:hover .product-action {
    opacity: 1; }
    .product-card-7:hover .product-action .btn i {
      transform: scale(1); } }

/* Product Card 8
-------------------------------------------*/
.product-card-8 {
  border: 1px solid var(--bs-gray-300);
  position: relative;
  background: var(--bs-body-bg);
  border-radius: 10px;
  overflow: hidden; }
  .product-card-8 .badge-ribbon {
    position: absolute;
    top: 15px;
    left: 0;
    z-index: 1; }
  .product-card-8 .product-media {
    overflow: hidden;
    position: relative; }
    .product-card-8 .product-media img {
      transform: scale(1);
      transition: ease all 0.35s;
      width: 100%; }
  .product-card-8 .product-action {
    position: absolute;
    top: 15px;
    right: 25px;
    display: flex;
    flex-direction: column;
    transition: ease all 0.35s;
    opacity: 0;
    z-index: 1; }
    .product-card-8 .product-action .btn {
      padding: 0;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      margin-bottom: 10px; }
  .product-card-8 .product-cart-btn {
    padding-top: 8px; }
    .product-card-8 .product-cart-btn .btn {
      border-radius: 30px; }
  .product-card-8 .product-card-info {
    position: relative;
    padding: 10px 15px 15px;
    text-align: center; }
  .product-card-8 .product-title {
    font-weight: 600;
    margin: 0 0 6px; }
    .product-card-8 .product-title a {
      color: var(--bs-text-mode); }
      .product-card-8 .product-title a:hover {
        color: var(--bs-primary); }
  .product-card-8 .product-price span {
    font-size: 16px;
    font-weight: 600; }
  .product-card-8 .product-price del {
    font-size: 14px; }
  .product-card-8 .rating-star {
    font-size: 12px;
    margin-bottom: 5px; }
  .product-card-8:hover {
    box-shadow: 0 0.125rem 0.3rem -0.0625rem rgba(0, 0, 0, 0.03), 0 0.275rem 0.75rem -0.0625rem rgba(0, 0, 0, 0.06); }
    .product-card-8:hover .product-media img {
      transform: scale(1.1); }
    .product-card-8:hover .product-action {
      opacity: 1;
      right: 15px; }

/* Product Card 8
-------------------------------------------*/
.product-card-9 .product-card-image {
  position: relative; }

.product-card-9 .badge-ribbon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 45px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  text-transform: uppercase;
  background: var(--bs-danger);
  color: var(--bs-white);
  z-index: 1;
  border-radius: 50%; }

.product-card-9 .product-media {
  position: relative;
  overflow: hidden; }
  .product-card-9 .product-media img {
    transition: ease all 0.35s;
    transform: scale(1);
    opacity: 1; }
  .product-card-9 .product-media .product-media-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: ease all 0.35s;
    transform: scale(1);
    opacity: 0; }

.product-card-9 .product-action {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 1;
  top: 10px;
  right: 0;
  opacity: 0;
  transition: ease all 0.35s; }
  .product-card-9 .product-action .btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
    box-shadow: 0 5px 10px rgba(var(--bs-dark-rgb), 0.05);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: var(--bs-white);
    color: var(--bs-dark);
    border: 1px solid var(--bs-gray-300); }
    .product-card-9 .product-action .btn + .btn {
      margin-top: 6px; }
    .product-card-9 .product-action .btn:hover {
      background: var(--bs-primary);
      color: var(--bs-white); }

.product-card-9 .product-card-info {
  text-align: center;
  padding: 10px 0 0; }

.product-card-9 .rating-star {
  font-size: 12px;
  margin-bottom: 5px; }

.product-card-9 .product-title {
  font-size: 14px;
  margin: 0; }
  .product-card-9 .product-title a {
    color: var(--bs-text-mode); }

.product-card-9 .nav-thumbs {
  margin-top: 8px;
  font-size: 0px;
  line-height: normal; }
  .product-card-9 .nav-thumbs .form-check {
    min-height: inherit;
    margin: 0 1px; }

.product-card-9 .product-price {
  margin-top: 8px; }

.product-card-9:hover .product-action {
  right: 10px;
  opacity: 1; }

.product-card-9:hover .product-media img {
  transform: scale(1.03);
  opacity: 0; }

.product-card-9:hover .product-media .product-media-hover {
  transform: scale(1.03);
  opacity: 1; }

/* Product Card 8
-------------------------------------------*/
.product-card-10 {
  border: 1px solid var(--bs-gray-300); }
  .product-card-10 .product-card-image {
    position: relative; }
  .product-card-10 .badge-ribbon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 45px;
    height: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    text-transform: uppercase;
    background: var(--bs-danger);
    color: var(--bs-white);
    z-index: 1;
    border-radius: 50%; }
  .product-card-10 .product-media {
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid var(--bs-gray-300); }
    .product-card-10 .product-media img {
      transition: ease all 0.35s;
      transform: scale(1); }
  .product-card-10 .product-action {
    padding-top: 8px; }
    .product-card-10 .product-action .btn {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: var(--bs-primary);
      color: var(--bs-white);
      border: none;
      margin: 0 2px;
      padding: 0; }
      @media (max-width: 575.98px) {
        .product-card-10 .product-action .btn {
          width: 26px;
          height: 26px;
          margin: 1px;
          font-size: 12px; } }
      .product-card-10 .product-action .btn:hover {
        background: var(--bs-bg-mode);
        color: var(--bs-text-mode-re); }
  .product-card-10 .product-card-info {
    text-align: center;
    padding: 15px; }
    @media (max-width: 575.98px) {
      .product-card-10 .product-card-info {
        padding: 8px; } }
  .product-card-10 .rating-star {
    font-size: 12px;
    margin-bottom: 5px; }
  .product-card-10 .product-title {
    font-size: 14px;
    margin: 0; }
    .product-card-10 .product-title a {
      color: var(--bs-text-mode); }
  .product-card-10 .product-price {
    margin-top: 8px;
    line-height: normal; }
  .product-card-10:hover .product-media img {
    transform: scale(1.03); }

.category-card-01 {
  position: relative; }
  .category-card-01 .category-card-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(var(--bs-dark-rgb), 0.3);
    transition: ease all 0.35s;
    opacity: 0; }
  .category-card-01:hover .category-card-info {
    opacity: 1; }

.shop-clear-filter-01 {
  margin-bottom: 20px;
  padding-bottom: 10px;
  max-width: 100%;
  overflow-x: auto; }
  .shop-clear-filter-01 ul {
    display: flex;
    margin: 0;
    padding: 0; }
    .shop-clear-filter-01 ul li + li {
      margin-left: 10px; }
  .shop-clear-filter-01 a {
    display: inline-block;
    vertical-align: top;
    padding: 2px 8px;
    border: 1px solid var(--bs-gray-300);
    color: var(--bs-text-mode);
    transition: ease all 0.35s;
    display: flex;
    align-items: center;
    white-space: nowrap; }
    .shop-clear-filter-01 a:hover {
      background: var(--bs-primary);
      border-color: var(--bs-primary);
      color: var(--bs-white); }
    .shop-clear-filter-01 a.clear-all {
      background: var(--bs-danger);
      border-color: var(--bs-danger);
      color: var(--bs-white); }
      .shop-clear-filter-01 a.clear-all:hover {
        background: var(--bs-dark);
        border-color: var(--bs-dark); }

.shop-category-list .nav .nav {
  padding-left: 10px; }
  .shop-category-list .nav .nav .nav-link {
    color: var(--bs-body-color);
    font-weight: 400;
    font-size: 15px; }
    .shop-category-list .nav .nav .nav-link.active, .shop-category-list .nav .nav .nav-link:hover {
      color: var(--bs-primary); }

.shop-category-list .nav-item {
  position: relative; }
  .shop-category-list .nav-item .s-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 5px;
    right: 0; }
    .shop-category-list .nav-item .s-icon:before, .shop-category-list .nav-item .s-icon:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background: var(--bs-text-mode); }
    .shop-category-list .nav-item .s-icon:before {
      width: 2px;
      height: 10px; }
    .shop-category-list .nav-item .s-icon:after {
      width: 10px;
      height: 2px; }
    .shop-category-list .nav-item .s-icon[aria-expanded="true"]:before {
      height: 0; }

.shop-category-list .nav-link {
  padding: 8px 0;
  color: var(--bs-text-mode);
  font-weight: 500; }
  .shop-category-list .nav-link span {
    color: var(--bs-body-color);
    font-size: 13px; }
  .shop-category-list .nav-link.active, .shop-category-list .nav-link:hover {
    color: var(--bs-primary); }

/* Sidebar
---------------------------*/
.shop-sidebar-list ul {
  margin: 0;
  padding: 0;
  list-style: none; }

.shop-sidebar-list li {
  padding-top: 5px;
  padding-bottom: 5px; }

.shop-sidebar-block {
  margin-bottom: 30px; }
  .shop-sidebar-block .shop-sidebar-title {
    position: relative; }
    .shop-sidebar-block .shop-sidebar-title h5, .shop-sidebar-block .shop-sidebar-title .h5 {
      margin: 0 0 15px;
      padding: 0 0 12px;
      border-bottom: 1px solid var(--bs-gray-300);
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 600;
      width: 100%;
      display: inline-block;
      vertical-align: top;
      color: var(--bs-text-mode); }
      .shop-sidebar-block .shop-sidebar-title h5[aria-expanded="false"] i:before, .shop-sidebar-block .shop-sidebar-title .h5[aria-expanded="false"] i:before {
        content: "\f282"; }
    .shop-sidebar-block .shop-sidebar-title i {
      position: absolute;
      right: 0;
      top: 3px; }

/* Sidebar
---------------------------*/
.shop-top-bar .layout-change .btn {
  padding: 0;
  width: 38px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--bs-gray-300);
  background: transparent;
  color: var(--bs-text-mode); }
  .shop-top-bar .layout-change .btn i {
    font-size: 18px;
    line-height: 1; }
  .shop-top-bar .layout-change .btn.active, .shop-top-bar .layout-change .btn:hover {
    background: var(--bs-bg-mode);
    border-color: var(--bs-gray-300);
    color: var(--bs-text-mode-re); }

.shop-sidebar-option-02 {
  flex: 0 0 300px;
  max-width: 300px; }
  .shop-sidebar-option-02 .shop-categories-list {
    border-top: 1px solid var(--bs-gray-300); }
    .shop-sidebar-option-02 .shop-categories-list .nav .nav {
      padding-left: 15px;
      margin-bottom: 10px;
      border-left: 1px dashed var(--bs-gray-300); }
      .shop-sidebar-option-02 .shop-categories-list .nav .nav .nav-link {
        color: var(--bs-body-color);
        font-weight: 400;
        font-size: 15px; }
        .shop-sidebar-option-02 .shop-categories-list .nav .nav .nav-link.active, .shop-sidebar-option-02 .shop-categories-list .nav .nav .nav-link:hover {
          color: var(--bs-primary); }
    .shop-sidebar-option-02 .shop-categories-list .nav-item {
      position: relative; }
      .shop-sidebar-option-02 .shop-categories-list .nav-item + .nav-item {
        border-top: 1px dashed var(--bs-gray-300); }
      .shop-sidebar-option-02 .shop-categories-list .nav-item .s-icon {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 5px;
        right: 0; }
        .shop-sidebar-option-02 .shop-categories-list .nav-item .s-icon:before, .shop-sidebar-option-02 .shop-categories-list .nav-item .s-icon:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          background: var(--bs-dark); }
        .shop-sidebar-option-02 .shop-categories-list .nav-item .s-icon:before {
          width: 2px;
          height: 10px; }
        .shop-sidebar-option-02 .shop-categories-list .nav-item .s-icon:after {
          width: 10px;
          height: 2px; }
        .shop-sidebar-option-02 .shop-categories-list .nav-item .s-icon[aria-expanded="true"]:before {
          height: 0; }
    .shop-sidebar-option-02 .shop-categories-list .nav-link {
      padding: 8px 30px 8px 25px;
      color: var(--bs-text-mode);
      font-weight: 400;
      position: relative;
      display: inline-block;
      vertical-align: top; }
      .shop-sidebar-option-02 .shop-categories-list .nav-link:after {
        content: "";
        position: absolute;
        left: 0;
        top: 12px;
        width: 16px;
        height: 16px;
        border: 1px solid var(--bs-gray-400);
        border-radius: 3px; }
      .shop-sidebar-option-02 .shop-categories-list .nav-link:before {
        content: "";
        width: 0.28rem;
        height: 0.5rem;
        border-bottom: 1px solid var(--bs-primary);
        border-right: 1px solid var(--bs-primary);
        transform: rotate(45deg);
        position: absolute;
        top: 15px;
        left: 6px;
        opacity: 0; }
      .shop-sidebar-option-02 .shop-categories-list .nav-link span {
        color: var(--bs-body-color);
        font-size: 72%; }
      .shop-sidebar-option-02 .shop-categories-list .nav-link.active, .shop-sidebar-option-02 .shop-categories-list .nav-link:hover {
        color: var(--bs-primary); }
        .shop-sidebar-option-02 .shop-categories-list .nav-link.active:after, .shop-sidebar-option-02 .shop-categories-list .nav-link:hover:after {
          border: 1px solid var(--bs-primary); }
        .shop-sidebar-option-02 .shop-categories-list .nav-link.active:before, .shop-sidebar-option-02 .shop-categories-list .nav-link:hover:before {
          opacity: 1; }
  .shop-sidebar-option-02 .shop-attributes-box {
    border: 1px solid var(--bs-gray-300);
    margin-bottom: 20px; }
    .shop-sidebar-option-02 .shop-attributes-box .shop-sidebar-title a {
      position: relative;
      padding: 15px;
      display: flex;
      margin: 0;
      font-size: 15px; }
      .shop-sidebar-option-02 .shop-attributes-box .shop-sidebar-title a i {
        margin-left: auto; }
    .shop-sidebar-option-02 .shop-attributes-box .shop-attributes-list {
      border-top: 1px solid var(--bs-gray-300); }
    .shop-sidebar-option-02 .shop-attributes-box .shop-attr-body {
      padding: 15px; }
      .shop-sidebar-option-02 .shop-attributes-box .shop-attr-body ul {
        margin: 0; }

.shop-top-bar-02 .layout-change {
  display: flex; }
  .shop-top-bar-02 .layout-change .btn {
    border: 1px solid var(--bs-gray-400);
    padding: 0;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-right: 8px;
    color: var(--bs-text-mode); }
    .shop-top-bar-02 .layout-change .btn.active, .shop-top-bar-02 .layout-change .btn:hover {
      background: var(--bs-primary);
      color: var(--bs-white);
      border-color: var(--bs-primary); }

.shop-bottom-bar-02 .pagination {
  margin: 0; }

.shop-sidebar-option-03 {
  flex: 0 0 300px;
  max-width: 300px;
  border-right: 1px solid var(--bs-gray-300);
  padding-right: 0; }
  .shop-sidebar-option-03 .shop-categories-list .nav .nav {
    padding-left: 15px;
    margin-bottom: 10px;
    border-left: 1px dashed var(--bs-gray-300); }
    .shop-sidebar-option-03 .shop-categories-list .nav .nav .nav-link {
      color: var(--bs-body-color);
      font-weight: 400;
      font-size: 15px; }
      .shop-sidebar-option-03 .shop-categories-list .nav .nav .nav-link.active, .shop-sidebar-option-03 .shop-categories-list .nav .nav .nav-link:hover {
        color: var(--bs-primary); }
  .shop-sidebar-option-03 .shop-categories-list .nav-item {
    position: relative; }
    .shop-sidebar-option-03 .shop-categories-list .nav-item .s-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 5px;
      right: 0; }
      .shop-sidebar-option-03 .shop-categories-list .nav-item .s-icon:before, .shop-sidebar-option-03 .shop-categories-list .nav-item .s-icon:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        background: var(--bs-bg-mode); }
      .shop-sidebar-option-03 .shop-categories-list .nav-item .s-icon:before {
        width: 2px;
        height: 10px; }
      .shop-sidebar-option-03 .shop-categories-list .nav-item .s-icon:after {
        width: 10px;
        height: 2px; }
      .shop-sidebar-option-03 .shop-categories-list .nav-item .s-icon[aria-expanded="true"]:before {
        height: 0; }
  .shop-sidebar-option-03 .shop-categories-list .nav-link {
    padding: 5px 30px 5px 25px;
    color: var(--bs-text-mode);
    font-weight: 400;
    position: relative;
    display: inline-block;
    vertical-align: top; }
    .shop-sidebar-option-03 .shop-categories-list .nav-link:after {
      content: "";
      position: absolute;
      left: 0;
      top: 9px;
      width: 16px;
      height: 16px;
      border: 1px solid var(--bs-gray-300); }
    .shop-sidebar-option-03 .shop-categories-list .nav-link:before {
      content: "";
      width: 0.28rem;
      height: 0.5rem;
      border-bottom: 1px solid var(--bs-primary);
      border-right: 1px solid var(--bs-primary);
      transform: rotate(45deg);
      position: absolute;
      top: 12px;
      left: 6px;
      opacity: 0; }
    .shop-sidebar-option-03 .shop-categories-list .nav-link span {
      color: var(--bs-body-color);
      font-size: 72%; }
    .shop-sidebar-option-03 .shop-categories-list .nav-link.active, .shop-sidebar-option-03 .shop-categories-list .nav-link:hover {
      color: var(--bs-primary); }
      .shop-sidebar-option-03 .shop-categories-list .nav-link.active:after, .shop-sidebar-option-03 .shop-categories-list .nav-link:hover:after {
        border: 1px solid var(--bs-primary); }
      .shop-sidebar-option-03 .shop-categories-list .nav-link.active:before, .shop-sidebar-option-03 .shop-categories-list .nav-link:hover:before {
        opacity: 1; }
  .shop-sidebar-option-03 .shop-attributes-box {
    margin-bottom: 30px; }
    .shop-sidebar-option-03 .shop-attributes-box .shop-sidebar-title .h5 {
      font-size: 17px;
      font-weight: 500;
      color: var(--bs-text-mode);
      margin-bottom: 20px;
      padding-bottom: 10px;
      position: relative;
      border-bottom: 1px solid var(--bs-gray-300); }
      .shop-sidebar-option-03 .shop-attributes-box .shop-sidebar-title .h5:after {
        content: "";
        position: absolute;
        left: 0;
        width: 30px;
        height: 3px;
        bottom: -1px;
        background: var(--bs-primary); }
    .shop-sidebar-option-03 .shop-attributes-box .shop-attr-body {
      padding-right: 15px; }
      .shop-sidebar-option-03 .shop-attributes-box .shop-attr-body ul {
        margin: 0; }

.shop-top-bar-03 {
  padding-bottom: 20px; }
  .shop-top-bar-03 .layout-change {
    display: flex; }
    .shop-top-bar-03 .layout-change .btn {
      border: 1px solid var(--bs-gray-300);
      padding: 0;
      width: 40px;
      height: 40px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      margin-right: 8px; }
      .shop-top-bar-03 .layout-change .btn.active, .shop-top-bar-03 .layout-change .btn:hover {
        background: var(--bs-primary);
        color: var(--bs-white);
        border-color: var(--bs-primary); }

.shop-bottom-bar-03 {
  padding-top: 20px; }
  .shop-bottom-bar-03 .pagination {
    margin: 0; }

.shop-sidebar-option-04 {
  flex: 0 0 200px;
  max-width: 200px; }
  .shop-sidebar-option-04 .shop-categories-list .nav-level-1 {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--bs-gray-300); }
    .shop-sidebar-option-04 .shop-categories-list .nav-level-1 .nav-link {
      font-size: 16px;
      font-weight: 500; }
      .shop-sidebar-option-04 .shop-categories-list .nav-level-1 .nav-link.active {
        font-weight: 700; }
  .shop-sidebar-option-04 .shop-categories-list .nav-link {
    padding: 3px 8px;
    color: var(--bs-text-mode);
    position: relative;
    background: transparent;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 500;
    transition: ease all 0.35s; }
    .shop-sidebar-option-04 .shop-categories-list .nav-link:hover {
      background: rgba(var(--bs-primary-rgb), 0.1);
      color: var(--bs-primary); }
    .shop-sidebar-option-04 .shop-categories-list .nav-link.active {
      font-weight: 600;
      color: var(--bs-primary); }

.shop-top-bar-04 {
  padding-bottom: 30px;
  position: relative; }
  .shop-top-bar-04 .breadcrumb-info {
    padding-top: 10px; }
    .shop-top-bar-04 .breadcrumb-info h3, .shop-top-bar-04 .breadcrumb-info .h3 {
      margin: 0;
      font-weight: 600; }
    .shop-top-bar-04 .breadcrumb-info span {
      font-size: 13px; }
  .shop-top-bar-04 .filter-btn {
    border: 1px solid var(--bs-gray-300);
    padding: 8px 22px;
    background: var(--bs-white);
    font-weight: 600; }
    .shop-top-bar-04 .filter-btn i {
      margin-left: 8px; }
  .shop-top-bar-04 .shop-filter-04 {
    padding: 20px;
    border: 1px solid var(--bs-gray-300);
    margin-top: 15px; }
    .shop-top-bar-04 .shop-filter-04 h6, .shop-top-bar-04 .shop-filter-04 .h6 {
      font-weight: 600;
      font-size: 16px;
      color: var(--bs-text-mode);
      margin-bottom: 10px; }
    @media (max-width: 767.98px) {
      .shop-top-bar-04 .shop-filter-04 h6, .shop-top-bar-04 .shop-filter-04 .h6 {
        margin-bottom: 0px; }
      .shop-top-bar-04 .shop-filter-04 .shop-list-tags-02,
      .shop-top-bar-04 .shop-filter-04 .shop-list-colors,
      .shop-top-bar-04 .shop-filter-04 .shop-list-tags {
        flex-wrap: nowrap;
        max-width: 100%;
        overflow-x: auto;
        padding: 7px 0;
        white-space: nowrap; } }

.cart-qty {
  width: 120px;
  position: relative; }
  .cart-qty .qty-btn {
    position: absolute;
    top: 1px;
    bottom: 1px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
    background: var(--bs-gray-200); }
    .cart-qty .qty-btn:selection {
      background: transparent; }
    .cart-qty .qty-btn:hover {
      color: var(--bs-text-mode); }
  .cart-qty .dec {
    left: 1px; }
  .cart-qty .inc {
    right: 1px; }
  .cart-qty .cart-qty-input {
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    border: 1px solid var(--bs-gray-300);
    padding: 0.675rem 1rem; }

@media (min-width: 992px) {
  .product-details .product-gallery.sticky-lg-top {
    top: 120px; } }

.product-details .product-gallery .gallery-link {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--bs-dark);
  background: rgba(var(--bs-white-rgb), 0.8); }

.product-details .product-gallery .product-thumb {
  margin-top: 10px; }
  .product-details .product-gallery .product-thumb .swiper-slide img {
    border: 2px solid transparent;
    cursor: pointer;
    width: 100%; }
  .product-details .product-gallery .product-thumb .swiper-slide.swiper-slide-thumb-active img {
    border-color: var(--bs-primary); }

.product-detail .product-info-buttons a {
  color: var(--bs-text-mode); }
  @media (max-width: 767.98px) {
    .product-detail .product-info-buttons a {
      font-size: 13px; } }
  .product-detail .product-info-buttons a i {
    margin-right: 8px; }
  .product-detail .product-info-buttons a:hover {
    color: var(--bs-primary); }

.product-tabs .product-nav-tabs {
  justify-content: center;
  border-bottom: 1px solid var(--bs-gray-200); }
  .product-tabs .product-nav-tabs li + li {
    margin-left: 15px;
    padding-left: 15px; }
  .product-tabs .product-nav-tabs a {
    font-weight: 600;
    color: var(--bs-text-mode);
    font-size: 18px;
    position: relative;
    padding-left: 0;
    padding-right: 0; }
    .product-tabs .product-nav-tabs a:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: auto;
      right: 0;
      width: 0px;
      height: 2px;
      background: var(--bs-primary);
      transition: ease all 0.35s; }
    .product-tabs .product-nav-tabs a.active, .product-tabs .product-nav-tabs a:hover {
      color: var(--bs-primary); }
      .product-tabs .product-nav-tabs a.active:after, .product-tabs .product-nav-tabs a:hover:after {
        left: 0;
        right: auto;
        width: 100%; }

.product-tabs .tab-content {
  padding-top: 40px; }

.review-box .review-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden; }
  @media (max-width: 991.98px) {
    .review-box .review-image {
      width: 60px;
      height: 60px; } }
  .review-box .review-image.sm {
    width: 50px;
    height: 50px; }

@media (min-width: 992px) {
  .review-form {
    top: 150px; } }

.product-detail-collapse {
  padding-top: 20px; }
  .product-detail-collapse .pd-collapse-row {
    border: 1px solid var(--bs-gray-200);
    margin-top: 15px; }
    .product-detail-collapse .pd-collapse-row .pd-collapse-h5 {
      padding: 10px 15px;
      font-weight: 600;
      color: var(--bs-text-mode);
      display: flex; }
      .product-detail-collapse .pd-collapse-row .pd-collapse-h5 i {
        margin-left: auto; }
      .product-detail-collapse .pd-collapse-row .pd-collapse-h5[aria-expanded="false"] i:before {
        content: "\f282"; }
  .product-detail-collapse .pd-collapse-box {
    padding: 20px;
    border-top: 1px solid var(--bs-gray-200); }

.cart-qty-01 {
  display: flex;
  width: 85px;
  position: relative;
  border: 1px solid var(--bs-gray-300); }
  .cart-qty-01 .qty-btn {
    width: 30px;
    height: calc(50% + 1px);
    position: absolute;
    top: -1px;
    right: -1px;
    border: 1px solid var(--bs-gray-300);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 12px; }
    .cart-qty-01 .qty-btn.inc {
      bottom: -1px;
      top: auto;
      border-top: none; }
  .cart-qty-01 .cart-qty-input {
    border: none;
    padding: 7px 35px 7px 10px;
    height: 45px; }

.product-details-02 .product-gallery {
  display: flex;
  margin-bottom: auto; }
  .product-details-02 .product-gallery .product-slider {
    flex: 0 0 calc(100% - 120px);
    margin-left: 20px;
    max-width: calc(100% - 120px);
    order: 2;
    border: 1px solid var(--bs-gray-300); }
    @media (max-width: 767.98px) {
      .product-details-02 .product-gallery .product-slider {
        flex: 0 0 calc(100% - 60px);
        margin-left: 10px;
        max-width: calc(100% - 60px); } }
  .product-details-02 .product-gallery .gallery-link {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--bs-white);
    background: rgba(var(--bs-dark-rgb), 0.8); }
  .product-details-02 .product-gallery .product-thumb .swiper-slide img {
    border: 1px solid var(--bs-gray-300);
    cursor: pointer;
    width: 100%; }
  .product-details-02 .product-gallery .product-thumb .swiper-slide.swiper-slide-thumb-active img {
    border-color: var(--bs-primary); }
  .product-details-02 .product-gallery .product-thumb {
    flex: 0 0 100px;
    max-width: 100px;
    padding-left: 10px; }
    @media (max-width: 767.98px) {
      .product-details-02 .product-gallery .product-thumb {
        flex: 0 0 50px;
        max-width: 50px; } }
    .product-details-02 .product-gallery .product-thumb .slick-arrow {
      border: 1px solid var(--bs-gray-300); }

.product-details-02 .products-title-box {
  border-bottom: 1px solid var(--bs-gray-300);
  padding-bottom: 10px;
  margin-bottom: 15px;
  display: flex; }
  .product-details-02 .products-title-box .products-title {
    padding-bottom: 5px; }
    .product-details-02 .products-title-box .products-title .h4 {
      margin: 0; }
  .product-details-02 .products-title-box .product-wishlist {
    margin-left: auto; }
    .product-details-02 .products-title-box .product-wishlist .btn {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: none;
      padding: 0;
      background: rgba(var(--bs-primary-rgb), 0.1);
      color: var(--bs-primary);
      display: inline-flex;
      align-items: center;
      justify-content: center; }
      .product-details-02 .products-title-box .product-wishlist .btn.active, .product-details-02 .products-title-box .product-wishlist .btn:hover {
        background: var(--bs-primary);
        color: var(--bs-white); }

.product-details-02 .product-attribute .product-options {
  margin-bottom: 18px; }

.product-details-02 .product-attribute .attribute-label {
  margin: 0 0 10px; }
  .product-details-02 .product-attribute .attribute-label span {
    font-weight: normal;
    color: var(--bs-body-color);
    font-size: 80%; }

.product-details-02 .product-price {
  font-size: 26px;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 15px; }
  .product-details-02 .product-price del {
    font-size: 70%;
    font-weight: 400; }

.product-details-02 .product-detail-actions {
  display: flex; }
  .product-details-02 .product-detail-actions .cart-button {
    flex: 1;
    padding-left: 12px; }
  .product-details-02 .product-detail-actions .pd-cart-btn {
    width: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    line-height: 45px; }
    .product-details-02 .product-detail-actions .pd-cart-btn i {
      margin-right: 8px; }

.product-details-02 .product-metas {
  padding-top: 20px; }

.product-details-02 .product-info-buttons {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid var(--bs-gray-300); }

.product-detail-tab-02 {
  margin-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid var(--bs-gray-300);
  border-bottom: 1px solid var(--bs-gray-300); }

.product-nav-tabs-02 {
  justify-content: center;
  margin-top: -48px; }
  .product-nav-tabs-02 .nav-item + .nav-item {
    margin-left: 15px; }
  .product-nav-tabs-02 .nav-item .nav-link {
    border: 1px solid var(--bs-gray-300);
    font-weight: 500;
    color: var(--bs-text-mode);
    font-size: 16px;
    padding: 3px 30px;
    text-transform: uppercase;
    line-height: 40px;
    position: relative;
    background: var(--bs-body-bg); }
    .product-nav-tabs-02 .nav-item .nav-link:after {
      content: "";
      position: absolute;
      top: 0;
      left: auto;
      right: 0;
      width: 0px;
      height: 3px;
      background: currentColor;
      transition: ease all 0.35s; }
    .product-nav-tabs-02 .nav-item .nav-link.active {
      color: var(--bs-primary);
      border-bottom-color: var(--bs-text-mode-re); }
    .product-nav-tabs-02 .nav-item .nav-link.active:after, .product-nav-tabs-02 .nav-item .nav-link:hover:after {
      left: 0;
      right: auto;
      width: 100%; }

.product-details-03 .product-detail-media .pd-video-box {
  display: flex; }
  .product-details-03 .product-detail-media .pd-video-box .pd-video-box-in {
    display: flex;
    height: 100%;
    width: 100%; }
    .product-details-03 .product-detail-media .pd-video-box .pd-video-box-in video {
      height: 100%;
      width: 100%; }

.product-details-03 .product-detail .breadcrumb {
  padding-bottom: 8px; }

.product-details-03 .product-detail .products-title-head {
  border-bottom: 1px solid var(--bs-gray-300);
  margin-bottom: 15px;
  padding-bottom: 15px; }

.product-details-03 .product-detail .products-title {
  display: flex; }
  .product-details-03 .product-detail .products-title .h4 {
    font-size: 24px;
    font-weight: 600; }
  .product-details-03 .product-detail .products-title .product-price {
    display: flex;
    flex-direction: column;
    padding-left: 15px; }
    .product-details-03 .product-detail .products-title .product-price span {
      font-size: 20px;
      font-weight: 600;
      color: var(--bs-text-mode); }

.product-details-03 .product-detail .product-attributes {
  border-bottom: 1px solid var(--bs-gray-300);
  margin-bottom: 20px;
  padding-bottom: 20px; }
  .product-details-03 .product-detail .product-attributes .product-attribute-in + .product-attribute-in {
    padding-top: 20px; }
  .product-details-03 .product-detail .product-attributes .att-label {
    padding-bottom: 15px;
    display: flex; }
    .product-details-03 .product-detail .product-attributes .att-label h6, .product-details-03 .product-detail .product-attributes .att-label .h6 {
      font-weight: 700;
      color: var(--bs-text-mode);
      margin: 0;
      font-size: 16px; }
      .product-details-03 .product-detail .product-attributes .att-label h6 span, .product-details-03 .product-detail .product-attributes .att-label .h6 span {
        font-weight: normal;
        color: var(--bs-body-color);
        padding-left: 5px;
        font-size: 90%; }
    .product-details-03 .product-detail .product-attributes .att-label a {
      font-size: 0.73rem;
      line-height: normal;
      text-transform: uppercase; }

.product-details-03 .product-detail .cart-qty-01 {
  width: 105px; }

.product-details-03 .product-detail .product-detail-actions {
  padding-top: 20px; }
  .product-details-03 .product-detail .product-detail-actions .cart-button {
    flex: 1; }
    .product-details-03 .product-detail .product-detail-actions .cart-button .btn {
      width: 100%; }

.product-nav-tabs-03 {
  justify-content: center; }
  .product-nav-tabs-03 .nav-item + .nav-item {
    margin-left: 10px; }
  .product-nav-tabs-03 .nav-link {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    padding: 5px 5px;
    position: relative;
    color: var(--bs-text-mode);
    font-size: 16px;
    letter-spacing: 1px; }
    .product-nav-tabs-03 .nav-link:after {
      content: "";
      position: absolute;
      left: auto;
      right: 0;
      bottom: 0;
      width: 0px;
      height: 2px;
      background: currentColor;
      transition: ease all 0.35s; }
    .product-nav-tabs-03 .nav-link.active, .product-nav-tabs-03 .nav-link:hover {
      color: var(--bs-primary); }
      .product-nav-tabs-03 .nav-link.active:after, .product-nav-tabs-03 .nav-link:hover:after {
        width: 100%;
        left: 0;
        right: auto; }
